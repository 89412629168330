import axios from "axios";
import { getToken } from "./authService";
const apiKey = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: apiKey
})

instance.interceptors.request.use((config)=>{
    if(getToken()){
        config.headers['Authorization'] = getToken();
    }
    return config;
}, (err)=>{
    return Promise.reject(err);
})

instance.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    console.log("Error loaded ==>", err);
    if(!err || !err.response){
        window.location.href='/#/system-down'
    }
    else if (err.response.status === 401) {
        // removeToken()
        window.location.href = "/";
    }
    else if(err.response.status >= 500){
        console.log("error to show page");
        // window.location.href = "/#/error";
    }
     
    return Promise.reject(err.response);
})

export const addNewCompanyService = (obj) => {
    try{
        return instance.post(`linkedin/get/company/data`, obj)
    }
    catch(e){
        return e;
    }
}

export const getCompanyListService = (page, limit, source) => {
    try{
        let companyListPath = `company/${page}/${limit}`
        if(source){
            companyListPath = `company/${page}/${limit}?source=${source}`
        }
        return instance.get(companyListPath)
    }
    catch(e){
        return e;
    }
}

export const getActiveCompanyCountService = () => {
    try {
        return instance.get(`company/active/count`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyInformationService = (slug) => {
    try{
        return instance.get(`company/${slug}`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyDataCollectionService = (page, limit) => {
    try{
        return instance.get(`company/data/collection/${page}/${limit}`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyWidgetInformationService = (slug) => {
    try{
        return instance.get(`company/ai/${slug}`)
    }
    catch(e){
        return e;
    }
}

export const updateDataSourceService = (obj) => {
    try {
        return instance.post('company/dataSource/added/info/update', obj)
    }
    catch(e){
        return e;
    }
}

export const updateDocumentUploadService = (obj) => {
    try {
        return instance.post('company/documents/added/info/update', obj)
    }
    catch(e){
        return e;
    }
}

export const getCompanyNewsService = (company) => {
    try{
        return instance.get(`company/news?q=${company}&sortBy=relevance&pageSize=20`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyNewsByCategoryService = (companyId) => {
    try {
        return instance.get(`company/category/news/${companyId}`)
    }
    catch(e) {
        return e;
    }
}

export const companyFileUploadService = (file) => {
    try {
        return instance.post('company/upload', file)
    }
    catch(e){
        return e;
    }
}

export const getFinanceInfoService = (companyId) => {
    try {
        return instance.get(`company/finance/info/${companyId}`)
    }
    catch(e){
        return e;
    }
}

export const getDealStagesService = () => {
    try {
        return instance.get(`company/deal/stage`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyByDealStageService = (stageId, page, limit) => {
    try {
        return instance.get(`company/deal/stage/list/${stageId}/${page}/${limit}`)
    }
    catch(e){
        return e;
    }
}

export const getAllDatasourceService = (companyId) => {
    try {
        return instance.get(`company/data/sourcing/list/${companyId}`)
    }
    catch(e){
        return e;
    }
}

export const updateCompanyDatasourceService = (obj) => {
    try {
        return instance.post(`company/update/sourcing`, obj)
    }
    catch(e){
        return e;
    }
}

export const removeCompanyService = (companyId) => {
    try{
        return instance.delete(`company/${companyId}`)
    }
    catch(e){
        return e;
    }
}

export const getAnalysisCategoryService = () => {
    try{
        return instance.get(`company/analysis/category`)
    }
    catch(e){
        return e;
    }
}

export const getAnalysisInfoService = (companyId) => {
    try{
        return instance.get(`company/analysis/info/${companyId}`)
    }
    catch(e){
        return e;
    }
}

export const createAnalysisInfoService = (obj) => {
    try{
        return instance.post(`company/view/analysis`, obj)
    }
    catch(e){
        return e;
    }
}

export const getDealbookListService = () => {
    try{
        return instance.get(`company/deal/book/file`)
    }
    catch(e){
        return e;
    }
}

export const addDealbookService = async (obj) => {
    try {
        return await instance.post(`company/add/project/info`, obj)
    }
    catch(e){

    }
}

export const getNeo4jNodesService = async(obj) => {
    try {
        return await instance.get(`neo4j/nodes`, {
            params: obj
          })
    }
    catch(e){
        return e;
    }
}

export const addCompanyLinkService = (obj) => {
    try{
        return instance.post(`company/scrape/build/graph`, obj)
    }
    catch(e){
        return e;
    }
}

export const getCompanyExternalLinkService = (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return instance.get(`company/external/info/links${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const getAnalyzeCompanyService = (page, limit) => {
    try{
        return instance.get(`company/performance/metrics/${page}/${limit}`)
    }
    catch(e){
        return e;
    }
}

export const getCompanyColumnConfigurationPromptsToAnalyzeService = (page, limit) => {
    try{
        return instance.get(`company/investment/performance/config/${page}/${limit}`)
    }
    catch(e){
        return e;
    }
}