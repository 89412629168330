import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateStringFromSlug } from "../../../utils/validation";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { SourceCompanyPeopleComponent } from "../../Sourcing/company/people";


export const PortcoInformationPeopleComponent = () => {

    const location = useLocation()
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'People', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    return (
        <SourceCompanyPeopleComponent callsByOtherPlace={true} />
    )
}