import React from "react";


export const AIModelCardComponent = ({ app, appInfo }) => {

    return (
        <div className="card shadow-sm mb-3 bl-ai-model-card-deck">
            <div className="card-body pb-1">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <div className="mr-2 ml-0 bl-recommended-app-icon">
                            <img className="img-fluid card-img-top bl-recommended-app-icon p-1" src={app.icon} alt="Card image cap" />
                        </div>
                        <div className="align-self-center">
                            <h6 className="mb-0 text-nowrap">{app.title}</h6>
                            <p className="mb-0 font-small text-muted text-nowrap">{app.category}</p>
                        </div>
                    </div>
                    <label className="bl-switch mb-0"><input type="checkbox" checked={app.isEnabled} readOnly /><span className="bl-slider round"></span></label>
                </div>
                <p className="my-1 font-small">{app.description}</p>
                <div>
                    <p className="text-muted my-2 font-small">Used By:</p>
                    <div className="">
                        {
                            app.apps.map((f, i) => {
                                return (
                                    <span key={`ai-model-overview-apps-${i + 1}`} className={`badge bl-filter-chip d-inline-block mb-2`}>{f}</span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="card-footer text-center bg-white p-2" data-toggle="modal" data-target="#agentAppInfoModal" onClick={() => appInfo(app)}>
                <p className="mb-0 cp font-small text-muted">
                    {
                        app.isEnabled ? (
                            <span><i className={`fa fa-check-circle text-success`} aria-hidden="true"></i> View Details</span>
                        ) : 'View Details'
                    }

                </p>
            </div>
        </div>
    )
}