import React, { useState } from 'react';

export const AnalyzeCompanyContext = React.createContext();

export const AnalyzeCompanyContextProvider = ({ children }) => {

    const [analyzedCompanyInfo, setAnalyzedCompanyInfo] = useState(null);
    const [analyzeCanvasList, setAnalyzeCanvasList] = useState([]);

    return (
        <AnalyzeCompanyContext.Provider value={{ analyzedCompanyInfo, setAnalyzedCompanyInfo, analyzeCanvasList, setAnalyzeCanvasList }}>
            {children}
        </AnalyzeCompanyContext.Provider>
    );
};
