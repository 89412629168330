import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbComponent from "./breadcrumbs";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { AuthContext } from "../../services/context/Auth/authContext";


export const HeaderComponent = () => {

    const { setGlobalSearch } = useContext(SearchContext)
    const { breadcrumbMenu } = useContext(BreadcrumbContext)
    const { loggedUserName } = useContext(AuthContext)
    const nav = useNavigate();

    const [showBar, setShowBar] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const debounceDelay = 1000;

    useEffect(()=>{
        if(breadcrumbMenu.length && breadcrumbMenu[0].hasSubMenu){
            setShowBar(breadcrumbMenu[0].hasSubMenu)
        }
        else {
            setShowBar(false)
        }
    }, [breadcrumbMenu])

    useEffect(() => {
        const timer = setTimeout(() => {
            setGlobalSearch(searchTerm)
        }, debounceDelay);

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm]);

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setGlobalSearch(searchTerm)
        }
    }

    const logoutContent = () => {
        return (
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary primary-link" data-dismiss="modal" onClick={() => nav('/')}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar fixed-top mb-0 shadow vistaHeaderMargin vistaCollapsedHeaderMargin" id="vistaHeader">

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                {/* <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-0">
                    <i className="fa fa-window-maximize"></i>
                </button> */}
                {
                    showBar ? <button id="togglersubmenu" className="btn btn-link rounded-circle mr-0 text-muted">
                    <i className="fa fa-bars"></i>
                </button> : null
                }
                {/* <div><h6 className="font-weight-bold text-muted m-0">{componentName}</h6></div> */}
                <div className={showBar ? '':'ml-3'}>
                    <BreadcrumbComponent item={breadcrumbMenu}></BreadcrumbComponent>
                </div>


                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav ml-auto">

                    {/* <!-- Nav Item - Search Dropdown (Visible Only XS/Mobile) --> */}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <button className="nav-link dropdown-toggle" id="searchDropdown"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-search fa-fw"></i>
                        </button>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fa fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/* <!-- Topbar Search --> */}
                    {/* <li className="nav-item dropdown no-arrow mx-1">
                        <button className="nav-link dropdown-toggle" id="">
                            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group align-items-center search-bar">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    <input type="text" className="form-control border-0 shadow-none bg-grey search" onChange={handleSearch} onKeyDown={handleKeySearch} placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-dark" type="button">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </button>
                    </li> */}

                    <li className="nav-item d-flex align-items-center mr-3">
                        <div className="d-flex align-items-center">
                            <h6 className="mb-0 text-nowrap mr-2 font-small">Active AI-Agent</h6>
                            <select className="form-control shadow-sm border border-primary px-4 font-small">
                                <option value="1">PortCo Monitor V2.1</option>
                                <option value="2">PortCo Monitor V1.0</option>
                            </select>
                        </div>
                    </li>


                    {/* <!-- Nav Item - Apps --> */}
                    <li className="nav-item dropdown no-arrow mx-1 d-none">
                        <button className="nav-link dropdown-toggle" id="messagesDropdown"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-th" aria-hidden="true"></i>
                        </button>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                            <h6 className="dropdown-header">
                                Apps for you
                            </h6>
                            <div className="row p-3 bg-transparent">
                                <div className="col-6 mb-4">
                                    <table width="100%" border="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="35%"><img className="rounded-circle" src="https://cdn2.steamgriddb.com/file/sgdb-cdn/icon/ff51fb7a9bcb22c595616b4fa368880a/32/256x256.png" alt="" height="35px" width="35px" /></td>
                                                <td><b className="text-gray-800">Risk Assessment</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6 mb-4">
                                    <table width="100%" border="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="35%"><img className="rounded-circle" src="https://i.pinimg.com/474x/85/00/dc/8500dcb69159d70acf7155345c243911.jpg" alt="" height="35px" width="35px" /></td>
                                                <td><b className="text-gray-800">ESG Scorecard</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6 mb-4">
                                    <table width="100%" border="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="35%"><img className="rounded-circle" src="https://static-00.iconduck.com/assets.00/todoist-icon-256x256-vmg7wr0z.png" alt="" height="35px" width="35px" /></td>
                                                <td><b className="text-gray-800">Deal Sourcing</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6 mb-4">
                                    <table width="100%" border="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="35%"><img className="rounded-circle" src="https://winaero.com/blog/wp-content/uploads/2020/04/Windows-10-Tips-app-icon-big-colorful-fluent-256.png" alt="" height="35px" width="35px" /></td>
                                                <td><b className="text-gray-800">Onboarding</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <table width="100%" border="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="35%"><img className="rounded-circle" src="https://framerusercontent.com/images/E2GMbcx4lKKAXdyPqL0dnDDKY.png" alt="" height="35px" width="35px" /></td>
                                                <td><b className="text-gray-800">Data Requests</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <button className="dropdown-item text-center small text-gray-500">View All Apps</button>
                        </div>
                    </li>

                    {/* <!-- Nav Item - Alerts --> */}
                    <li className="nav-item dropdown no-arrow mx-1">
                        <button className="nav-link dropdown-toggle" id="alertsDropdown"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-bell" aria-hidden="true"></i>
                            {/* <!-- Counter - Alerts --> */}
                            <span className="badge badge-danger badge-counter">3+</span>
                        </button>
                        {/* <!-- Dropdown - Alerts --> */}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fa fa-file-code-o text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fa fa-usd text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fa fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </button>
                            <button className="dropdown-item text-center small text-gray-500" >Show All Alerts</button>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/* <!-- Nav Item - User Information panel --> */}
                    <li className="nav-item dropdown no-arrow">
                        <button className="nav-link dropdown-toggle" id="userDropdown"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{loggedUserName}</span>
                            <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                        </button>
                        {/* <!-- Dropdown - User Information --> */}
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <button className="dropdown-item" >
                                <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                                Profile
                            </button>
                            <button className="dropdown-item" >
                                <i className="fa fa-cogs fa-sm mr-2 text-gray-400"></i>
                                Settings
                            </button>
                            <button className="dropdown-item" >
                                <i className="fa fa-list fa-sm mr-2 text-gray-400"></i>
                                Activity Log
                            </button>
                            <div className="dropdown-divider"></div>
                            <button className="dropdown-item" href="" data-toggle="modal" data-target="#logoutModal">
                                <i className="fa fa-sign-out fa-sm mr-2 text-gray-400"></i>
                                Logout
                            </button>
                        </div>
                    </li>

                </ul>

            </nav>
            {logoutContent()}
        </>
    )
}