import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import ColoredHeatmapChart from "../Graph/coloredHeatMap";
import moment from "moment";
import { getCompanyDataCollectionService } from "../../services/companyService";
import { CardDropdownContent } from "../Common/cardDropdown";
import AIContext from "../../services/context/AIAssistance/aiContext";
import companyPlaceholder from "./../../assets/img/companyPlaceholder.svg";


export const PortcoMonitoringDataCollectionComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType, setCompanyProfileWidget, setAIModalTitle } = useContext(AIContext);
    // const { sourcingCompanyList, setSourcingCompanyList } = useContext(SourcingContext)
    const nav = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setCompanyDataCollection([])
        getCompanyDataCollection()
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
                { slug: '/portco/data-collection', menuName: 'Data Collection', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [companyDataCollection, setCompanyDataCollection] = useState([]);
    const [companyScores, setCompanyScores] = useState([]);
    const [heatYAxisData, setHeatYAxisData] = useState([]);
    const [heatXAxisData, setHeatXAxisData] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({
        name: "", pending: 0, completed: 0, overdue: 0
    });
    const [companyPeriodData, setCompanyPeriodData] = useState([]);
    const [filter] = useState([
        { name: 'Financial', val: 'recent', isActive: true },
        { name: 'Census', val: 'Trending_Companies', isActive: false },
        { name: 'ESG', val: 'In_Current_News', isActive: false },
        { name: 'Spend', val: 'Unicorns', isActive: false }
    ]);
    const [dropdownFilter, setDropdownFilter] = useState([])

    useEffect(() => {
        const items = homeLabel.find(l => l.FEATURE === "DATA_COLLECTION").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        if (labels && labels.filters) {
            setDropdownFilter(labels.filters)
        }
    }, [labels])

    useEffect(() => {
        if (companyDataCollection && companyDataCollection.length) {
            setCompanyScores(companyDataCollection)
            openCompanyProfile(companyDataCollection[0])
            const companies = [...new Set(companyDataCollection.map(item => JSON.stringify({ company: item.company })))].map(item => JSON.parse(item));
            const periods = [...new Set(companyDataCollection.map(item => JSON.stringify({ period: item.period })))].map(item => JSON.parse(item));
            setHeatXAxisData(periods)
            setHeatYAxisData(companies)
        }
    }, [companyDataCollection])

    const getStatus = (value) => {
        if (value > 0 && value <= 25) return 'Not Started';
        if (value > 25 && value <= 50) return 'Overdue';
        if (value > 50 && value <= 75) return 'Pending';
        if (value > 75 && value <= 100) return 'Completed';
    }

    const getCompanyDataCollection = async () => {
        try {
            const resp = await getCompanyDataCollectionService(1, 10);
            if (resp && resp.success) {
                const companyCollection = [];
                const companyData = resp.data;
                if (companyData && companyData.length) {
                    companyData.forEach(comp => {
                        comp && comp.DataCollection && comp.DataCollection.pvtMonitoring && comp.DataCollection.pvtMonitoring.length && comp.DataCollection.pvtMonitoring.forEach(item => {
                            companyCollection.push({
                                company: comp.DataCollection.company,
                                logo: comp.logo,
                                period: item.period,
                                value: item.value,
                                due_date: item.dueDate,
                                scheduled_date: item.scheduledDate,
                                status: getStatus(item.value)
                            })
                        })
                    })
                    if (companyCollection && companyCollection.length) {
                        setCompanyDataCollection(companyCollection)
                    }
                }
            }
        }
        catch (e) { }
    }

    const openCompanyProfile = (companyInfo) => {
        console.log("companyInfo ===>", companyInfo);
        
        const companyPeriodList = companyDataCollection.filter(comp => comp.company === companyInfo.company);
        console.log("companyPeriodList ===>", companyPeriodList);
        
        setCompanyPeriodData(companyPeriodList)
        let notStarted = 0;
        let pending = 0;
        let completed = 0;
        let overdue = 0;
        companyPeriodList && companyPeriodList.length && companyPeriodList.forEach(item => {
            const value = item.value;
            if (value > 0 && value <= 25) {
                notStarted++;
            }
            if (value > 25 && value <= 50) {
                overdue++;
            }
            if (value > 50 && value <= 75) {
                pending++;
            }
            if (value > 75 && value <= 100) {
                completed++;
            }
        });
        setCompanyInfo({
            name: companyInfo.company, logo: companyInfo.logo, notStarted: notStarted, pending: pending, completed: completed, overdue: overdue
        })
    }

    function getTextColor(value) {
        if (value > 0 && value <= 25) return "bl-heatmap-legend_notStarted_text";
        if (value > 25 && value <= 50) return "bl-heatmap-legend_overdue_text";
        if (value > 50 && value <= 75) return "bl-heatmap-legend_pending_text";
        if (value > 75 && value <= 100) return "bl-heatmap-legend_completed_text";
        return "text-primary";
    }

    const onChildCardEvent = (item) => {
        if (item) {
            onModalLaunch(item)
        }
    }

    const onModalLaunch = (modalMode) => {
        let companyProfileInfoObj = {
            company: companyInfo.company, website: companyInfo.website, description: companyInfo.description, address: companyInfo.address,
            employeeRange: companyInfo.employeeRange, fundingRound: companyInfo.fundingRound, valuation: companyInfo.valuation,
            fundingAmount: companyInfo.fundingAmount, totalEmployee: companyInfo.totalEmployee, heatYAxisData: heatYAxisData,
            heatXAxisData: heatXAxisData, companyScores: companyScores
        }
        setAIModalTitle('Data Collection Breakdown')
        setAIModalType('DATA_COLLECTION')
        setCompanyProfileWidget(companyProfileInfoObj)
    }

    const missingImg = (e) => {
        e.target.src = companyPlaceholder;
    }

    const rightPanelContent = () => {
        return (
            <div className="">
                <div className="text-center pt-3">
                    <img src={companyInfo.logo} height={'40px'} onError={missingImg} className="rounded" alt="Company Logo"/>
                    <h4>{companyInfo.name}</h4>
                </div>
                <div className="d-flex justify-content-between text-center px-5 py-4">
                    <div className="mr-1">
                        <h5 className="bl-heatmap-right-panel-text mb-0">{companyInfo.notStarted}</h5>
                        <h6 className="text-muted font-smaller badge badge-pill bg-white border">NOT STARTED</h6>
                    </div>
                    <div className="mr-1">
                        <h5 className="bl-heatmap-right-panel-text mb-0">{companyInfo.pending}</h5>
                        <h6 className="text-muted font-smaller badge badge-pill bl-heatmap-legend_pending border">PENDING</h6>
                    </div>
                    <div className="mr-1">
                        <h5 className="bl-heatmap-right-panel-text mb-0">{companyInfo.completed}</h5>
                        <h6 className="font-smaller badge badge-pill bl-heatmap-legend_completed text-white">COMPLETED</h6>
                    </div>
                    <div className="mr-1">
                        <h5 className=" bl-heatmap-right-panel-text mb-0">{companyInfo.overdue}</h5>
                        <h6 className="font-smaller badge badge-pill bl-heatmap-legend_overdue text-white">OVERDUE</h6>
                    </div>
                </div>
                <table className="table table-borderless">
                    <thead className="border-top border-bottom">
                        <tr>
                            <th className="pl-4 text-muted">Scheduled</th>
                            <th className="text-muted">Due</th>
                            <th className="text-center text-muted">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            companyPeriodData && companyPeriodData.map((data, i) => {
                                return (
                                    <tr key={`comp-period-${i}`}>
                                        <td className="pl-4">{moment(data.scheduled_date.split('/').reverse().join('-')).format("MMM DD, YYYY")}</td>
                                        <td className="">{moment(data.due_date).format("MMM DD, YYYY")}</td>
                                        <td className="text-center"><i className={`fa fa-circle ${getTextColor(data.value)}`} aria-hidden="true"></i></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-4">
                <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                        <section className="mb-3">
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/manager-prioritizing-tasks-list_74855-5272.jpg" alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center py-3">
                                                    <h6 className="mb-0">{labels.RECOMMEND}</h6>
                                                    <CardDropdownContent cardId="dataCollectionWidget" cardType={'DATA_COLLECTION'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                                </div>
                                                <div className="d-flex justify-content-between pb-2 mb-2">
                                                    <div className="d-flex">
                                                        {
                                                            filter.map((f, i) => {
                                                                return (
                                                                    <span key={`risk-cate-filter-${i + 1}`} className={`badge bl-filter-chip ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {/* <div className="d-flex">
                                                        <div className="bg-secondary px-2">Not Started</div>
                                                        <div className="bg-warning px-2">Pending</div>
                                                        <div className="bg-success px-2">Completed</div>
                                                        <div className="bl-bg-pink px-2">Overdue</div>
                                                    </div> */}
                                                    <div className="btn-group border rounded">
                                                        <button type="button" className="btn btn-sm bl-heatmap-legend_notStarted  text-dark py-0 px-3 font-smaller border-0">Not Started</button>
                                                        <button type="button" className="btn btn-sm bl-heatmap-legend_pending text-dark py-0 px-3 font-smaller border-0">Pending</button>
                                                        <button type="button" className="btn btn-sm bl-heatmap-legend_completed text-white py-0 px-3 font-smaller border-0">Completed</button>
                                                        <button type="button" className="btn btn-sm bl-heatmap-legend_overdue text-white py-0 px-3 font-smaller border-0">Overdue</button>
                                                    </div>
                                                </div>
                                                <div className="card-filter py-3 mb-3 rounded">
                                                    <div className="col-xl-12 col-md-12">
                                                        <div className="form-row blc_button align-items-end d-none">
                                                            {
                                                                dropdownFilter.map((items, i) => {
                                                                    return (
                                                                        <div key={`dropdown-filter-${i}`} className="form-group mr-2 mb-0">
                                                                            <label htmlFor="inputState">{items.title}</label>
                                                                            <select className="form-control form-control-sm font-small">
                                                                                {
                                                                                    items.list && items.list.map((item, j) => {
                                                                                        return (
                                                                                            <option key={`filter-dropdown-item-${i}${j}`}>{item}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <button className="btn btn-filter btn-sm font-small">Apply Filter</button>
                                                            <button className="btn btn-link btn-sm bl-filter-clear font-small">Clear All</button>

                                                        </div>

                                                        <div className="d-flex">
                                                            <div className="input-group w-auto">
                                                                <input type="text" className="form-control form-control-sm font-small bl-w-58rem" placeholder="Ask AI filter... (e.g., 'Show companies with incomplete data', 'List companies with 100% collection status', or 'Find companies with missing financial data for Q2')" />
                                                                <div className="input-group-append"><span className="input-group-text"><i className="fa fa-search font-smaller"></i></span></div>
                                                            </div>
                                                            <button type="button" className="btn btn-outline-primary btn-sm ml-2" data-toggle="modal" data-target="#configureCompanyPromptsModal"><i className="fa fa-cog" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ColoredHeatmapChart id="portcoCompanyScoreChart" data={companyScores} yAxisData={heatYAxisData} xAxisData={heatXAxisData} categoryYField="company" categoryXField="period" yAxisVisibility={true} height={'32rem'} minColor={0xff0000} maxColor={0x00ff00} legendVisibility={false} dashHeat={(item) => openCompanyProfile(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-12 p-0 mt-n3 bl-heatmap-right-panel-bg">
                        {rightPanelContent()}
                    </div>
                </div>
            </div>

        </div>
    )
}