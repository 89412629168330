import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateStringFromSlug } from "../../../utils/validation";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { SourceCompanyAllNews } from "../../Sourcing/company/allNews";
import { SourceCompanyCompareComponent } from "../../Sourcing/company/compareableCompany";
import { getCompanyListService } from "../../../services/companyService";


export const PortcoInformationComparableCompaniesComponent = () => {

    const location = useLocation()
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const [companyList, setCompanyList] = useState([])

    useEffect(() => {
        setCompanyList([])
        getCompanyList(1)
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Comparable Companies', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const getCompanyList = async (page) => {
        const limit = 11;
        const resp = await getCompanyListService(page, limit, 'company');
        getCompanyListResponse(page, resp, limit)
    }

    const getCompanyListResponse = (page, resp, limit) => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        if (resp && resp.success) {
            const newCompanyList = resp.data.filter(comp => comp.slug !== companySlug);
            setCompanyList(companies => [...companies, ...newCompanyList])
        }
    }

    return (
        <SourceCompanyCompareComponent callsByOtherPlace={true} companies={companyList} />
    )
}