import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const HorizontalBarChart = ({ data, height = '10rem', id = 'horizontalBarChartDiv' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        let root = am5.Root.new(id);

        root.setThemes([
            am5themes_Animated.new(root),
        ]);
        root._logo.dispose();

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panY",
            wheelY: "zoomY",
            pinchZoomY: true,
            paddingLeft: 0,
            paddingRight: 1,
        }));

        chart.children.unshift(am5.Label.new(root, {
            fontSize: 10
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineX.set("visible", false);

        // Y-axis (Categories)
        let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
            inversed: true, // Ensure horizontal bar orientation
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
        });

        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "country",
            renderer: yRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        }));

        // X-axis (Values)
        let xRenderer = am5xy.AxisRendererX.new(root, {
            strokeOpacity: 0.1,
        });

        let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: xRenderer,
        }));

        // Series
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "value",
            categoryYField: "country",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueX}",
            }),
        }));

        series.columns.template.setAll({
            cornerRadiusTL: 0,
            cornerRadiusTR: 5,
            cornerRadiusBL: 0,
            cornerRadiusBR: 5,
            strokeOpacity: 0,
        });

        const customColors = [
            "#47abe1",
            "#9bbfd0",
            "#61aabb",
            "#73cfbf",
            "#070b3180",
            "#47abe180",
            "#9bbfd080",
            "#61aabb80",
            "#73cfbf80",
            "#47ab00",
            "#9bbf00",
            "#61aa00",
            "#73cf00"
          ];

        series.columns.template.adapters.add("fill", function (fill, target) {            
            return customColors[series.columns.indexOf(target)] // chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return customColors[series.columns.indexOf(target)];
        });

        yAxis.data.setAll(data);
        series.data.setAll(data);

        series.appear(1000);

        setChartRoot(root);

        return () => {
            if (chartRoot) {
                chartRoot.dispose();
            }
        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};
