export const generateStringFromSlug = (slug) => {
    return slug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const extractNumberFromString = (str) => {
    return parseInt(str.replace(/\D/g, ''), 10) || 0;
}

export const convertToDate = (dateString) => {
    const parts = dateString.split('-').map(Number);
    let [day, month, year] = [1, 1, 2016];

    if (parts.length === 3) {
        [day, month, year] = parts;
    } else if (parts.length === 2) {
        [month, year] = parts;
    } else if (parts.length === 1) {
        [year] = parts;
    }
    day = day || 1;
    month = month || 1;

    return new Date(year, month - 1, day)
}

export const generateRandomHexColor = () => {
    const colors = [
        "#47abe1",
        "#9bbfd0",
        "#61aabb",
        "#73cfbf",
        "#070b3180",
        "#47abe180",
        "#9bbfd080",
        "#61aabb80",
        "#73cfbf80",
        "#47ab00",
        "#9bbf00",
        "#61aa00",
        "#73cf00"
    ]
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
    const randomColor = Math.floor(Math.random() * 16777215).toString(16); 
    return `#${randomColor.padStart(6, '0')}`;
  }