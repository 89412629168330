import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService } from "../../../services/companyService";
import { convertToDate, generateRandomHexColor, generateStringFromSlug } from "../../../utils/validation";
import { SourceCommonCompanyTitle } from "../../Sourcing/Common/companyTitle";
import { EmptyCardsContent } from "../../Sourcing/Common/emptyCard";
import { PIEChartComponent } from "../../Graph/piechart";
import { ColumnChart } from "../../Graph/columnChart";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { TrendChartComponent } from "../../Graph/trendChart";
import moment from "moment";
import { WaterFallChartComponent } from "../../Graph/waterFallChart";


export const PortcoInformationValuationsComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Vendor Spends', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [isGraphLoaded, setIsGraphLoaded] = useState(false)
    const [portfolioAIInfo, setPortfolioAIInfo] = useState(null)
    const [sectorBreakdown, setSectorBreakdown] = useState([])
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [spendOverTimeChartData, setSpendOverTimeChartData] = useState([]);
    const [valuationChartData, setValuationChartData] = useState([]);
    const [valuationTrendChartData, setValuationTrendChartData] = useState([]);

    useEffect(() => {
        setIsLoaded(false)
        setIsGraphLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location.pathname])

    const getCompanyInformation = async (slug) => {
        try {
            const resp = await getCompanyInformationService(slug);
            setIsLoaded(true)
            if (resp && resp.success) {
                const companyInfoResp = resp.data[0];
                setCompanyInfo(companyInfoResp)
                setPortfolioAIInfo(companyInfoResp.portfolio_ai_info ? companyInfoResp.portfolio_ai_info : null)
            }
        }
        catch (e) { }
    }

    useEffect(() => {
        if (portfolioAIInfo) {
            if (portfolioAIInfo.valuationOverTime) {
                const valuationInfo = portfolioAIInfo.valuationOverTime.map(valuation => {
                    return { date: convertToDate(valuation.date), value: valuation.key }
                })
                setValuationChartData(valuationInfo)
            }
            if (portfolioAIInfo.charts && portfolioAIInfo.charts.sectorBreakdown) {
                const spendInfo = portfolioAIInfo.charts.sectorBreakdown.map(sector => {
                    return { sector: sector.sector, size: sector.proportion }
                })
                setSectorBreakdown(spendInfo)
            }
            if (portfolioAIInfo.charts && portfolioAIInfo.charts.purchaseType) {
                getPurchaseTypeDataInfo(portfolioAIInfo.charts.purchaseType)
            }
            if (portfolioAIInfo.widgets && portfolioAIInfo.widgets.totalSpendOverTime) {
                const spendTimeInfo = portfolioAIInfo.widgets.totalSpendOverTime.map(growth => {
                    return { date: convertToDate(growth.year), value: growth.spend }
                })
                setSpendOverTimeChartData(spendTimeInfo)
            }
            setIsGraphLoaded(true)
        }
    }, [portfolioAIInfo])

    const getPurchaseTypeDataInfo = (data) => {
        setIncomePeriodData(data);
        setCategoryField('type');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'type');
        setIncomeSeries(items)
    }

    useEffect(() => {
        const colors = [
            "#47abe1",
            "#9bbfd0",
            "#61aabb",
            "#73cfbf",
            "#070b3180",
            "#47abe180",
            "#9bbfd080",
            "#61aabb80",
            "#73cfbf80",
            "#47ab00",
            "#9bbf00",
            "#61aa00",
            "#73cf00"
        ]
        if (portfolioAIInfo && portfolioAIInfo.valuationTrendsData && portfolioAIInfo.valuationTrendsData.length) {
            const _valuationTrendChartData = portfolioAIInfo.valuationTrendsData.map((trend, i) => {
                return {
                    category: trend.year,
                    value: trend.increase,
                    open: i === 0 || portfolioAIInfo.valuationTrendsData.length-1 === i ? 0 : trend.decrease,
                    stepValue: trend.increase,
                    columnConfig: {
                        fill: colors[i] ? colors[i] : generateRandomHexColor(),
                    },
                    displayValue: trend.total
                }
            })
            setValuationTrendChartData(_valuationTrendChartData)
        }
    }, [portfolioAIInfo])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-4">
                    <div className="card-deck">
                        <div className="card col shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-money mr-1" aria-hidden="true"></i> Equity Invested</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.valuationKPIs?.equityInvested || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-usd mr-1" aria-hidden="true"></i> Equity Value</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.valuationKPIs?.equityValue || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-binoculars mr-1" aria-hidden="true"></i> Fully Diluted Ownership</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.valuationKPIs?.fullyDilutedOwnership || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-flask mr-1" aria-hidden="true"></i> MOIC</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.valuationKPIs?.moic || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Over Time</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                spendOverTimeChartData.length ?
                                                    <TrendChartComponent id="valuationOverTime" height="20rem" chartInfo={valuationChartData} /> :
                                                    <EmptyCardsContent title="No Spend Over Time found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Trends</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                valuationTrendChartData.length ?
                                                    <WaterFallChartComponent id="valuationTrends" data={valuationTrendChartData} height="20rem" /> :
                                                    <EmptyCardsContent title="No Purchase Type found" buttonView={false} />
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Over Time Breakdown</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-muted" scope="col">Date</th>
                                                    <th className="text-muted" scope="col">Valuation</th>
                                                    <th className="text-muted" scope="col">Key</th>
                                                    <th className="text-muted" scope="col">Growth Rate</th>
                                                    <th className="text-muted" scope="col">Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    portfolioAIInfo && portfolioAIInfo.valuationOverTime && portfolioAIInfo.valuationOverTime.length ? portfolioAIInfo.valuationOverTime.map((valuation, i) => {
                                                        return (
                                                            <tr key={`valuation-time-details-${i}`}>
                                                                <td>{moment(convertToDate(valuation.date)).format("MMM DD, YYYY")}</td>
                                                                <td>{valuation.valuation}</td>
                                                                <td>{valuation.key}</td>
                                                                <td>{valuation.growthRate}</td>
                                                                <td>{valuation.reason}</td>
                                                            </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}