import React, { useContext, useEffect, useState } from "react";
import homeLabels from "../../utils/property-file/componentOverview.json";
import modelListJson from "../../data/AIModel/modelList.json";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "../../data/AIModel/recentActivity.json";
import { useLocation } from "react-router-dom";
import { AIModelCardComponent } from "./AIAgentCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const AIModelOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'AI Agents', isActive: true, hasSubMenu: true },
                { slug: '/ai-agent/overview', menuName: 'Dashboard', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [modelList, setModelList] = useState([])
    const [activity, setActivity] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [appInfo, setAppInfo] = useState({
        title: "",
        description: "",
        category: "",
        icon: "",
        apps: [],
        isEnabled: true,
        isPinned: true,
        features: [],
        specifications: []
    })

    useEffect(() => {
        let items = homeLabels.find(l => l.FEATURE === "AI_MODELS").items;
        setLabels(items)
    }, [homeLabels])

    useEffect(() => {
        setModelList(modelListJson)
    }, [modelListJson])

    useEffect(() => {
        setActivity(recentActivityJson)
    }, [recentActivityJson])

    const appInfoForModal = (app) => {        
        setAppInfo(app)
    }

    const agentAppInformationModal = () => {
        return (
            <div className="modal fade" id="agentAppInfoModal" tabIndex="-1" role="dialog" aria-labelledby="agentAppInfoModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <b>{appInfo.title}</b>
                            {/* <h5 className="modal-title" id="deleteReportModalLabel">{reportForm.DELETE.TITLE}</h5> */}
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="pricing-header pb-md-4 mx-auto text-center">
                                    {/* <h4>{appInfo.title}</h4> */}
                                    <img src="https://cdn.prod.website-files.com/65b8cd72835ceeacd4449a53/667b080e4b3ca12dc5d5d439_Langgraph%20UI-2.webp" height="430rem" />
                                    <p className="lead pb-0 mb-0 px-3">{appInfo.description}</p>
                                </div>

                                <div className="container">
                                    <div className="card-deck text-center">
                                        <div className="card box-shadow">
                                            <div className="card-header">
                                                <b>Overview</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    <li>Name : <b>{appInfo.title}</b></li>
                                                    <li>Category: <b>{appInfo.category}</b></li>
                                                    <li>Used By: <b>{appInfo.apps.join(', ')}</b></li>
                                                    {/* <li>Used By: {
                                                        appInfo.apps.map((f, i) => {
                                                            return (
                                                                <span key={`ai-agent-apps-${i + 1}`} className={`badge bl-filter-chip d-inline-block mb-2`}>{f}</span>
                                                            )
                                                        })
                                                    }</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card box-shadow">
                                            <div className="card-header">
                                                <b>Key Features</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    {
                                                        appInfo.features.map((feat, i) => {
                                                          return <li key={`key-feat-app-${i}`}>{feat}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card box-shadow">
                                            <div className="card-header">
                                                <b>Specifications</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    {
                                                        appInfo.specifications.map((spec, i) => {
                                                           return <li key={`key-spec-app-${i}`}>{spec}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">

                        <section className="mb-3">
                            <div className="media py-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    <button className="btn btn-primary btn-sm my-3">
                                        <i className="fa fa-fw fa-plus-circle mr-2" aria-hidden="true"></i>Configure New AI Agent
                                    </button>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>



                        {/* <section className="mb-3">
                            <h6 className="mb-3">{labels.GUIDE}</h6>
                            <div className="row">
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/notebooks-for-workbench-card-image-v2.png'} alt="explore icon" className="card-img-top img-fluid bl-w-9rem align-self-center"/>
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_1}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_1}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/pipelines-for-mlops-card-image-v2.png'} alt="training icon" className="card-img-top img-fluid bl-w-9rem align-self-center" />
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_2}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_2}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/pretrained-model-apis-card-image-v2.png'} alt="deploy icon" className="card-img-top img-fluid bl-w-9rem align-self-center" />
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_3}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <section className="mb-3">
                            {/* <h6 className="mb-3">{labels.RECOMMEND}</h6> */}
                            <div className="card-deck">
                                {
                                    modelList.map((app, i) => {
                                        return (
                                            <AIModelCardComponent key={`ai-model-card-app-${i}`} app={app} appInfo={appInfoForModal}></AIModelCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>

                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray d-none">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>
                </div>

                {agentAppInformationModal()}


            </div>
        </div>
    )
}