import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";


const ColoredHeatmapChart = ({ data, yAxisData, xAxisData, height = '500px', dashHeat, yAxisVisibility=true, xAxisVisibility=true, categoryYField='weekday', categoryXField='hour', id='coloredHeatMapDiv', minColor='#edf6ff', maxColor='#5eb0ef', legendVisibility=true }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let heatRoot = am5.Root.new(id);

        heatRoot.setThemes([
            am5themes_Animated.new(heatRoot)
        ]);
        heatRoot._logo.dispose();

        var chart = heatRoot.container.children.push(am5xy.XYChart.new(heatRoot, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: heatRoot.verticalLayout,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 0
        }));

        // Create axes and their renderers
        var yRenderer = am5xy.AxisRendererY.new(heatRoot, {
            visible: false,
            minGridDistance: 30,
            inversed: true
        });

        yRenderer.grid.template.set("visible", false);

        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(heatRoot, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: categoryYField,
            visible: yAxisVisibility
        }));

        yAxis.get("renderer").labels.template.setAll({
            centerX: am5.p0,
            fontSize: 12
        });

        var xRenderer = am5xy.AxisRendererX.new(heatRoot, {
            visible: false,
            minGridDistance: 30,
            opposite: true
        });

        xRenderer.grid.template.set("visible", false);

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(heatRoot, {
            renderer: xRenderer,
            categoryField: categoryXField,
            oversizedBehavior: 'wrap',
            visible: xAxisVisibility
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12
        });

        const colors = {
            gray: am5.color("#fdfebf"), /*--not started--*/
            yellow: am5.color("#fbe08b"), /*--pending--*/
            green: am5.color("#c6e7a3"), /*--completed--*/
            pink: am5.color("#f69376") /*--overdue--*/
        };

        function getColor(value) {
            if (value > 0 && value <= 25) return colors.gray;
            if (value > 25 && value <= 50) return colors.pink;
            if (value > 50 && value <= 75) return colors.yellow;
            if (value > 75 && value <= 100) return colors.green;
            return am5.color("#FFFFFF");
        }

        var series = chart.series.push(am5xy.ColumnSeries.new(heatRoot, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: categoryXField,
            categoryYField: categoryYField,
            valueField: "value"
        }));

        series.columns.template.setAll({
            tooltipText:
                `{company}, {period}: {status}`,
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100),
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            fontSize: 12
        });

        series.columns.template.events.on("pointerover", function (event) {
            var di = event.target.dataItem;
            if (di) {
                // heatLegend.showValue(di.get("value", 0));
            }
        });

        let selectedRow = null;
        series.columns.template.events.on('click', function (e) {
            const selectedCompany = e.target.dataItem.dataContext.company;
            dashHeat(e.target.dataItem.dataContext)            
            yAxis.dataItems.forEach(function (dataItem) {
                const category = dataItem.get("category");
                const label = dataItem.get("label");
                if (label) {
                    label.setAll({
                        fontWeight: "normal"
                    });
                }
            });
        
            // Highlight the selected Y-axis label
            yAxis.dataItems.forEach(function (dataItem) {
                const category = dataItem.get("category");
                if (category === selectedCompany) {
                    const label = dataItem.get("label");
                    if (label) {
                        label.setAll({
                            fontWeight: "bold" // Bold the selected row
                        });
                    }
                }
            });
            // series.columns.each(function (column) {
            //     const columnData = column.dataItem.dataContext;
            //     if (columnData.company === selectedCompany) {
            //         column.set("stroke", am5.color("#000000")); 
            //         column.set("strokeWidth", 2);
            //     } else {
            //         column.set("stroke", am5.color("#FFFFFF"));
            //         column.set("strokeWidth", 1);
            //     }
            // });

            selectedRow = selectedCompany;
        })

        series.events.on("datavalidated", function () {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });

        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color(minColor),
            max: am5.color(maxColor),
            dataField: "value",
            key: "fill"
        }]);

        series.columns.template.adapters.add("fill", function(fill, target) {
            const value = target.dataItem?.get("value") || 0;
            return getColor(value);
        });



        var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(heatRoot, {
            orientation: "horizontal",
            endColor: am5.color(minColor),
            startColor: am5.color(maxColor),
            visible: legendVisibility,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            fontSize: 12
        }));


        series.data.setAll(data);


        yAxis.data.setAll(yAxisData);
        
        yAxis.events.on("datavalidated", () => {
            if (yAxis.dataItems.length > 0) {
                const firstDataItem = yAxis.dataItems[0];
                const firstLabel = firstDataItem.get("label");
                if (firstLabel) {
                    firstLabel.setAll({
                        fontWeight: "bold"
                    });
                }
            }
        });

        let xAxisItem = [
            { hour: "Jan" },
            { hour: "Feb" },
            { hour: "Mar" },
            { hour: "Apr" },
            { hour: "May" },
            { hour: "Jun" },
            { hour: "Jul" },
            { hour: "Aug" }
        ]

        xAxis.data.setAll(xAxisData ? xAxisData : xAxisItem);

        setChartRoot(heatRoot);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};

export default ColoredHeatmapChart;
