import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "./../../data/recentActivity.json";
import sourcingIcon from "./../../assets/img/sourcing.png";
import { Column } from "primereact/column";
import companyJson from "./../../data/Risk/companyRiskProfile.json";
import { BoldPieChart } from "../Graph/boldPieChart";
import { MapChartComponent } from "../Graph/mapchart";
import { FunnelChartComponent } from "../Graph/funnelchart";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyListService, removeCompanyService } from "../../services/companyService";
import { DataTable } from "primereact/datatable";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import moment from "moment";
import { SourcingContext } from "../../services/context/sourcingContext";
import missingImg from "./../../assets/img/logoplaceholder.png";


export const SourcingOverviewComponent = () => {

    // window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { sourcingCompanyList, setSourcingCompanyList } = useContext(SourcingContext)
    const nav = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [nodes, setNodes] = useState([]);
    const [filter] = useState([
        { name: 'Recently Added', val: 'recent', isActive: true },
        { name: 'Trending Companies', val: 'Trending_Companies', isActive: false },
        { name: 'In Current News', val: 'In_Current_News', isActive: false },
        { name: 'Unicorns', val: 'Unicorns', isActive: false }
    ]);
    const [activity, setActivity] = useState([]);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [companyList, setCompanyList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [selectedCompanyName, setSelectedCompanyName] = useState('')

    useEffect(() => {
        console.log("Companies ===>", sourcingCompanyList.slice(0, 5));
        setIsLoaded(sourcingCompanyList.length > 0)
        if (sourcingCompanyList && sourcingCompanyList.length) {
            setCompanyList([...sourcingCompanyList])
        }
    }, [sourcingCompanyList])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "SOURCING").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setNodes(loadNodes(0, companyJson))
    }, [companyJson])

    useEffect(() => {
        let items = [];
        for (let i = 0; i < 6; i++) {
            items.push(recentActivityJson[i])
        }
        setActivity(items)
    }, [recentActivityJson])

    const getCompanyList = async (page) => {
        const limit = 10;
        if (page === 1) {
            setIsLoaded(false)
        }
        const resp = await getCompanyListService(page, limit, false);
        setIsLoaded(true)
        getCompanyListResponse(page, resp, limit)
    }

    const getCompanyListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setSourcingCompanyList(companies => [...companies, ...newCompanyList])
            if (resp.data.length >= limit) {
                getCompanyList(page + 1);
            }
        }
    }

    const loadNodes = (first, rows) => {
        let nodes = [];
        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `table-company-view-key-${first + i}`,
                data: {
                    companyId: rows[i].companyId,
                    company: rows[i].company,
                    address: rows[i].address,
                    yearfounded: rows[i].yearfounded,
                    revenue: `$${rows[i].revenue} M`,
                    noofemployees: rows[i].totalEmployee,
                    businessdescription: rows[i].description,
                    keyexecutives: rows[i].keyexecutives,
                    slug: rows[i].slug,
                    logo: rows[i].logo,
                    blScore: rows[i].blScore
                }
            }
            nodes.push(node);
        }
        return nodes;
    };

    function concatenateWithCommas(street, locality, country) {
        let address = [];
        if (street) {
            address.push(street)
        }
        if (locality) {
            address.push(locality)
        }
        if (country) {
            address.push(country)
        }
        const resultString = address.join(', ');
        return resultString;
    }

    const removeCompanyContent = (e, data) => {
        e.preventDefault();
        setSelectedCompanyId(data.companyId)
        setSelectedCompanyName(data.company)
        window.$("#deletesourcingcompanymodal").modal('toggle');
    }

    const removeCompany = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        const resp = await removeCompanyService(selectedCompanyId);
        if (resp && resp.success) {
            window.$("#deletesourcingcompanymodal").modal('toggle');
            setSourcingCompanyList([])
            setCompanyList([])
            setTimeout(() => {
                if (pageLoader) {
                    pageLoader.style.display = 'none';
                }
                setTimeout(() => {
                    getCompanyList(1)
                    alert("Company removed successfully!");
                })
            }, 200)
        }
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
    }

    function containsProtocolOrWWW(url) {
        const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
        return pattern.test(url);
    }

    const getSourceCompanyName = (data) => {
        if (data.company && !containsProtocolOrWWW(data.company)) {
            return data.company;
        }
        else {
            return data.external_data && data.external_data.companyName ? data.external_data.companyName : data.company ? data.company : ''
        }
    }

    const arrangeColumnValue = (item, column) => {
        const data = item;
        const compDate = data.updated_at ? data.updated_at : moment();
        const diffInHours = moment().diff(moment(compDate), 'hours', true)
        const isGeneratingCompany = Math.abs(diffInHours) < 1 ? item.reachedMaxLimit ? false : (item.source === 'company' && !item.linkedinCompanyId && !item.external_data) : false;
        const collectingExternalData = Math.abs(diffInHours) < 1 ? item.reachedMaxLimit ? false : (item.source === 'company' && !item.external_data) : false;
        if (column === 'ICON') {
            const companyLogo = data.logo ? data.logo : missingImg;
            return (
                <div className="d-flex align-items-center">
                    {
                        isGeneratingCompany ? <span className="spin-loader"></span> : <img src={companyLogo} alt="logo" onError={handleImageError} className="img-fluid bl-report-thumbnail" />
                    }
                </div>
            )
        }
        else if (column === 'COMPANY') {
            const contryFlag = data.country ? (data.country).toLowerCase() : 'us';
            const address = data.address ? data.address.replace(/undefined,?/g, '').trim() : null;
            const isDealProject = data.source && data.source.toLowerCase() === "project";
            const dealOverview = data.projectInfo && data.projectInfo.data && data.projectInfo.data.dealOverview ? data.projectInfo.data.dealOverview : null;
            const companyOverview = data.projectInfo && data.projectInfo.data && data.projectInfo.data.companyOverview ? data.projectInfo.data.companyOverview : null;
            if (isDealProject) {
                return (
                    <>
                        <h6 className="text-nowrap">{data.company} <span className="badge bl-deal-badge">Project/Deal</span></h6>
                        <div className="d-flex">
                            <div>
                                <i className="fa fa-fw fa-building" aria-hidden="true"></i> {dealOverview.companyName}
                            </div>
                            <div className="mx-2">
                                <i className="fa fa-fw fa-industry" aria-hidden="true"></i> {dealOverview.industry}
                            </div>
                            <div>
                                <i className="fa fa-fw fa-map-marker" aria-hidden="true"></i> {dealOverview.geography}
                            </div>
                        </div>
                        <div className="d-flex font-small py-1">
                            <div>
                                <span className="text-muted">Deal Source</span> {dealOverview.dealSource}
                            </div>
                            <div className="mx-2">
                                <span className="text-muted">Source Type</span> {dealOverview.sourceType}
                            </div>
                            <div>
                                <span className="text-muted">Deal Type</span> {dealOverview.dealType}
                            </div>
                        </div>
                        <p className="mb-0 bl-truncate-line-2">{companyOverview.description}</p>
                    </>
                )
            }
            return (
                <>
                    <h6 className="text-nowrap">
                        {getSourceCompanyName(data)}
                    </h6>
                    <div className="d-flex align-self-center mr-2 mb-1">
                        {
                            address || concatenateWithCommas(data.street, data.locality, data.country) ? <span className={`flag-icon flag-icon-${contryFlag} mr-2`}></span> : null
                        }

                        {
                            address ? <p className="mb-0">{address}</p> : data.external_data && data.external_data.headquarters ? data.external_data.headquarters : <p className="mb-0">{concatenateWithCommas(data.street, data.locality, data.country)}</p>
                        }

                    </div>
                    <div className="d-flex align-self-center mb-1">
                        {
                            data.yearfounded && <p className="mb-0 mr-1"><i className="fa fa-fw fa-globe" aria-hidden="true"></i> Founded {data.yearfounded} |</p>
                        }
                        {
                            data.revenue && <p className="mb-0 mr-1"><i className="fa fa-fw fa-usd" aria-hidden="true"></i> {data.revenue} |</p>
                        }
                        {
                            data.type && <p className="mb-0 mr-1"><i className="fa fa-fw fa-building-o" aria-hidden="true"></i> {data.type} |</p>
                        }
                        {
                            data.totalEmployee && <p className="mb-0"><i className="fa fa-fw fa-users" aria-hidden="true"></i> {data.totalEmployee} Employees</p>
                        }
                    </div>
                    <p className="mb-0 text-gray bl-desc-truncate-line-2">{data.external_data && data.external_data.aboutUs ? data.external_data.aboutUs : data.description ? data.description : ''}</p>
                </>
            )
        }
        else if (column === 'EXECUTIVES') {
            const executives = data.keyexecutives.slice(0, 3);
            const sectors = data.specialty.slice(0, 4);
            const isDealProject = data.source && data.source.toLowerCase() === "project";
            const considerations = data.projectInfo && data.projectInfo.data && data.projectInfo.data.considerations ? data.projectInfo.data.considerations : null;
            const marketAndCompetitivePosition = data.projectInfo && data.projectInfo.data && data.projectInfo.data.marketAndCompetitivePosition ? data.projectInfo.data.marketAndCompetitivePosition : null;
            if (isDealProject && considerations && marketAndCompetitivePosition) {
                return (
                    <div className="card">
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-6">
                                    <p className="bl-font-size-12px mb-1 font-small text-muted">Considerations:</p>
                                    <table className="bl-deal-consideration-sec" width={'100%'}>
                                        <tbody>
                                            <tr>
                                                <td className="bl-font-size-12px" width={'65%'}>Strategic Fit</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.strategicFit.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.strategicFit.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Synergy Potential</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Exit Strategy Viability</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <p className="bl-font-size-12px mb-1 font-small text-muted">Market & Competitive Position:</p>
                                    <table className="bl-deal-consideration-sec" width={'100%'}>
                                        <tbody>
                                            <tr>
                                                <td width={'65%'}>Market Growth Potential</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Competitive Landscape</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Value Proposition</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="card bg-light border-0">
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-6">
                                    <p className="bl-font-size-12px mb-1 font-small text-muted">Key Executives:</p>
                                    {
                                        executives.map((ex, i) => {
                                            return (
                                                <div key={`executives-${i}`} className="media mb-1">
                                                    <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i>
                                                    {/* <img className="align-self-center mr-2" src="https://i.pinimg.com/474x/3b/5e/6c/3b5e6c7caaeeba45303704e74f7f92b5.jpg" alt="Generic placeholder image" height="20px" /> */}
                                                    <div className="media-body">
                                                        <p className="bl-font-size-12px mb-0 bl-desc-truncate-line-1 text-capitalize">{ex.Name}</p>
                                                        <p className="mb-0 font-smaller text-gray bl-desc-truncate-line-1">{ex.Title}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        executives.length === 0 && <div className="media mb-1">
                                            <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i>
                                            <div className="media-body">
                                                <p className="bl-font-size-12px mb-0 text-muted"><i>People information not available publicly</i></p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-6">
                                    <table className="mt-3 w-100">
                                        <tbody>
                                            <tr>
                                                <td className="text-muted font-small" width={'30%'}><i className="fa fa-industry mr-1" aria-hidden="true"></i> Industry:</td>
                                                <td className="bl-font-size-12px bl-truncate-line-2 text-capitalize">{data.industry}</td>
                                            </tr>
                                            <tr>
                                                <td className="align-top text-muted font-small" width={'30%'}><i className="fa fa-pie-chart mr-1" aria-hidden="true"></i> Sector:</td>
                                                <td>
                                                    {
                                                        sectors.map((sec, i) => {
                                                            return <p key={`company-sector-${i}`} className="mb-0 bl-truncate-line-1 bl-font-size-12px text-capitalize">- {sec.specialty}</p>
                                                        })
                                                    }
                                                    {
                                                        sectors.length === 0 && <p className="bl-font-size-12px mb-0 text-muted"><i>NA</i></p>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else if (column === 'SEED') {
            return (
                <>
                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Seed Round: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$2M(2018)</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Series A: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$10M(2019)</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Series B: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$12M(2022)</p>
                        </div>
                    </div>
                </>
            )
        }
        else if (column === 'CHART') {
            if (isGeneratingCompany || collectingExternalData) {
                return <p className="mb-0 font-small"><span className="line-loader"></span> <br />
                    Connecting to additional datasources
                </p>
            }
            let time = moment().unix();
            const min = 1;
            const max = 100;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min + parseInt(time);
            return (
                <div className="text-center">
                    <span onClick={(e) => e.stopPropagation()} className="bl-hover-show position-absolute">
                        <i className="fa fa-trash" aria-hidden="true" onClick={(e) => removeCompanyContent(e, data)}></i>
                    </span>
                    <BoldPieChart id={`piechart${data.companyId}-${randomInteger}`} height="60px" score={data.BlScore}></BoldPieChart>
                    <p className="mb-0">BL Score</p>
                </div>
            )
        }
        else {
            return ''
        }

    }

    const openCompanyProfile = (e) => {
        const item = e.data;
        const isGeneratingCompany = item.source === 'company' && !item.linkedinCompanyId && !item.external_data;
        if (!isGeneratingCompany) {
            const navPage = e.data.source.toLowerCase() === 'project' ? 'summary' : 'profile';
            nav(`/sourcing/company/${e.data.slug}/${navPage}`)
        }

    }

    const deleteCompanyModalContent = () => {
        return (
            <div className="modal fade" id="deletesourcingcompanymodal" tabIndex="-1" role="dialog" aria-labelledby="deleteCompanyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteCompanyModalLabel">Delete Company</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to delete <b>{selectedCompanyName}</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => removeCompany(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between">
                                    <div className="align-self-center mr-16">
                                        <h5>{labels.WELCOME}</h5>
                                        <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    </div>
                                    <img className="bl-overview-img" src={sourcingIcon} alt="overview-Img" />
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        {/* <img className="card-img-top" src={sourcingW1} alt="Card image cap"/> */}
                                        <div className="card-header d-flex justify-content-between px-3">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_1}</h6>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">1325</h6>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row bl-minh-10rem">
                                                <div className="col d-grid">
                                                    <div>
                                                        <h6 className="mb-0">237</h6>
                                                        <p className="text-gray">In Pipeline</p>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <h6 className="mb-0">9.5 Months</h6>
                                                        <p className="text-gray">Deal Cycle Time</p>
                                                    </div>
                                                    {/* <div className="align-self-center">
                                                    <h6 className="mb-0">$350,000</h6>
                                                    <p className="text-gray">Avg Acquisition Cost</p>
                                                </div> */}
                                                </div>
                                                <div className="col d-grid">
                                                    <div>
                                                        <h6 className="mb-0">$150 M</h6>
                                                        <p className="text-gray">Avg Valuation</p>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <h6 className="mb-0">38%</h6>
                                                        <p className="text-gray">Deal Success Rate</p>
                                                    </div>
                                                    {/* <div className="align-self-center">
                                                    <h6 className="mb-0">27%</h6>
                                                    <p className="text-gray">Average ROI</p>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        {/* <img className="card-img-top" src={sourcingW2} alt="Card image cap"/> */}
                                        <div className="card-header d-flex justify-content-between px-3">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_2}</h6>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">85</h6>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <table width={'100%'}>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-gray">Prospect to Due Diligence</td>
                                                        <td rowSpan={3} className="w-40">
                                                            <FunnelChartComponent height="10rem"></FunnelChartComponent>
                                                        </td>
                                                        <td className="bl-text-due-deligence text-right">65%</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-gray">Due Diligence to Negotiation</td>
                                                        <td className="bl-text-negotiation text-right">45%</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-gray">Negotiation to Closed</td>
                                                        <td className="bl-text-closed text-right">45%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        {/* <img className="card-img-top" src={sourcingW3} alt="Card image cap"/> */}
                                        <div className="card-header d-flex justify-content-between px-3">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_3}</h6>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <MapChartComponent height="10rem"></MapChartComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div>
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="d-flex pb-2">
                                                    {
                                                        filter.map((f, i) => {
                                                            return (
                                                                <span key={`risk-cate-filter-${i + 1}`} className={`badge bl-filter-chip ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                !isLoaded ? (
                                                    <div className="col text-center">
                                                        <img className="bl-list-loader" src={listViewLoader} />
                                                    </div>
                                                ) : (
                                                    <DataTable value={companyList} rowClassName={"bl-source-overview-list position-relative"} className="cp" selectionMode="single" onRowClick={openCompanyProfile}>
                                                        <Column field="valuation" style={{ width: '5%' }} body={(e) => arrangeColumnValue(e, 'ICON')}></Column>
                                                        <Column field="company" body={(e) => arrangeColumnValue(e, 'COMPANY')}></Column>
                                                        <Column body={(e) => arrangeColumnValue(e, 'EXECUTIVES')} style={{ width: '50%' }}></Column>
                                                        {/* <Column body={(e) => arrangeColumnValue(e, 'SEED')} style={{ width: '17%' }}></Column> */}
                                                        <Column body={(e) => arrangeColumnValue(e, 'CHART')} style={{ width: '9%', textAlign: 'center' }}></Column>
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>
                </div>
                {
                    deleteCompanyModalContent()
                }
            </div>

        </div>
    )

}