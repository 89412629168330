import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";


export const SimpleTreeMapChart = ({ data, height = '500px', id = 'treeMapChartDiv' }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    let series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        upDepth: -1,
        initialDepth: 2,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 0,
        nodePaddingInner: 0
      })
    );

    series.rectangles.template.setAll({
      strokeWidth: 2
    });

    const customColors = [
      "#47abe1",
      "#9bbfd0",
      "#61aabb",
      "#73cfbf",
      "#070b3180",
      "#47abe180",
      "#9bbfd080",
      "#61aabb80",
      "#73cfbf80",
      "#47ab00",
      "#9bbf00",
      "#61aa00",
      "#73cf00"
    ];

    const colorSet = am5.ColorSet.new(root, {
      colors: customColors.map(color => am5.color(color)),
      passOptions: {}
    });

    series.set("colors", colorSet);

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    series.appear(1000, 100);

    setChartRoot(root);

    return () => {
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: height }}></div>;
};