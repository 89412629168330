import React, { useContext, useEffect, useRef, useState } from "react";
import ask_question from "./../../assets/icons/app/AI_Icon.png";
import { ESGScoreCardComponent } from "../Common/esgScoreCard";
import sourceprofile2Pic from "./../../assets/img/sourceprofile2.png";
import companyProfile from "../../data/Risk/companyRiskProfile.json";
import { ColumnChart } from "../Graph/columnChart";
import suggestiveQuestionJson from "../../data/suggestiveQuestion.json";
import AIAnswersJson from "../../data/AIAnswers.json";
import { AImodalTableView } from "./AIModalTable";
import insightLightIcon from "./../../assets/img/insight_light.svg";
import reportIcon from "./../../assets/img/report_icon.png";
import taskIcon from "./../../assets/img/task_icon.png";
import { useNavigate } from "react-router-dom";
import { AIListViewAnswerComponent } from "./AIListViewAns";
import typedQuestionList from "./../../data/AIAssistant/userTypedQuestion.json";
import { canabisQuestionInputForServerService, cannabisFileUploadService, cannabisQueryService, fileUploadService, getUploadedFilesService, questionInputForServerService } from "../../services/commonService";
import AIContext from "../../services/context/AIAssistance/aiContext";
import moment from "moment";
import { TrendChartComponent } from "../Graph/trendChart";
import { PeopleListComponent } from "../Sourcing/Common/peopleListView";
import { AuthContext } from "../../services/context/Auth/authContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SimpleTreeMapChart } from "../Graph/simpleTreeMap";
import { HorizontalBarChart } from "../Graph/horizontalBarChart";
import ColoredHeatmapChart from "../Graph/coloredHeatMap";


export const AIAssistantModalComponent = () => {

    const nav = useNavigate();
    const { aiModalType, setAIModalType, aiModalTitle, companyProfileWidget } = useContext(AIContext);
    const { isCanabisApp } = useContext(AuthContext)

    const sourceMenuDefaultData = [
        {
            type: 'FILE',
            name: "Files",
            items: []
        },
        {
            type: 'REPORT',
            name: "Reports",
            items: [
                {
                    file_name: 'Sales Dashboard',
                    file_url: '',
                    file_image: 'https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png',
                    isSelected: false
                },
                {
                    file_name: 'Performance Model',
                    file_url: '',
                    file_image: 'https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png',
                    isSelected: false
                },
                {
                    file_name: 'Valuation Model',
                    file_url: '',
                    file_image: 'https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png',
                    isSelected: false
                }
            ]
        },
        {
            type: 'NEWS',
            name: 'News & Social',
            items: [
                {
                    file_name: "Google News",
                    file_url: "",
                    file_image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Google_News_icon.svg/512px-Google_News_icon.svg.png",
                    isSelected: false
                },
                {
                    file_name: "Reddit",
                    file_url: "",
                    file_image: "https://static-00.iconduck.com/assets.00/reddit-icon-256x256-60kyb8pn.png",
                    isSelected: aiModalType === 'REDDIT'
                },
                {
                    file_name: "Reuters",
                    file_url: "",
                    file_image: "https://cdn-1.webcatalog.io/catalog/reuters/reuters-icon-filled-256.png?v=1675613809018",
                    isSelected: false
                },
                {
                    file_name: "LinkedIn",
                    file_url: "",
                    file_image: "https://cdn-icons-png.flaticon.com/256/174/174857.png",
                    isSelected: false
                }
            ]
        },
        {
            type: "DATABASE",
            name: "DATABASES",
            items: [
                {
                    file_name: "Snowflake",
                    file_url: "",
                    file_image: "https://styles.redditmedia.com/t5_318fi/styles/communityIcon_0kgtnqe3shi61.png",
                    isSelected: false
                },
                {
                    file_name: "SQL Server",
                    file_url: "",
                    file_image: "https://cdn.iconscout.com/icon/free/png-256/free-sql-4-190807.png",
                    isSelected: false
                }
            ]
        }
    ]
    const [showMinimizeIcon, setShowMinimizeIcon] = useState(true);
    const [showMaximizeIcon, setShowMaximizeIcon] = useState(false);
    const [uploadedFileOriginalName, setUploadedFileOriginalName] = useState("");
    const [defaultCardHeader, setDefaultCardHeader] = useState(aiModalTitle)
    const [suggestiveQuestion, setSuggestiveQuestion] = useState([])
    const [isQuestionAsked, setIsQuestionAsked] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [assistantAnswer, setAssistantAnswer] = useState([])
    const [modalContentType, setModalContentType] = useState(aiModalType)
    const [userQuestion, setUserQuestion] = useState('')
    const [viewType, setViewType] = useState('DATA_SOURCES');
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [dataSourceMenu, setDataSourceMenu] = useState(sourceMenuDefaultData)
    const [selectedSource, setSelectedSource] = useState([])


    useEffect(() => {
        setIsFileUploaded(false)
        getUploadedFiles()
    }, [])

    useEffect(() => {
        setUploadedFilesToSourceMenu()
    }, [uploadedFiles])

    const setUploadedFilesToSourceMenu = (items) => {
        const dataSourceMenuItems = items ? items : [...dataSourceMenu];
        const indx = dataSourceMenuItems.findIndex(d => d.type === 'FILE');
        if (indx !== -1) {
            const fileList = uploadedFiles.map((f, i) => {
                let selectedFile = false;
                if (isFileUploaded && i == 0) {
                    selectedFile = true;
                }
                else {
                    const oldIndx = dataSourceMenuItems[indx].items.findIndex(item => moment(item.created_on).isSame(moment(f.created_on)) && item.isSelected);
                    selectedFile = oldIndx !== -1;
                }
                return {
                    file_name: f.file_name,
                    file_url: f.file_url,
                    file_image: "https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png",
                    isSelected: selectedFile,
                    created_on: f.created_on,
                    originalFileName: f.originalFileName
                }
            })
            dataSourceMenuItems[indx].items = fileList;
            setDataSourceMenu(dataSourceMenuItems)
        }
    }

    useEffect(() => {
        let selectedFiles = [];
        dataSourceMenu.map(menu => {
            let files = menu.items.filter(item => item.isSelected);
            selectedFiles.push(...files)
        })
        setSelectedSource(selectedFiles)
    }, [dataSourceMenu])

    const getUploadedFiles = async () => {
        let obj = {
            page: 1,
            pageSize: 5
        }
        const resp = await getUploadedFilesService(obj);
        if (resp && resp.success) {
            if (resp.data.questions) {
                setUploadedFiles(resp.data.questions)
            }
        }
    }

    const scrollToDown = () => {
        setTimeout(() => {
            let modalElement = document.getElementById('aimodalcontent');
            if (modalElement) {
                modalElement.scrollTop = modalElement.scrollHeight;
            }

        }, 10)
    }

    const modalMinimize = () => {
        setShowMaximizeIcon(true)
        setShowMinimizeIcon(false)
        let body = document.getElementById('approot');
        body.classList.add('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.add('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.add('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.add('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.add('d-none')
    }

    const modalMaximize = () => {
        setShowMaximizeIcon(false);
        setShowMinimizeIcon(true)
        let body = document.getElementById('approot');
        body.classList.remove('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.remove('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.remove('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.remove('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const modalClose = () => {
        setAIModalType('')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const setDefaultDataValues = () => {
        setDataSourceMenu([...sourceMenuDefaultData])
        setSelectedSource([])
        setTimeout(() => {
            setUploadedFilesToSourceMenu(sourceMenuDefaultData)
        }, 500)
    }

    const redirectToModule = (path) => {
        modalMinimize()
        nav(path)
    }

    const openNewChat = () => {
        scrollToDown()
        setIsFileUploaded(false)
        setDataSourceMenu([])
        setAIModalType('DEFAULT')
        setIsQuestionAsked(false)
        setAssistantAnswer([])
        setModalContentType('DEFAULT')
        modalContentViewMode()
        setTimeout(() => {
            setDefaultDataValues()
        })
    }

    useEffect(() => {
        setUploadedFileOriginalName("")
        setDataSourceMenu([])
        setUserQuestion('')
        setDefaultDataValues()
        setModalContentType(aiModalType === 'REDDIT' ? 'DEFAULT' : aiModalType)
        setIsQuestionAsked(false)
        setAssistantAnswer([])
        modalMaximize()
        scrollToDown()
        setViewType('DATA_SOURCES')
        setShowSuggestion(false)
        setDefaultCardHeader(aiModalTitle)
        if (aiModalType === 'ESG') {
            setShowSuggestion(true)
            getSuggestiveQuestion('ESG')
            setDefaultCardHeader('ESG Scorecard')
        }
        else if (aiModalType === 'FINANCIAL') {
            setShowSuggestion(true)
            getSuggestiveQuestion('FINANCIAL')
            aiModalTitle ? setDefaultCardHeader(aiModalTitle) : setDefaultCardHeader('Financial Summary Over Time')
        }
        else if (aiModalType === 'FINANCIAL_TABLE') {
            setShowSuggestion(true)
            getSuggestiveQuestion('FINANCIAL')
            aiModalTitle ? setDefaultCardHeader(aiModalTitle) : setDefaultCardHeader('Financial Summary')
        }
        else if (aiModalType === 'RISK') {
            setShowSuggestion(true)
            getSuggestiveQuestion('RISK')
            setDefaultCardHeader('Risk Intensity over Time')
        }
        else if (aiModalType === 'VALUATION') {
            setShowSuggestion(true)
            getSuggestiveQuestion('VALUATION')
            setDefaultCardHeader('Valuation over Time')
        }
        else if (aiModalType === 'EMPLOYEE_COUNT') {
            setShowSuggestion(true)
            getSuggestiveQuestion('EMPLOYEE_COUNT')
            setDefaultCardHeader('Employee Count Over Time')
        }
        else if (aiModalType === 'SOCIAL_MEDIA') {
            setShowSuggestion(true)
            getSuggestiveQuestion('SOCIAL_MEDIA')
            setDefaultCardHeader('Social Media Followers Over Time')
        }
        else if (aiModalType === 'KEY_PEOPLE') {
            setShowSuggestion(true)
            getSuggestiveQuestion('KEY_PEOPLE')
            setDefaultCardHeader('Key People')
        }
        else if (['SPEND_BY_REGION', 'SPEND_OVER_TIME', 'SPEND_BY_CATEGORY', 'VENDOR_SPEND_DETAILS', 'VENDOR_SPEND_LOG'].includes(aiModalType)) {
            setShowSuggestion(true)
            getSuggestiveQuestion('VENDOR_SPEND')
            aiModalTitle ? setDefaultCardHeader(aiModalTitle) : setDefaultCardHeader('Vendor Spends')
        }
        else if (aiModalType === 'DEFAULT') {
            getSuggestiveQuestion('DEFAULT')
        }
    }, [aiModalType, aiModalTitle])

    const getSuggestiveQuestion = (mode) => {
        let questions = suggestiveQuestionJson.find(q => q.category === mode).questions;
        setSuggestiveQuestion(questions)
    }

    const defaultModalContentView = () => {
        return (
            <section className="mb-3">
                <div className="text-center bl-mt-10rem">
                    <img src={ask_question} height="75px" className="shadow-lg rounded-circle" />
                </div>
                <div className="card-deck mt-5 mb-3">
                    <div className="card shadow-lg">
                        <div className="card-body">
                            <div className="text-center mb-5">
                                <img src="https://icon-library.com/images/icon-for-chat/icon-for-chat-21.jpg" className="mb-2 mt-3" height="45px" />
                                <h6>Example</h6>
                            </div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Analyze a company's carbon footprint data.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Summarize the diversity initiatives of a company.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Explain cybersecurity measures of Company X.</div>
                            <div className="bg-light p-2 rounded mb-0 font-small">Assess renewable energy investments.</div>
                        </div>
                    </div>

                    <div className="card shadow-lg">
                        <div className="card-body">
                            <div className="text-center mb-5">
                                <img src="https://cdn-icons-png.flaticon.com/512/7364/7364323.png" className="mb-2 mt-3" height="45px" />
                                <h6>Capabilities</h6>
                            </div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Natural language understanding and generation.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Data analysis and predictive modeling.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Image recognition and classification.</div>
                            <div className="bg-light p-2 rounded mb-0 font-small">Task automation and workflow optimization.</div>
                        </div>
                    </div>

                    <div className="card shadow-lg">
                        <div className="card-body">
                            <div className="text-center mb-5">
                                <img src="https://cdn-icons-png.flaticon.com/512/7490/7490434.png" className="mb-2 mt-3" height="45px" />
                                <h6>Limitations</h6>
                            </div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Lack of common sense reasoning.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Data bias and ethical concerns.</div>
                            <div className="bg-light p-2 rounded mb-2 font-small">Limited contextual understanding.</div>
                            <div className="bg-light p-2 rounded mb-0 font-small">Vulnerability to adversarial attacks.</div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        getSuggestiveQuestionContent()
                    }

                </div>
            </section>
        )
    }

    const modalContentViewMode = () => {
        if (modalContentType === 'ESG' || modalContentType === 'OPEN_ESG') {
            return (
                <div className="card-body">
                    <ESGScoreCardComponent id="aimodalesg" scores={companyProfileWidget.ESGScore}></ESGScoreCardComponent>
                </div>
            )
        }
        else if (modalContentType === 'FINANCIAL' || modalContentType === 'OPEN_FINANCIAL') {
            return (
                <div className="card-body">
                    <ColumnChart id="aiincomestatementfinance" data={companyProfileWidget.incomePeriodData} categoryField={companyProfileWidget.categoryField} seriesItem={companyProfileWidget.incomeSeries} height="12rem"></ColumnChart>
                </div>
            )
        }
        else if (modalContentType === 'RISK' || modalContentType === 'OPEN_RISK') {
            return (
                <div className="card-body">
                    <img className="img-fluid" src={sourceprofile2Pic} />
                </div>
            )
        }
        else if (modalContentType === 'VALUATION') {
            return (
                <div className="card-body">
                    <TrendChartComponent id="aimodelvaluation" height="15rem" chartInfo={companyProfileWidget.valuationChartData}></TrendChartComponent>
                </div>
            )
        }
        else if (modalContentType === 'EMPLOYEE_COUNT') {
            return (
                <div className="card-body">
                    <TrendChartComponent id="aimodelemployeecount" height="15rem" chartInfo={companyProfileWidget.employeeChartData}></TrendChartComponent>
                </div>
            )
        }
        else if (modalContentType === 'SOCIAL_MEDIA') {
            return (
                <div className="card-body">
                    <TrendChartComponent id="aimodelsocialmedia" height="15rem" chartInfo={companyProfileWidget.socialMediaFollowers}></TrendChartComponent>
                </div>
            )
        }
        else if (modalContentType === 'KEY_PEOPLE') {
            return (
                <div className="card-body py-0 px-1">
                    <PeopleListComponent list={companyProfileWidget.keyPeople}></PeopleListComponent>
                </div>
            )
        }
        else if (modalContentType === "FINANCIAL_TABLE") {
            const financialStatementTableDetail = companyProfileWidget.tableData;
            const financialStatementTableHeader = companyProfileWidget.tableHeader;
            return (
                <div className="card-body py-0 px-1">
                    <DataTable value={financialStatementTableDetail} className="bl-finance-graph">
                        {
                            financialStatementTableHeader.map((col, i) => {
                                const uniqueVal = Date.now() + Math.random();
                                return (
                                    <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field}></Column>
                                )
                            })
                        }
                    </DataTable>
                </div>
            )
        }
        else if (modalContentType === 'VENDOR_SPEND_DETAILS') {
            return (
                <div className="card-body py-0 px-1">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-muted" scope="col">Vendor Name</th>
                                <th className="text-muted" scope="col">Total Spend</th>
                                <th className="text-muted" scope="col">Category</th>
                                <th className="text-muted" scope="col">Spend %</th>
                                <th className="text-muted" scope="col">Exceptions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companyProfileWidget.vendorSpendDetails && companyProfileWidget.vendorSpendDetails.length ? companyProfileWidget.vendorSpendDetails.map((vendor, i) => {
                                    return (
                                        <tr key={`vendor-spends-details-${i}`}>
                                            <td scope="row">{vendor.vendorName}</td>
                                            <td>{vendor.totalSpend}</td>
                                            <td>{vendor.category}</td>
                                            <td>{vendor.spendPercent}</td>
                                            <td>{vendor.exceptions}</td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            )
        }
        else if (modalContentType === 'VENDOR_SPEND_LOG') {
            return (
                <div className="card-body py-0 px-1">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-muted" scope="col">Vendor Name</th>
                                <th className="text-muted" scope="col">Total Spend</th>
                                <th className="text-muted" scope="col">Category</th>
                                <th className="text-muted" scope="col">Avg Monthly Spend</th>
                                <th className="text-muted" scope="col">Last Payment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companyProfileWidget && companyProfileWidget.vendorSpendLogs && companyProfileWidget.vendorSpendLogs.length ? companyProfileWidget.vendorSpendLogs.map((vendor, i) => {
                                    return (
                                        <tr key={`vendor-spends-details-${i}`}>
                                            <td scope="row">{vendor.vendorName}</td>
                                            <td>{vendor.totalSpend}</td>
                                            <td>{vendor.category}</td>
                                            <td>{vendor.avgMonthlySpend}</td>
                                            <td>{moment(vendor.lastPaymentDate).format("MMM DD, YYYY")}</td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            )
        }
        else if (modalContentType === 'SPEND_OVER_TIME'){
            return (
                <div className="card-body">
                    <TrendChartComponent id="vendorSpendTimeAI" height="15rem" chartInfo={companyProfileWidget.spendOverTimeChartData} />
                </div>
            )
        }
        else if (modalContentType === 'SPEND_BY_REGION'){
            return (
                <div className="card-body">
                    <SimpleTreeMapChart id="spendByRegionChartAI" data={companyProfileWidget.spendByRegion} height="20rem" />
                </div>
            )
        }
        else if (modalContentType === 'SPEND_BY_CATEGORY'){
            return (
                <div className="card-body">
                    <HorizontalBarChart id="spendByCategoryChartAI" data={companyProfileWidget.spendByCategory} hideCenterText={true} height="20rem" />
                </div>
            )
        }
        else if (modalContentType === 'DATA_COLLECTION'){
            return (
                <div className="card-body">
                    <ColoredHeatmapChart id="portcoCompanyScoreChartAI" data={companyProfileWidget.companyScores} yAxisData={companyProfileWidget.heatYAxisData} xAxisData={companyProfileWidget.heatXAxisData} categoryYField="company" categoryXField="period" yAxisVisibility={true} height={'32rem'} minColor={0xff0000} maxColor={0x00ff00} legendVisibility={false} />
                </div>
            )
        }
    }

    const getSuggestiveQuestionContent = (canShow, suggestion) => {
        let suggestiveQues = suggestion && suggestion.length ? suggestion : suggestiveQuestion;
        if (canShow) {
            return (
                <section>
                    <p className="text-muted">Questions you can ask</p>
                    <div className="card-deck bl-ai-card-deck">
                        {
                            suggestiveQues.map((ques, i) => {
                                let question = ques ? ques.title ? ques.title : ques : '';
                                return (
                                    <div key={`question-sugg-${i}`} className="card">
                                        <div className="card-body bl-body-hover p-2 d-flex" onClick={(e) => getQuestionInputByUser(e, question, 'SUGGESTIVE')}>
                                            <i className="fa fa-question-circle-o mr-2 text-gray align-self-center" aria-hidden="true"></i>
                                            <p className="mb-0 font-small cp align-self-center">{question}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            )
        }
    }

    const setAssistantAnswersView = (question, userQues) => {
        scrollToDown()
        setIsLoaded(false)
        let answers = assistantAnswer;
        let answerItem = AIAnswersJson.find(ans => ans.question === question);
        console.log("check now ===>", answerItem);

        if (userQues && answerItem) {
            answerItem.question = userQues;
        }
        answers.push(answerItem);
        setAssistantAnswer(answers);
        setUserQuestion('')
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)
    }

    const getMatchedWordsQuestionFromAIUser = (userQuestions) => {
        const searchWords = userQuestions.toLowerCase().split(' ');
        const ignoredWord = [
            "show", "me", "the", "list", "of"
        ]
        let matchedWords = [];
        for (const sentence of typedQuestionList) {
            let count = 0;
            if (searchWords.some(word => {
                if (!ignoredWord.includes(word)) {
                    if (sentence.toLowerCase().includes(word)) {
                        count++;
                    }
                }
                sentence.toLowerCase().includes(word)
            })) { }
            matchedWords.push({
                sentence, count
            })
        }
        let greatestNumber = 0;
        let matchedQuestion = "";
        for (let i = 0; i < matchedWords.length; i++) {
            if (matchedWords[i].count > greatestNumber) {
                greatestNumber = matchedWords[i].count;
                matchedQuestion = matchedWords[i].sentence
            }
        }
        return matchedQuestion;
    }

    const getAnswerForCompanyProfile = () => {
        scrollToDown()
        setIsLoaded(false)
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)
        let answers = assistantAnswer;
        let ans = companyProfile.map(c => {
            return {
                company: c.company,
                esgScore: c.ESGScore,
                slug: c.slug
            }
        });
        let answerMode = {
            question: userQuestion,
            assistant: "Sure, please find the list of companies with similar ESG score.",
            answer: [{ viewMode: 'ESG_TABLE', items: ans }]
        }
        answers.push(answerMode);
        setAssistantAnswer(answers);
        setUserQuestion('')
    }

    function findClosestQuestions(userInput, threshold = 0.6) {
        const questions = suggestiveQuestionJson.find(q => q.category === "FINANCIAL").questions;
        console.log("questions ===>", questions);

        // Normalize user input to ensure case-insensitive matching and trim spaces
        const normalizedInput = userInput.trim().toLowerCase();

        // Function to calculate similarity between two strings
        function similarity(a, b) {
            let matches = 0;
            const longer = a.length >= b.length ? a : b;
            const shorter = a.length < b.length ? a : b;

            for (let i = 0; i < shorter.length; i++) {
                if (longer[i] === shorter[i]) matches++;
            }

            return matches / longer.length;
        }

        // Find questions with similarity above the threshold
        const matchedQuestions = questions
            .map(question => {
                const normalizedQuestion = question.toLowerCase();
                return { question, score: similarity(normalizedInput, normalizedQuestion) };
            })
            .filter(match => match.score >= threshold) // Filter based on threshold
            .sort((a, b) => b.score - a.score); // Sort by highest similarity

        // Return the closest matching questions
        console.log("matched ===>", matchedQuestions);

        return matchedQuestions.map(match => match.question);
    }

    const getQuestionInputByUser = (e, question, type) => {
        e.preventDefault();
        if (aiModalType === 'FINANCIAL') {
            console.log("check here ===>", type, question);

            if (type === 'SUGGESTIVE') {
                askQuestionByUser(question)
            }
            else {
                const matchedQuestion = findClosestQuestions(question);
                console.log("check matched questions ===>", matchedQuestion);
                if (matchedQuestion && matchedQuestion.length) {
                    askQuestionByUser(matchedQuestion[0])
                }
                else {
                    userInputQuestionFromServer(question)
                }
            }
        }
        else {
            userInputQuestionFromServer(question)
        }

        // if (aiModalType === 'DEFAULT') {
        //     // userInputQuestionFromServer(question)
        // }
        // else {
        //     if (type === 'SUGGESTIVE') {
        //         askQuestionByUser(question)
        //     }
        //     else {
        //         enterQuestionByUser(question)
        //     }
        // }
    }

    const askQuestionByUser = (question) => {
        if (question === "Provide a detailed breakdown of the above ESG scores") {
            setIsQuestionAsked(true)
            setAssistantAnswersView(question)
        }
        else if (question === "Can you tell me about companies leading in AI research?") {
            setIsQuestionAsked(true)
            setAssistantAnswersView(question)
        }
        else {
            setIsQuestionAsked(true)
            setAssistantAnswersView(question)
        }
    }

    const enterQuestionByUser = (question) => {
        if (question) {
            setIsQuestionAsked(true)
            let matchedQuestion = getMatchedWordsQuestionFromAIUser(question);
            if (matchedQuestion === "List down comparable companies with similar ESG scores in United States") {
                getAnswerForCompanyProfile()
            }
            else {
                if (matchedQuestion) {
                    setAssistantAnswersView(matchedQuestion, question)
                }
                else {
                    userInputQuestionFromServer(question)
                }
            }
        }
    }

    const userInputQuestionFromServer = async (question, sourceType) => {
        setIsQuestionAsked(true)
        setIsLoaded(false)
        let answers = assistantAnswer;
        let answerMode = {
            question: question,
            assistant: "Please be patient while we are searching...",
            answer: [{ viewMode: 'SERVER_RESP', items: {} }]
        }
        answers.push(answerMode);
        setAssistantAnswer(answers);
        setUserQuestion('')
        if (question) {
            let resp = null;
            if (isCanabisApp) {
                if (uploadedFileOriginalName) {
                    resp = await cannabisQueryService(uploadedFileOriginalName, question)
                }
                else {
                    const obj = {
                        "query": question,
                        "sourceType": "reddit",
                        "domain": "cannabis"
                    }
                    resp = await canabisQuestionInputForServerService(obj)
                }
            }
            else {
                const sourceType = aiModalType === 'DEFAULT' ? isFileUploaded ? 'file' : 'application' : 'widget';
                let obj = {
                    query: question,
                    source_type: sourceType
                }
                if (sourceType === 'widget') {
                    obj.data = companyProfileWidget
                }
                resp = await questionInputForServerService(obj);
            }
            console.log("get response for canabis ===>", resp);
            setIsLoaded(true)
            if (resp && resp.success) {
                let allAnswers = assistantAnswer;
                let indx = allAnswers.findIndex(a => a.assistant === 'Please be patient while we are searching...');
                if (indx !== -1) {
                    allAnswers.splice(indx, 1)
                }
                let answerMode = {
                    question: question,
                    assistant: "Please find the results below.",
                    answer: [{ viewMode: 'SERVER_RESP', items: resp.data }]
                }
                allAnswers.push(answerMode);
                setAssistantAnswer(allAnswers);
            }
            else {
                let allAnswers = assistantAnswer;
                let message = "The inquiry you've made does not align with the current context. Kindly ask a different question that is contextually appropriate.";
                let indx = allAnswers.findIndex(a => a.assistant === 'Please be patient while we are searching...');
                if (indx !== -1) {
                    allAnswers[indx].assistant = message;
                }
                setAssistantAnswer([]);
                setTimeout(() => {
                    setAssistantAnswer(allAnswers);
                })
            }
        }
    }

    const serverSideContent = (items, indx) => {
        if (items) {
            if (items.type && items.type.toLowerCase() === 'table' || items.recommended_visualization && items.recommended_visualization.toLowerCase() === 'table') {
                let columnSet = [];
                let itemList = [];
                let cardTitle = "";
                if (items.data) {
                    if (items.data.data) {
                        items.data = items.data.data;
                    }
                    cardTitle = items.data.title ? items.data.title : '';
                    if (items.data.headers && items.data.values) {

                        let fElement = items.data.values[0];
                        for (let x = 0; x < fElement.length; x++) {
                            let h = items.data.headers[x].replace(/\s/g, '');
                            columnSet.push({
                                header: items.data.headers[x],
                                field: h
                            })
                        }
                        for (let i = 0; i < items.data.values.length; i++) {
                            let jsObj = {};
                            for (let j = 0; j < items.data.values[i].length; j++) {
                                let h = items.data.headers[j].replace(/\s/g, '');
                                jsObj[h] = items.data.values[i][j];
                            }

                            itemList.push(jsObj)
                        }
                    }
                    else {
                        let fElement = items.data[0];
                        itemList = items.data;
                        for (let key in fElement) {
                            columnSet.push({
                                header: convertCamelCaseToSeparateWords(key),
                                field: key
                            })
                        }
                    }
                }


                return (
                    <div key={`list-view-entry-${indx.toString()}`}>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between px-2 py-1">
                                <h6 className="mb-0 align-self-center">{cardTitle}</h6>
                                <div>
                                    <button className="btn btn-link p-0 openAIModal" id={`aicardtableconi${indx}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby={`aicardtableconi${indx}`}>
                                        <button className="dropdown-item bl_H1_Color pl-3">
                                            <i className="fa fa-print fa-sm mr-2"></i>
                                            Print
                                        </button>
                                        <button className="dropdown-item bl_H1_Color pl-3">
                                            <i className="fa fa-download fa-sm mr-2"></i>
                                            Download
                                        </button>
                                        <button className="dropdown-item bl_H1_Color pl-3">
                                            <i className="fa fa-share fa-sm mr-2"></i>
                                            Share
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <AIListViewAnswerComponent list={itemList} columnSet={columnSet}></AIListViewAnswerComponent>
                            </div>
                        </div>
                        {
                            items.sql_query ? <div className="mt-3">
                                {
                                    queryFormat(items.sql_query)
                                }
                            </div> : null
                        }
                        {
                            items.source_data ? <div className="mt-3">
                                {
                                    redditCommentsAndPostsContent(items.source_data)
                                }
                            </div> : null
                        }
                        <div className="mt-3">
                            {rateResponseContent()}
                        </div>
                    </div>
                )
            }
            else if (items.type === 'text') {
                return (
                    <div key={`text-view-entry-${indx.toString()}`}>
                        <p>{items.data}</p>
                        {
                            items.sql_query ? <div className="mt-3">
                                {
                                    queryFormat(items.sql_query)
                                }
                            </div> : null
                        }
                        {
                            items.source_data ? <div className="mt-3">
                                {
                                    redditCommentsAndPostsContent(items.source_data)
                                }
                            </div> : null
                        }
                        <div className="mt-3">
                            {rateResponseContent()}
                        </div>
                    </div>
                )
            }

        }

    }

    const searchInHistory = (e, text) => {
        e.preventDefault();
    }

    const getCardTableContent = (items, indx) => {
        return (
            <section key={`ai-card-table-${indx}`}>
                {
                    items.map((item, i) => {
                        return (
                            <div key={`ai-cont-card-${i}`} className="card mb-3">
                                <div className="card-header d-flex justify-content-between">
                                    <h6 className="mb-0 align-self-center">{item.cardHeader}</h6>
                                    <div>
                                        <button className="btn btn-link p-0 openAIModal" id={`aicardtablecon${indx}${i}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby={`aicardtablecon${indx}${i}`}>
                                            <button className="dropdown-item bl_H1_Color pl-3">
                                                <i className="fa fa-print fa-sm mr-2"></i>
                                                Print
                                            </button>
                                            <button className="dropdown-item bl_H1_Color pl-3">
                                                <i className="fa fa-download fa-sm mr-2"></i>
                                                Download
                                            </button>
                                            <button className="dropdown-item bl_H1_Color pl-3">
                                                <i className="fa fa-share fa-sm mr-2"></i>
                                                Share
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <AImodalTableView list={item.list} tableFor={item.categoryFor}></AImodalTableView>
                                </div>
                            </div>
                        )
                    })
                }
            </section>
        )
    }

    const getTableViewContent = (items, indx) => {
        return (
            <section key={`ai-card-table-${indx}`}>
                <div className="card mb-3">
                    <div className="card-header d-flex justify-content-between">
                        <h6 className="mb-0 align-self-center">{'Company List'}</h6>
                        <div>
                            <button className="btn btn-link p-0 openAIModal" id="aicardtableviewcon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="aicardtableviewcon">
                                <button className="dropdown-item bl_H1_Color pl-3">
                                    <i className="fa fa-print fa-sm mr-2"></i>
                                    Print
                                </button>
                                <button className="dropdown-item bl_H1_Color pl-3">
                                    <i className="fa fa-download fa-sm mr-2"></i>
                                    Download
                                </button>
                                <button className="dropdown-item bl_H1_Color pl-3">
                                    <i className="fa fa-share fa-sm mr-2"></i>
                                    Share
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <AImodalTableView list={items} tableFor={''} TableContent="ESG_TABLE"></AImodalTableView>
                    </div>
                </div>
            </section>
        )
    }

    const getInsightsContent = (item, indx) => {
        return (
            <section key={`insights-${indx}`} className="mb-3">
                <div className="bl-ai-insight p-2 d-flex">
                    <img className="img-fluid mr-3" alt="insight" src={insightLightIcon} />
                    <div className="align-self-center">
                        <p className="mb-0 text-muted font-smaller">{item.title}</p>
                        <p className="mb-0 font-small">{item.description} <span className="font-smaller text-muted">{item.action}</span></p>
                    </div>
                </div>
            </section>
        )
    }

    const getReportContent = (items, indx) => {
        return (
            <section key={`ai-rep-${indx}`} className="mb-3">
                <p className="font-small text-muted">Related information on the topic:</p>
                <h6>Reports ({items.length})</h6>
                <div className="card-deck">
                    {
                        items.map((item, i) => {
                            return (
                                <div key={`ai-report-view-${i}`} className="card">
                                    <div className="card-body d-flex bg-gray justify-content-between cp" title={item.type} onClick={() => redirectToModule(item.redirection)}>
                                        <div className="d-flex">
                                            <img className="img-fluid mr-3 bl-h-2rem align-self-center" alt="rep-ic" src={reportIcon} />
                                            <div className="align-self-center">
                                                <p className="mb-0 font-small bl-text-underline-hover">{item.title}</p>
                                                <p className="mb-0 text-muted font-small">{item.date}</p>
                                            </div>
                                        </div>
                                        <i className={`fa ${item.icon} font-small text-gray`}></i>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
        )
    }

    const getTaskContent = (items, indx) => {
        return (
            <section key={`ai-task-${indx}`}>
                <h6>Task ({items.length})</h6>
                <div className="card-deck">
                    {
                        items.map((item, i) => {
                            return (
                                <div key={`ai-report-view-${i}`} className="card">
                                    <div className="card-body d-flex bg-gray justify-content-between" title={item.type}>
                                        <div className="d-flex">
                                            <img className="img-fluid mr-3 bl-h-2rem align-self-center" alt="rep-ic" src={taskIcon} />
                                            <div className="align-self-center">
                                                <p className="mb-0 font-small">{item.title}</p>
                                                <p className="mb-0 text-muted font-small">{item.date}</p>
                                            </div>
                                        </div>
                                        <i className={`fa ${item.icon} font-small text-gray`}></i>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
        )
    }

    const getListViewContent = (items, indx) => {
        let columnSet = [];
        let fElement = items[0];
        for (let key in fElement) {
            columnSet.push({
                header: convertCamelCaseToSeparateWords(key),
                field: key
            })
        }
        return (
            <AIListViewAnswerComponent key={`list-view-${indx.toString()}`} list={items} columnSet={columnSet}></AIListViewAnswerComponent>
        )
    }

    const getTableCardModeViewContent = (items, header, index) => {
        if (items && items.length) {
            const tableData = items;
            let tableHeader = [];
            if (tableData.length) {
                for (let key in tableData[0]) {
                    const headerText = convertCamelCaseToSeparateWords(key);
                    tableHeader.push({
                        header: headerText,
                        field: key
                    })
                }
            }
            return (
                <div key={`card-table-mode-${index}`} className="card">
                    <div className="card-header px-2">
                        <h6>{header}</h6>
                    </div>
                    <div className="card-body p-0">
                        <DataTable value={tableData} className="bl-finance-graph">
                            {
                                tableHeader.map((col, i) => {
                                    const uniqueVal = Date.now() + Math.random();
                                    return (
                                        <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field}></Column>
                                    )
                                })
                            }
                        </DataTable>
                    </div>
                </div>
            )
        }

    }

    const getListModeViewContent = (items, index) => {
        return (
            <div key={`list-mode-${index}`} className="list-group">
                <ul>
                    {
                        items.map((item, i) => {
                            return (
                                <li key={`list-mode-item-${index}-${i}`}><b>{item.title}:</b> {item.description}</li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }

    const getTextViewContent = (items, index) => {
        return (
            items.map((item, i) => {
                return <p key={`text-view-cont-${index}-${i}`}>{item}</p>
            })
        )
    }

    const convertCamelCaseToSeparateWords = (camelCaseWord) => {
        const separateWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2');
        let words = separateWords.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return words.replace(/_/g, ' ')
    }

    const getNewsActivityViewContent = (items, indx) => {
        return (
            items.map((item, i) => {
                return (
                    <div key={`news-view-${indx}-${i}`} className={`bl-activity py-2`}>
                        <img className="img-fluid mt-1 bl-w-5rem bl-h-4rem" src={item.imgUrl} alt="news logo" />
                        <div>
                            <button className="btn btn-link bl-desc-truncate-line-2 p-0 text-left">{item.newsTitle}</button>
                            <p className="text-muted font-small mb-0">{item.description}</p>
                            <p className="text-muted font-small mb-0"><small>{item.publishedDate} | {item.source}</small></p>
                        </div>
                    </div>
                )
            })
        )
    }

    const assistantAnswerContent = () => {
        scrollToDown()
        return (
            <section>

                {
                    assistantAnswer.map((ans, i) => {
                        return (
                            <div key={`answer-card-view-${i}`} className="card shadow-lg mb-3 bl-answer-mode">
                                <div className="card-header border-0 d-flex px-2">
                                    <img className="img-fluid rounded-circle bl-h-2rem mr-2" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                                    <p className="mb-0 align-self-center">{ans && ans.question ? ans.question : ''}
                                        {ans && ans.question ? (<i className="fa fa-pencil-square-o ml-2 text-gray cp" aria-hidden="true"></i>) : null}
                                    </p>
                                </div>
                                {
                                    <div key={`assis-ans-${i}`} className="card-header d-flex justify-content-between px-2 bl-assistant-sec mb-3">
                                        <div className="d-flex">
                                            <img className="img-fluid bl-h-2rem mr-2" src={ask_question} />
                                            <p className="mb-0 align-self-center">{ans.assistant}
                                                {ans && ans.isFileUpload ? (<i className="fa fa-pencil-square-o ml-2 text-gray cp" aria-hidden="true"></i>) : null}
                                            </p>
                                        </div>
                                        <div className="px-2 align-self-center">
                                            <button className="btn btn-link p-0 openAIModal" id={`datasourcedropdown${i}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-info-circle text-gray" aria-hidden="true"></i>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby={`datasourcedropdown${i}`}>
                                                <h6 className="dropdown-header font-smaller pl-3">Sources</h6>
                                                {
                                                    selectedSource.length ? (
                                                        <button className="dropdown-item bl_H1_Color pl-3 font-smaller">
                                                            {selectedSource[0].file_name}
                                                        </button>
                                                    ) : (
                                                        <button className="dropdown-item bl_H1_Color pl-3 font-smaller">
                                                            No Source selected!
                                                        </button>
                                                    )
                                                }
                                            </div>
                                            {/* <i className="fa fa-info-circle cp" aria-hidden="true" id="datasourcedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="datasourcedropdown">
                                                <h6 className="dropdown-header">Selected Data Sources</h6>
                                                <li className="dropdown-item list-group-item d-flex justify-content-between align-items-center border-0">
                                                    <input type="checkbox" className="custom-checkbox small mr-3" defaultChecked />
                                                    <span className="bl-limited-text"> <a >231213 - Anonymized Vista...xls</a></span>
                                                </li>
                                                <div className="dropdown-divider"></div>
                                                <div className="dropdown-item">
                                                    <form>
                                                        <div className="input-group border-bottom">
                                                            <input type="text" className="form-control h-auto border-0 shadow-none" placeholder="Search More to Add" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text bg-white border-0">
                                                                    <i type="submit" className="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <li className="dropdown-item list-group-item d-flex justify-content-between align-items-center border-0">
                                                    <input type="checkbox" className="custom-checkbox small mr-3" />
                                                    <span className="bl-limited-text"> <a >loaporeum.xls</a></span>
                                                </li>
                                                <li className="dropdown-item list-group-item d-flex justify-content-between align-items-center border-0">
                                                    <input type="checkbox" className="custom-checkbox small mr-3" />
                                                    <span className="bl-limited-text"> <a >jan.xls</a></span>
                                                </li>
                                                <li className="dropdown-item list-group-item d-flex justify-content-between align-items-center border-0">
                                                    <input type="checkbox" className="custom-checkbox small mr-3" />
                                                    <span className="bl-limited-text"> <a >newupload.xls</a></span>
                                                </li>
                                            </div> */}
                                        </div>
                                    </div>
                                }
                                <div key={`ans-list-view-${i}`} className="card-body mx-4 pt-0">
                                    {
                                        assistantAnswer.length === i + 1 && !isLoaded ? <span className="loader"></span> :
                                            ans.answer.map((qa, j) => {
                                                return (
                                                    qa.viewMode === "card_table" ? getCardTableContent(qa.items, j)
                                                        : qa.viewMode === 'insights' ? getInsightsContent(qa.items, j)
                                                            : qa.viewMode === 'report' ? getReportContent(qa.items, j)
                                                                : qa.viewMode === 'task' ? getTaskContent(qa.items, j)
                                                                    : qa.viewMode === 'ESG_TABLE' ? getTableViewContent(qa.items, j)
                                                                        : qa.viewMode === 'list' ? getListViewContent(qa.items, j)
                                                                            : qa.viewMode === 'CARD_TABLE_MODE' ? getTableCardModeViewContent(qa.items, qa.cardHeader, j)
                                                                                : qa.viewMode === 'listMode' ? getListModeViewContent(qa.items, j)
                                                                                    : qa.viewMode === 'text' ? getTextViewContent(qa.items, j)
                                                                                        : qa.viewMode === 'NEWS_TILE' ? getNewsActivityViewContent(qa.items, j)
                                                                                            : qa.viewMode === 'FILE_UPLOAD' ? uploadedFileContent(qa.items.files, qa.items.previewItems, qa.items.suggestion, j)
                                                                                                : qa.viewMode === 'SERVER_RESP' ? serverSideContent(qa.items, j) : null
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </section>
        )
    }

    const queryFormat = (query) => {
        return (
            <div className="">
                <div className="col-12 bl-ai-insight p-2">
                    <h6 className="font-small">Executed Query:</h6>
                    <p className="mb-0 text-muted font-smaller">{query}</p>
                </div>
            </div>
        )
    }

    const redditCommentsAndPostsContent = (items) => {
        const posts = items.slice(0, 4);
        return (
            <div className="row">
                <h6 className="font-small">Corresponding Reddit Threads:</h6>
                {
                    posts.map((post, i) => {
                        return (
                            <div key={`reddit-thread-${i}`} className="col-12 bl-ai-insight px-2 mb-2 feed">
                                <div className="post-item border-0 pb-2">
                                    <span><i className="fa fa-reddit" aria-hidden="true"></i></span>
                                    <p>
                                        <a className="text-muted font-small" href={post.url} target="_blank">
                                            {post.type === 'reddit-post' ? post.postTitle : post.commentTitle}
                                        </a>
                                    </p>
                                    <p className="small">{post.created ? moment(post.created).format('MMM DD, YYYY') : ''}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const uploadedFileContent = (files, previewItems, suggestion, indx) => {
        return (
            <section key={`ans-cont-${indx}`}>
                <div className="mb-4">
                    {
                        files.map((item, i) => {
                            return (
                                <div key={`ai-file-view-${i}`} className="col-6 card p-0">
                                    <div className="card-body d-flex bg-gray justify-content-between cp p-2" title={item.type}>
                                        <div className="d-flex">
                                            <img className="img-fluid mr-3 bl-h-2rem align-self-center" alt="rep-ic" src="https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png" />
                                            <div className="align-self-center mr-3">
                                                <p className="mb-0 font-small bl-text-underline-hover">{item.title}</p>
                                                <p className="mb-0 text-muted font-smaller">{item.date}</p>
                                            </div>
                                        </div>
                                        <i className={`fa ${item.icon} font-small text-gray`}></i>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className="mb-2">
                    {
                        previewItems && previewItems.length ? <h6 className="mt-2 font-small">File Preview: </h6> : null
                    }

                    {
                        previewItems && previewItems.map((item, i) => {
                            return (
                                <div key={`preview-items-${i}`} className="col-12 card mb-3 p-0">
                                    <div className="card-header d-flex justify-content-between px-2 py-1">
                                        <h6 className="mb-0 align-self-center font-small">{item.title}</h6>
                                        <div>
                                            <button className="btn btn-link p-0 openAIModal" id={`aicardtablecony${indx}${i}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby={`aicardtablecony${indx}${i}`}>
                                                <button className="dropdown-item bl_H1_Color pl-3">
                                                    <i className="fa fa-print fa-sm mr-2"></i>
                                                    Print
                                                </button>
                                                <button className="dropdown-item bl_H1_Color pl-3">
                                                    <i className="fa fa-download fa-sm mr-2"></i>
                                                    Download
                                                </button>
                                                <button className="dropdown-item bl_H1_Color pl-3">
                                                    <i className="fa fa-share fa-sm mr-2"></i>
                                                    Share
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            getListViewContent(item.data, i)
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="">
                    {
                        suggestion && suggestion.length ? getSuggestiveQuestionContent(true, suggestion) : null
                    }
                </div>
                <div>
                    {rateResponseContent()}
                </div>
            </section>
        )
    }

    const fileInputRef = useRef(null);

    const triggerFileInput = () => {
        // Trigger the click event on the file input
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        setIsQuestionAsked(false)
        setIsLoaded(false)
        setIsFileUploaded(false)
        let answers = assistantAnswer;
        let answerMode = {
            question: '',
            assistant: "File upload in progress, please wait...",
            isFileUpload: false,
            answer: [{ viewMode: 'FILE_UPLOAD', items: { files: [], previewItems: [] } }]
        }
        answers.push(answerMode);
        setAssistantAnswer(answers);
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.name.endsWith('.xlsx') || selectedFile.name.endsWith('.xls')) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                setIsQuestionAsked(true)
                setIsLoaded(false)
                let resp = null;
                if (isCanabisApp) {
                    resp = await cannabisFileUploadService(formData);
                }
                else {
                    resp = await fileUploadService(formData);
                }
                setIsLoaded(true)
                if (resp && resp.success) {
                    if (isCanabisApp && resp.data.originalFileName) {
                        setUploadedFileOriginalName(resp.data.originalFileName)
                    }
                    getUploadedFiles()
                    setViewType('DATA_SOURCES')
                    setIsFileUploaded(true)
                    const files = [
                        {
                            "title": selectedFile.name,
                            "date": "uploaded just now",
                            "icon": "fa-shield",
                            "type": "Private"
                        }
                    ];
                    let answers = assistantAnswer.slice(0, -1);
                    let answerMode = {
                        question: '',
                        assistant: `Files uploaded - ${selectedFile.name}`,
                        isFileUpload: true,
                        answer: [{
                            viewMode: 'FILE_UPLOAD',
                            items: {
                                files,
                                previewItems: resp.data.preview ? resp.data.preview : [],
                                suggestion: resp.data.suggestion ? resp.data.suggestion : []
                            }
                        }]
                    }
                    answers.push(answerMode);
                    setAssistantAnswer(answers);
                    fileInputRef.current.value = '';
                }
                else {
                    let answers = assistantAnswer.slice(0, -1);
                    // setAssistantAnswer(answers);
                    fileInputRef.current.value = '';
                    let message = "Invalid file, please upload again!";
                    let answerMode = {
                        question: "",
                        assistant: message,
                        isFileUpload: false,
                        answer: [{ viewMode: 'SERVER_RESP', items: [] }]
                    }
                    answers.push(answerMode);
                    setAssistantAnswer(answers);
                }
            } else {
                setIsLoaded(true)
                setIsQuestionAsked(true)
                let answers = assistantAnswer.slice(0, -1);
                setAssistantAnswer(answers);
                fileInputRef.current.value = '';
                alert('Please select a valid Excel file.');
            }
        }
    };

    const conversationsSideViewContent = () => {
        return (
            <>
                {/* <!-- Divider --> */}
                {/* <hr className="sidebar-divider mt-0" /> */}
                {/* <!-- Heading --> */}
                <div className="sidebar-heading mt-3">
                    Today
                </div>
                {/* <!-- Nav Item - Pages Collapse Menu --> */}
                <li className="nav-item active d-flex justify-content-between">
                    <a className="nav-link py-2 w-100 bl-desc-truncate-line-1">
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Current financial health of our...</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
                {/* <!-- Nav Item - Utilities Collapse Menu --> */}
                <li className="nav-item d-flex justify-content-between">
                    <a className="nav-link py-2 bl-desc-truncate-line-1">
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Highest revenue growth over the...</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
                <li className="nav-item d-flex justify-content-between">
                    <a className="nav-link py-2 bl-desc-truncate-line-1">
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Breakdown of the cash flow trends</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
                <li className="nav-item d-flex justify-content-between">
                    <a className="nav-link py-2 bl-desc-truncate-line-1">
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Recommended funding strategies</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
                {/* <!-- Divider --> */}
                {/* <!-- <hr className="sidebar-divider mt-3 mb-4"> --> */}
                {/* <!-- Heading --> */}
                <div className="sidebar-heading mt-3">
                    Last week
                </div>
                {/* <!-- Nav Item - Pages Collapse Menu --> */}
                <li className="nav-item d-flex justify-content-between">
                    <a className="nav-link py-2 bl-desc-truncate-line-1" >
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Current financial health of our to...</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
                {/* <!-- Nav Item - Utilities Collapse Menu --> */}
                <li className="nav-item d-flex justify-content-between mb-4">
                    <a className="nav-link py-2 bl-desc-truncate-line-1" >
                        <i className="fa fa-fw fa-comment"></i>
                        <span>Highest revenue growth over th...</span>
                    </a>
                    <i className="fa fa-trash mr-3 align-self-center cp"></i>
                </li>
            </>
        )
    }

    const dataSourceElementSelection = (e, i, j) => {
        e.preventDefault();
        let dataSourceItems = [...dataSourceMenu];
        if (dataSourceItems[i].type === 'FILE' && isCanabisApp) {
            const isSelectedItem = dataSourceItems[i].items[j].isSelected;
            let fileItems = dataSourceItems[i].items.map(item => {
                item.isSelected = false;
                return item;
            })
            dataSourceItems[i].items = fileItems;
            dataSourceItems[i].items[j].isSelected = !isSelectedItem;
            if (!isSelectedItem) {
                let item = dataSourceItems[i].items.find(item => item.isSelected);
                if (item) {
                    setUploadedFileOriginalName(item.originalFileName)
                }
            }
            else {
                setUploadedFileOriginalName('')
            }
        }
        else dataSourceItems[i].items[j].isSelected = !dataSourceItems[i].items[j].isSelected;

        setTimeout(() => {
            setDataSourceMenu(dataSourceItems)
        }, 10)
    }

    const dataSourceContentView = () => {
        return (
            dataSourceMenu && dataSourceMenu.map((menu, i) => {
                return (
                    <React.Fragment key={`data-source-element-${i}`}>
                        {
                            menu.items.length ? (
                                <div className="sidebar-heading mt-3">
                                    {menu.name}
                                </div>
                            ) : null
                        }
                        {
                            menu.items.map((item, j) => {
                                return (
                                    <li key={`data-source-list-${i}${j}`} className="nav-item d-flex justify-content-between">
                                        {
                                            item.file_url ? (
                                                <a href={item.file_url} target="_blank" className="nav-link py-2 w-100 bl-limited-text">
                                                    <img src={item.file_image} height="18px" />
                                                    <span className="ml-2">{item.file_name}</span>
                                                </a>
                                            ) : (
                                                <a className="nav-link py-2 w-100 bl-limited-text">
                                                    <img src={item.file_image} height="18px" />
                                                    <span className="ml-2">{item.file_name}</span>
                                                </a>
                                            )
                                        }
                                        {
                                            menu.type === "DATABASE" ? (
                                                <div className="align-self-center mr-3">
                                                    <label className="bl-switch mb-0">
                                                        <input type="checkbox" onChange={(e) => dataSourceElementSelection(e, i, j)} checked={item.isSelected} />
                                                        <span className="bl-slider round"></span>
                                                    </label>
                                                </div>
                                            ) : (<input type="checkbox" onChange={(e) => dataSourceElementSelection(e, i, j)} className="custom-checkbox small mr-3" checked={item.isSelected} />)
                                        }

                                    </li>
                                )
                            })
                        }
                    </React.Fragment>
                )
            })
        )
        return (
            <>
                <div className="sidebar-heading mt-3">
                    Files
                </div>
                <ul className="list-group list-group-flush border-0">
                    {
                        uploadedFiles && uploadedFiles.map((file, i) => {
                            return (
                                <li key={`uploaded-files-${i}`} className="list-group-item d-flex justify-content-between align-items-center border-0">
                                    <span className="bl-limited-text"><img src="https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png" height="18px" /> <a target="_blank" href={file.file_url}>{file.file_name}</a></span>
                                    <input type="checkbox" className="custom-checkbox small" />
                                </li>
                            )
                        })
                    }

                    {/* <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png" height="18px" /> <a >234 - VistaProfileExtract.xls</a></span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png" height="18px" /> <a >234 - VistaProfileExtract.xls</a></span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li> */}
                </ul>

                <br />

                <div className="sidebar-heading">
                    Reports
                </div>
                <ul className="list-group list-group-flush border-0">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"><img src="https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png" height="18px" /> <a >Sales Dashboard</a></span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png" height="18px" /> <a >Performance Model</a></span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://i0.wp.com/indiciatraining.com/wp-content/uploads/2019/10/power-bi_logo_transparent.png" height="18px" /> <a >Valuation Model</a></span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                </ul>

                <br />

                <div className="sidebar-heading">
                    News & Social
                </div>
                <ul className="list-group list-group-flush border-0">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"><img src="https://cdn.iconscout.com/icon/free/png-256/free-google-news-9132533-7417224.png" height="18px" /> Google News</span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://static-00.iconduck.com/assets.00/reddit-icon-256x256-60kyb8pn.png" height="18px" /> Reddit</span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://cdn-1.webcatalog.io/catalog/reuters/reuters-icon-filled-256.png?v=1675613809018" height="18px" /> Reddit</span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://cdn-icons-png.flaticon.com/256/174/174857.png" height="18px" /> LinkedIn</span>
                        <input type="checkbox" className="custom-checkbox small" />
                    </li>
                </ul>

                <br />

                <div className="sidebar-heading">
                    Databases
                </div>
                <ul className="list-group list-group-flush border-0 mb-4">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"><img src="https://styles.redditmedia.com/t5_318fi/styles/communityIcon_0kgtnqe3shi61.png" height="18px" /> Snowflake</span>
                        <div className="align-self-center">
                            <label className="bl-switch mb-0">
                                <input type="checkbox" defaultChecked={true} />
                                <span className="bl-slider round"></span>
                            </label>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                        <span className="bl-limited-text"> <img src="https://cdn.iconscout.com/icon/free/png-256/free-sql-4-190807.png" height="18px" /> SQL Server</span>
                        <div className="align-self-center">
                            <label className="bl-switch mb-0">
                                <input type="checkbox" defaultChecked={true} />
                                <span className="bl-slider round"></span>
                            </label>
                        </div>
                    </li>
                </ul>
            </>
        )
    }

    const rateResponseContent = () => {
        return (
            <div className="col-12 text-center">
                <p className="mb-0">Is this conversation helpful so far?
                    <span className="bl-like-dislike-icon cp"><i className="fa fa-thumbs-o-up" aria-hidden="true"></i></span>
                    <span className="bl-like-dislike-icon cp"><i className="fa fa-thumbs-o-down" aria-hidden="true"></i></span>
                </p>
            </div>
        )
    }


    return (
        <div className="modal fade aiModal p-0 overflow-hidden" id="aimodalbox" data-backdrop={false} tabIndex="-1" role="dialog" aria-labelledby="aimodalbox" aria-hidden="true">
            <div className="modal-dialog mw-100 m-0" role="document">
                <div id="preaimodalcontent" className="modal-content bl-modal-context min-vh-100">
                    {
                        showMaximizeIcon ? <div className="modal-header p-2">
                            <h6 className="mb-0 ml-2">
                                <img className="img-fluid bl-h-2rem mr-2" src={ask_question} />
                                AI Assistant
                            </h6>
                        </div> : null
                    }
                    <div className="bl-modal-action">
                        {
                            showMinimizeIcon ? <i className="fa fa-minus fa-fw mr-2 cp" onClick={() => modalMinimize()}></i> : null
                        }
                        {
                            showMaximizeIcon ? <i className="fa fa-window-maximize fa-fw mr-2 cp modalMinimize" onClick={() => modalMaximize()}></i> : null
                        }
                        <i className="fa fa-times fa-fw cp" onClick={() => modalClose()} data-dismiss="modal" aria-label="Close"></i>
                    </div>
                    <div id="aimodalbody" className="modal-body p-0">

                        <div className="modal-wrapper d-flex">
                            <div className="modal-sidebar">
                                <ul className="navbar-nav bg-white sidebar sidebar-light shadow-lg bl-ai-sidebar" id="accordionSidebar" >
                                    <div className="d-flex justify-content-between p-3">
                                        <div className="btn-group bl-w-fill-available mr-4" role="group" aria-label="Basic example">
                                            <button className={`btn btn-sm border ${viewType === 'CONVERSATIONS' ? 'btn-primary active' : 'btn-outline-primary'}`} onClick={() => setViewType('CONVERSATIONS')}>Conversations</button>
                                            <button className={`btn btn-sm border ${viewType === 'DATA_SOURCES' ? 'btn-primary active' : 'btn-outline-primary'}`} onClick={() => setViewType('DATA_SOURCES')}>Data Sources</button>
                                        </div>
                                        <button className="btn btn-danger btn-sm" onClick={() => openNewChat()}><i className="fa fa-fw fa-plus" aria-hidden="true"></i></button>
                                    </div>
                                    <div className="px-3 pt-0">
                                        <form onSubmit={(e) => searchInHistory(e, userQuestion)}>
                                            <div className="input-group border-bottom">
                                                <input type="text" className="form-control h-auto border-0 shadow-none px-0" placeholder={viewType === 'CONVERSATIONS' ? 'Search Conversations' : 'Search Docs'} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text bg-white border-0">
                                                        <i type="submit" className="fa fa-search" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {
                                        viewType === 'CONVERSATIONS' ? conversationsSideViewContent() : dataSourceContentView()
                                    }
                                </ul>
                            </div>
                            <div className="modal-content-wrapper w-100 min-vh-100 ">
                                <div id="aimodalcontent" className="row justify-content-center vh-100 overflow-auto bl-pt-20">
                                    <div className="col-9 d-flex flex-column-reverse">
                                        <div className="bl-card-visibility mb-4">
                                            {
                                                modalContentType === 'DEFAULT' || modalContentType === 'OPEN_DEFAULT' ? defaultModalContentView() :
                                                    <div className="card shadow-lg mb-3">
                                                        <div className="card-header border-0 d-flex px-2">
                                                            <img className="img-fluid bl-h-2rem mr-2" src={ask_question} />
                                                            <p className="mb-0 align-self-center">Selected Context <b>- {defaultCardHeader}</b></p>
                                                        </div>
                                                        <div className="card-body ml-4 pt-0">
                                                            <section className="mb-3">
                                                                <div className={`card mb-3 ${modalContentType !== 'KEY_PEOPLE' ? 'bl-mw-30rems' : ''}`}>
                                                                    {modalContentViewMode()}
                                                                </div>
                                                            </section>
                                                            {
                                                                getSuggestiveQuestionContent(showSuggestion)
                                                            }

                                                        </div>
                                                    </div>
                                            }

                                            {
                                                isQuestionAsked ? assistantAnswerContent() : null
                                            }
                                            <div className="bl-search-footer">
                                                {
                                                    selectedSource.length ? (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p className="d-flex mb-2 p-2 bl-selected-source text-nowrap overflow-auto">Selected Sources (<b>{selectedSource.length}</b>):
                                                                    {
                                                                        selectedSource.map((s, i) => {
                                                                            return (
                                                                                <span key={`selected-source-${i}`} className="badge bl-filter-chip bl-bg-lightpink bl-limited-text d-block px-2 ml-2">{s.file_name}</span>
                                                                            )
                                                                        })
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                <form className="shadow-lg" onSubmit={(e) => getQuestionInputByUser(e, userQuestion, 'TEXT')}>
                                                    {/* <form onSubmit={(e) => userInputQuestionFromServer(e, userQuestion)}> */}
                                                    <div className="input-group border rounded">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text bg-white border-0 p-2">
                                                                <img className="img-fluid bl-h-2rem" src={ask_question} />
                                                            </span>
                                                        </div>
                                                        <input type="text" onChange={(e) => setUserQuestion(e.target.value)} value={userQuestion} className="form-control h-auto border-0 shadow-none" placeholder="Ask me something about your data" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-0">
                                                                <input type="file" ref={fileInputRef} onChange={handleFileChange} className="d-none" accept=".xlsx, .xls" />
                                                                <i className="fa fa-cloud-upload cp" aria-hidden="true" onClick={triggerFileInput}></i>
                                                            </span>
                                                        </div>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-0">
                                                                <i type="submit" className="fa fa-share bl-rotate-175" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}