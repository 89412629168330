import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import moment from "moment";
import missingImg from "./../../assets/img/logoplaceholder.png";
import { BoldPieChart } from "../Graph/boldPieChart";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { getCompanyListService } from "../../services/companyService";


export const PortcoMonitoringDashboardComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setCompanyList([])
        getCompanyList(1)
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
                { slug: '/portco/dashboard', menuName: 'Dashboard', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: "",
        filters: []
    });
    const [companyList, setCompanyList] = useState([])
    const [filter, setFilter] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [portcoKPI, setPortcoKPI] = useState([])

    useEffect(() => {
        const items = homeLabel.find(l => l.FEATURE === "PORTCO").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        if (labels && labels.ENTITY && labels.ENTITY.length) {
            setPortcoKPI(labels.ENTITY)
        }
        if (labels && labels.filters) {
            setFilter(labels.filters)
        }
    }, [labels])

    const getCompanyList = async (page) => {
        const limit = 15;
        if (page === 1) {
            setIsLoaded(false)
        }
        const resp = await getCompanyListService(page, limit, 'company');
        setIsLoaded(true)
        getCompanyListResponse(page, resp, limit)
    }

    const getCompanyListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setCompanyList(companies => [...companies, ...newCompanyList])
        }
    }

    function containsProtocolOrWWW(url) {
        const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
        return pattern.test(url);
    }

    const getSourceCompanyName = (data) => {
        if (data.company && !containsProtocolOrWWW(data.company)) {
            return data.company;
        }
        else {
            return data.external_data && data.external_data.companyName ? data.external_data.companyName : data.company ? data.company : ''
        }
    }

    function concatenateWithCommas(street, locality, country) {
        let address = [];
        if (street) {
            address.push(street)
        }
        if (locality) {
            address.push(locality)
        }
        if (country) {
            address.push(country)
        }
        const resultString = address.join(', ');
        return resultString;
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
    }

    const arrangeColumnValue = (item, column) => {
        const data = item;
        const compDate = data.updated_at ? data.updated_at : moment();
        const diffInHours = moment().diff(moment(compDate), 'hours', true)
        const isGeneratingCompany = Math.abs(diffInHours) < 1 ? item.reachedMaxLimit ? false : (item.source === 'company' && !item.linkedinCompanyId && !item.external_data) : false;
        const collectingExternalData = Math.abs(diffInHours) < 1 ? item.reachedMaxLimit ? false : (item.source === 'company' && !item.external_data) : false;
        if (column === 'ICON') {
            const companyLogo = data.logo ? data.logo : missingImg;
            return (
                <div className="d-flex align-items-center">
                    {
                        isGeneratingCompany ? <span className="spin-loader"></span> : <img src={companyLogo} alt="logo" onError={handleImageError} className="img-fluid bl-report-thumbnail" />
                    }
                </div>
            )
        }
        else if (column === 'COMPANY') {
            const contryFlag = data.country ? (data.country).toLowerCase() : 'us';
            const address = data.address ? data.address.replace(/undefined,?/g, '').trim() : null;
            const isDealProject = data.source && data.source.toLowerCase() === "project";
            const dealOverview = data.projectInfo && data.projectInfo.data && data.projectInfo.data.dealOverview ? data.projectInfo.data.dealOverview : null;
            const companyOverview = data.projectInfo && data.projectInfo.data && data.projectInfo.data.companyOverview ? data.projectInfo.data.companyOverview : null;
            if (isDealProject) {
                return (
                    <>
                        <h6 className="text-nowrap">{data.company} <span className="badge bl-deal-badge">Project/Deal</span></h6>
                        <div className="d-flex">
                            <div>
                                <i className="fa fa-fw fa-building" aria-hidden="true"></i> {dealOverview.companyName}
                            </div>
                            <div className="mx-2">
                                <i className="fa fa-fw fa-industry" aria-hidden="true"></i> {dealOverview.industry}
                            </div>
                            <div>
                                <i className="fa fa-fw fa-map-marker" aria-hidden="true"></i> {dealOverview.geography}
                            </div>
                        </div>
                        <div className="d-flex font-small py-1">
                            <div>
                                <span className="text-muted">Deal Source</span> {dealOverview.dealSource}
                            </div>
                            <div className="mx-2">
                                <span className="text-muted">Source Type</span> {dealOverview.sourceType}
                            </div>
                            <div>
                                <span className="text-muted">Deal Type</span> {dealOverview.dealType}
                            </div>
                        </div>
                        <p className="mb-0 bl-truncate-line-2">{companyOverview.description}</p>
                    </>
                )
            }
            return (
                <>
                    <h6 className="text-nowrap">
                        {getSourceCompanyName(data)}
                    </h6>
                    <div className="d-flex align-self-center mr-2 mb-1">
                        {
                            address || concatenateWithCommas(data.street, data.locality, data.country) ? <span className={`flag-icon flag-icon-${contryFlag} mr-2`}></span> : null
                        }

                        {
                            address ? <p className="mb-0">{address}</p> : data.external_data && data.external_data.headquarters ? data.external_data.headquarters : <p className="mb-0">{concatenateWithCommas(data.street, data.locality, data.country)}</p>
                        }

                    </div>
                    <div className="d-flex align-self-center mb-1">
                        {
                            data.yearfounded && <p className="mb-0 mr-1"><i className="fa fa-fw fa-globe" aria-hidden="true"></i> Founded {data.yearfounded} |</p>
                        }
                        {
                            data.revenue && <p className="mb-0 mr-1"><i className="fa fa-fw fa-usd" aria-hidden="true"></i> {data.revenue} |</p>
                        }
                        {
                            data.type && <p className="mb-0 mr-1"><i className="fa fa-fw fa-building-o" aria-hidden="true"></i> {data.type} |</p>
                        }
                        {
                            data.totalEmployee && <p className="mb-0"><i className="fa fa-fw fa-users" aria-hidden="true"></i> {data.totalEmployee} Employees</p>
                        }
                    </div>
                    <p className="mb-0 text-gray bl-desc-truncate-line-2">{data.external_data && data.external_data.aboutUs ? data.external_data.aboutUs : data.description ? data.description : ''}</p>
                </>
            )
        }
        else if (column === 'EXECUTIVES') {
            const executives = data.keyexecutives.slice(0, 3);
            const sectors = data.specialty.slice(0, 4);
            const portfolioInfo = data.portfolio_ai_info && data.portfolio_ai_info.investmentAndFinancialDetails ? data.portfolio_ai_info.investmentAndFinancialDetails : null
            const isDealProject = data.source && data.source.toLowerCase() === "project";
            const considerations = data.projectInfo && data.projectInfo.data && data.projectInfo.data.considerations ? data.projectInfo.data.considerations : null;
            const marketAndCompetitivePosition = data.projectInfo && data.projectInfo.data && data.projectInfo.data.marketAndCompetitivePosition ? data.projectInfo.data.marketAndCompetitivePosition : null;
            if (isDealProject && considerations && marketAndCompetitivePosition) {
                return (
                    <div className="card">
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-6">
                                    <p className="bl-font-size-12px mb-1 font-small text-muted">Considerations:</p>
                                    <table className="bl-deal-consideration-sec" width={'100%'}>
                                        <tbody>
                                            <tr>
                                                <td className="bl-font-size-12px" width={'65%'}>Strategic Fit</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.strategicFit.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.strategicFit.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Synergy Potential</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Exit Strategy Viability</td>
                                                <td width={'35%'}>
                                                    {
                                                        considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <p className="bl-font-size-12px mb-1 font-small text-muted">Market & Competitive Position:</p>
                                    <table className="bl-deal-consideration-sec" width={'100%'}>
                                        <tbody>
                                            <tr>
                                                <td width={'65%'}>Market Growth Potential</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Competitive Landscape</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width={'65%'}>Value Proposition</td>
                                                <td width={'35%'}>
                                                    {
                                                        marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'high' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'medium' ? (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        ) : (
                                                            <div className="progress">
                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="card bg-light border-0">
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-6">

                                    <div className="media mb-1">
                                        {/* <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i> */}
                                        <div className="media-body d-flex">
                                            <p className="text-muted font-small mb-0 bl-desc-truncate-line-1 text-capitalize mr-2 bl-table-cell-width"><i className="fa fa-university mr-1" aria-hidden="true"></i> {'Fund:'}</p>
                                            <p className="mb-0 bl-desc-truncate-line-1 bl-font-size-12px text-capitalize">{portfolioInfo?.fund}</p>
                                        </div>
                                    </div>
                                    <div className="media mb-1">
                                        {/* <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i> */}
                                        <div className="media-body d-flex">
                                            <p className="text-muted font-small mb-0 bl-desc-truncate-line-1 text-capitalize mr-2 bl-table-cell-width"><i className="fa fa-usd mr-1" aria-hidden="true"></i> {'Investment Amount:'}</p>
                                            <p className="mb-0 bl-desc-truncate-line-1 bl-font-size-12px text-capitalize">{portfolioInfo?.investmentAmount}</p>
                                        </div>
                                    </div>
                                    <div className="media mb-1">
                                        {/* <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i> */}
                                        <div className="media-body d-flex">
                                            <p className="text-muted font-small mb-0 bl-desc-truncate-line-1 text-capitalize mr-2 bl-table-cell-width"><i className="fa fa-pie-chart mr-1" aria-hidden="true"></i> {'Stake:'}</p>
                                            <p className="mb-0 bl-desc-truncate-line-1 bl-font-size-12px text-capitalize">{portfolioInfo?.stakePercent}</p>
                                        </div>
                                    </div>
                                    <div className="media mb-1">
                                        {/* <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i> */}
                                        <div className="media-body d-flex">
                                            <p className="text-muted font-small mb-0 bl-desc-truncate-line-1 text-capitalize mr-2 bl-table-cell-width"><i className="fa fa-calendar-o mr-1" aria-hidden="true"></i> {'Last Investment On:'}</p>
                                            <p className="mb-0 bl-desc-truncate-line-1 bl-font-size-12px text-capitalize">{portfolioInfo?.latestInvestmentOn ? moment(portfolioInfo.latestInvestmentOn).format('MMM DD, YYYY') : ''}</p>
                                        </div>
                                    </div>
                                    <div className="media mb-1">
                                        {/* <i className="fa fa-fw fa-user-circle-o mr-1 mt-1" aria-hidden="true"></i> */}
                                        <div className="media-body d-flex">
                                            <p className="text-muted font-small mb-0 bl-desc-truncate-line-1 text-capitalize mr-2 bl-table-cell-width"><i className="fa fa-clock-o mr-1" aria-hidden="true"></i> {'Status:'}</p>
                                            <p className="mb-0 bl-desc-truncate-line-1 bl-font-size-12px text-capitalize">{portfolioInfo?.status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <table className="mt-1 w-100">
                                        <tbody>
                                            <tr>
                                                <td className="text-muted font-small" width={'30%'}><i className="fa fa-industry mr-1" aria-hidden="true"></i> Industry:</td>
                                                <td className="bl-font-size-12px bl-truncate-line-2 text-capitalize">{data.industry}</td>
                                            </tr>
                                            <tr>
                                                <td className="align-top text-muted font-small" width={'30%'}><i className="fa fa-pie-chart mr-1" aria-hidden="true"></i> Sector:</td>
                                                <td>
                                                    {
                                                        sectors.map((sec, i) => {
                                                            return <p key={`company-sector-${i}`} className="mb-0 bl-truncate-line-1 bl-font-size-12px text-capitalize">- {sec.specialty}</p>
                                                        })
                                                    }
                                                    {
                                                        sectors.length === 0 && <p className="bl-font-size-12px mb-0 text-muted"><i>NA</i></p>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else if (column === 'SEED') {
            return (
                <>
                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Seed Round: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$2M(2018)</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Series A: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$10M(2019)</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 p-0">
                            <p className="mb-0 text-gray">
                                <i className="fa fa-fw fa-money mr-1" aria-hidden="true"></i>
                                <span>Series B: </span>
                            </p>
                        </div>
                        <div className="col-6 p-0">
                            <p className="mb-0">$12M(2022)</p>
                        </div>
                    </div>
                </>
            )
        }
        else if (column === 'CHART') {
            if (isGeneratingCompany || collectingExternalData) {
                return <p className="mb-0 font-small"><span className="line-loader"></span> <br />
                    Connecting to additional datasources
                </p>
            }
            let time = moment().unix();
            const min = 1;
            const max = 100;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min + parseInt(time);
            return (
                <div className="text-center">
                    {/* <span onClick={(e) => e.stopPropagation()} className="bl-hover-show position-absolute">
                        <i className="fa fa-trash" aria-hidden="true" onClick={(e) => removeCompanyContent(e, data)}></i>
                    </span> */}
                    <BoldPieChart id={`piechart${data.companyId}-${randomInteger}`} height="60px" score={data.BlScore}></BoldPieChart>
                    <p className="mb-0">BL Score</p>
                </div>
            )
        }
        else {
            return ''
        }

    }

    const openCompanyProfile = (e) => {
        nav(`/portco/info/${e.data.slug}/profile`)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">

                        <section className="mb-3">
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/manager-prioritizing-tasks-list_74855-5272.jpg" alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-building mr-1" aria-hidden="true"></i> {'Total Companies'}</h6>
                                            <h5 className={`mb-0`}>{'15'}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-usd mr-1" aria-hidden="true"></i> {'Total Amount Invested'}</h6>
                                            <h5 className={`mb-0`}>{'$1.4M'}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-money mr-1" aria-hidden="true"></i> {'Active Funds'}</h6>
                                            <h5 className={`mb-0`}>{'5'}</h5>
                                        </div>
                                    </div>
                                </div>
                                {
                                    // portcoKPI && portcoKPI.map((kpi, i) => {
                                    //     return (
                                    //         <div key={`portco-kpi-${i}`} className="card shadow-sm">
                                    //             <div className="card-body">
                                    //                 <div className="d-flex justify-content-between align-items-center">
                                    //                     <h6>{kpi.title}</h6>
                                    //                     <h6>{kpi.count}</h6>
                                    //                 </div>
                                    //                 <div>
                                    //                     <p className={`mb-0 text-muted small`}>{kpi.description}</p>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     )
                                    // })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div>
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                <div className="card-filter py-3 rounded">
                                                    <div className="col-xl-12 col-md-12">
                                                        <div className="form-row blc_button align-items-end">
                                                            {
                                                                filter.map((items, i) => {
                                                                    return (
                                                                        <div key={`filter-items-${i}`} className="form-group mr-2 mb-0">
                                                                            <label htmlFor="inputState">{items.title}</label>
                                                                            <select className="form-control form-control-sm font-small">
                                                                                {
                                                                                    items.list && items.list.map((item, j) => {
                                                                                        return (
                                                                                            <option key={`filter-drop-item-${i}${j}`}>{item}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <button className="btn btn-filter btn-sm font-small">Apply Filter</button>
                                                            <button className="btn btn-link btn-sm bl-filter-clear font-small">Clear All</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !isLoaded ? (
                                                    <div className="col text-center">
                                                        <img className="bl-list-loader" src={listViewLoader} />
                                                    </div>
                                                ) : (
                                                    <DataTable value={companyList} showHeaders={false} rowClassName={"bl-source-overview-list position-relative"} className="cp" selectionMode="single" onRowClick={openCompanyProfile}>
                                                        <Column field="valuation" style={{ width: '5%' }} body={(e) => arrangeColumnValue(e, 'ICON')}></Column>
                                                        <Column field="company" body={(e) => arrangeColumnValue(e, 'COMPANY')}></Column>
                                                        <Column body={(e) => arrangeColumnValue(e, 'EXECUTIVES')} style={{ width: '50%' }}></Column>
                                                        {/* <Column body={(e) => arrangeColumnValue(e, 'SEED')} style={{ width: '17%' }}></Column> */}
                                                        <Column body={(e) => arrangeColumnValue(e, 'CHART')} style={{ width: '9%', textAlign: 'center' }}></Column>
                                                    </DataTable>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}