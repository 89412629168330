import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColumnChart } from "../../Graph/columnChart";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService, getFinanceInfoService } from "../../../services/companyService";
import { EmptyCardsContent } from "../Common/emptyCard";
import { convertCamelCaseToSeparateWords, truncateFileName } from "../../../services/commonService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CardDropdownContent } from "../../Common/cardDropdown";
import AIContext from "../../../services/context/AIAssistance/aiContext";


export const SourceCompanyFinancialComponent = ({ callsByOtherPlace = false, financialDetailsInfoVisible = false }) => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType, setCompanyProfileWidget, setAIModalTitle } = useContext(AIContext);
    const location = useLocation()

    useEffect(() => {
        if (!callsByOtherPlace) {
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                    { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: '', menuName: 'Financial', isActive: false, hasSubMenu: true }
                ]
            )
        }
    }, [location])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomeTableInfo, setIncomeTableInfo] = useState([]);
    const [incomeTableHeader, setIncomeTableHeader] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [balanceSeries, setBalanceSeries] = useState([]);
    const [balanceTableHeader, setBalanceTableHeader] = useState([]);
    const [balanceTableInfo, setBalanceTableInfo] = useState([]);
    const [balancePeriodData, setBalancePeriodData] = useState([]);
    const [cashSeries, setCashSeries] = useState([]);
    const [cashTableHeader, setCashTableHeader] = useState([]);
    const [cashTableInfo, setCashTableInfo] = useState([]);
    const [cashPeriodData, setCashPeriodData] = useState([]);
    const [isFinanceGraphDataLoaded, setIsFinanceGraphDataLoaded] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [portfolioAIInfo, setPortfolioAIInfo] = useState(null)
    const [financialStatementTableDetail, setFinancialStatementTableDetail] = useState([]);
    const [financialStatementTableHeader, setFinancialStatementTableHeader] = useState([]);

    useEffect(() => {
        setIsLoaded(false)
        setIsFinanceGraphDataLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location.pathname])

    useEffect(() => {
        if (portfolioAIInfo && portfolioAIInfo.financialStatements) {
            if (portfolioAIInfo.financialStatements.incomeStatement) {
                const tableData = portfolioAIInfo.financialStatements.incomeStatement;
                let tableHeader = [];
                if (tableData.length) {
                    for (let key in tableData[0]) {
                        const headerText = convertCamelCaseToSeparateWords(key);
                        tableHeader.push({
                            header: headerText,
                            field: key
                        })
                    }
                    setFinancialStatementTableHeader(tableHeader)
                    setFinancialStatementTableDetail(tableData)
                }
            }
        }

    }, [portfolioAIInfo])

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        setIsLoaded(true)
        if (resp && resp.success) {
            const companyInfoResp = resp.data[0];
            setCompanyInfo(companyInfoResp)
            setPortfolioAIInfo(companyInfoResp.portfolio_ai_info ? companyInfoResp.portfolio_ai_info : null)
            getFinanceInfo(companyInfoResp.companyId)
        }
    }

    const getFinanceInfo = async (companyId) => {
        setIsFinanceGraphDataLoaded(false)
        const resp = await getFinanceInfoService(companyId);
        setIsFinanceGraphDataLoaded(true)
        if (resp && resp.success && resp.data && resp.data.length) {
            setUploadedFileName(resp.fileName ? resp.fileName : '')
            if (resp.data[0].income_statement) {
                getIncomeStatementData(resp.data[0].income_statement)
            }
            if (resp.data[0].balance_sheet) {
                getBalanceSheetData(resp.data[0].balance_sheet)
            }
            if (resp.data[0].net_change || resp.data[0].cash_flow) {
                const cashFlowData = resp.data[0].net_change ? resp.data[0].net_change : resp.data[0].cash_flow ? resp.data[0].cash_flow : []
                getCashFlowData(cashFlowData)
            }
        }
    }

    const getIncomeStatementData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        console.log("Check graph data ==>", data);
        
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        console.log("check series ==>", items);
        
        setIncomeSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if (tableData.length) {
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setIncomeTableHeader(tableHeader)
            setIncomeTableInfo(tableData)
        }
    }

    const getBalanceSheetData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setBalancePeriodData(data);
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setBalanceSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if (tableData.length) {
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setBalanceTableHeader(tableHeader)
            setBalanceTableInfo(tableData)
        }
    }

    const getCashFlowData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setCashPeriodData(data);
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setCashSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if (tableData.length) {
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setCashTableHeader(tableHeader)
            setCashTableInfo(tableData)
        }
    }

    const arrangeColumnValue = (item, column) => {
        if (column !== 'USD (Millions)') {
            return formatCurrency(item[column]);

        }
        else return item.Text ? item.Text : item.text;
    }

    const graphTableInfoContent = (columns, data) => {
        if (financialDetailsInfoVisible) {
            data = data.slice(0, 10)
        }
        return (
            <DataTable key={`items`} value={data} className="bl-finance-graph">
                {
                    columns.map((col, i) => {
                        const uniqueVal = Date.now() + Math.random();
                        return (
                            <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} body={(e) => arrangeColumnValue(e, col.header)}></Column>
                        )
                    })
                }
            </DataTable>
        )
    }

    const updateCompanyInfo = () => {
        getCompanyInformation(companyInfo.slug)
    }

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            number
        ).replace(/(\.|,)00$/g, '')
    }

    const [tabs, setTabs] = useState([
        { tabName: "Income Statement", isActive: true },
        { tabName: "Balance Sheet", isActive: false },
        { tabName: "Cashflow", isActive: false }
    ])

    const changeTabs = (index) => {
        const allTabs = tabs.map(tab => {
            tab.isActive = false;
            return tab;
        });
        allTabs[index].isActive = true;
        setTabs(allTabs)
    }

    const getDetailedFinanceInfoContent = () => {
        return (
            <section className="mb-3">
                <div className="card shadow-sm">
                    <div className="card-header d-flex justify-content-between">
                        <div className="d-flex align-self-center">
                            <h6 className="mb-0">{'Financial Summary'}</h6>
                            {/* {companyInfo.isDocumentsAdded && incomePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>} */}
                        </div>
                        <CardDropdownContent cardId="financeTableCard" cardType={'TABLE'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                    </div>
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex p-3 border-bottom">
                                    {
                                        tabs.map((tab, i) => {
                                            return (
                                                <span key={`risk-cate-filter-${i + 1}`} onClick={() => changeTabs(i)} className={`badge cp bl-filter-chip ${tab.isActive ? 'active' : ''}`}>{tab.tabName}</span>
                                            )
                                        })
                                    }
                                </div>
                                <div className="card border-0">
                                    <div className="card-body">
                                        <DataTable value={financialStatementTableDetail} className="bl-finance-graph">
                                            {
                                                financialStatementTableHeader.map((col, i) => {
                                                    const uniqueVal = Date.now() + Math.random();
                                                    return (
                                                        <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field}></Column>
                                                    )
                                                })
                                            }
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const onChildCardEvent = (item) => {
        if (item) {
            onModalLaunch(item)
        }
    }

    const onModalLaunch = (modalMode) => {
        let companyProfileInfoObj = {
            company: companyInfo.company, website: companyInfo.website, description: companyInfo.description, address: companyInfo.address,
            employeeRange: companyInfo.employeeRange, fundingRound: companyInfo.fundingRound, valuation: companyInfo.valuation,
            fundingAmount: companyInfo.fundingAmount, totalEmployee: companyInfo.totalEmployee
        }
        if (modalMode === 'BALANCE') {
            companyProfileInfoObj.incomeStatement = companyInfo.incomeStatement
            companyProfileInfoObj.incomePeriodData = balancePeriodData;
            companyProfileInfoObj.categoryField = categoryField;
            companyProfileInfoObj.incomeSeries = balanceSeries;
            setAIModalTitle('Balance Sheet')
            setAIModalType('FINANCIAL')
        }
        else if (modalMode === 'CASHFLOW') {
            companyProfileInfoObj.incomeStatement = companyInfo.incomeStatement
            companyProfileInfoObj.incomePeriodData = cashPeriodData;
            companyProfileInfoObj.categoryField = categoryField;
            companyProfileInfoObj.incomeSeries = cashSeries;
            setAIModalTitle('Cashflow')
            setAIModalType('FINANCIAL')
        }
        else if (modalMode === 'INCOME') {
            companyProfileInfoObj.incomeStatement = companyInfo.incomeStatement
            companyProfileInfoObj.incomePeriodData = incomePeriodData;
            companyProfileInfoObj.categoryField = categoryField;
            companyProfileInfoObj.incomeSeries = incomeSeries;
            setAIModalTitle('Income Statement')
            setAIModalType('FINANCIAL')
        }
        else if (modalMode === 'TABLE') {
            companyProfileInfoObj.tableHeader = financialStatementTableHeader;
            companyProfileInfoObj.tableData = financialStatementTableDetail;
            setAIModalTitle('Financial Summary')
            setAIModalType('FINANCIAL_TABLE')
        }
        setCompanyProfileWidget(companyProfileInfoObj)

    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-4">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Income Statement'}</h6>
                                            {companyInfo.isDocumentsAdded && incomePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financeIncomeCard" cardType={'INCOME'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                                    <ColumnChart id="incomeStatementFinance" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Income Statement Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && incomeTableInfo.length ? graphTableInfoContent(incomeTableHeader, incomeTableInfo) : null
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Balance Sheet'}</h6>
                                            {companyInfo.isDocumentsAdded && balancePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financeBalanceCard" cardType={'BALANCE'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && balancePeriodData.length ?
                                                    <ColumnChart id="balanceSheetFinance" data={balancePeriodData} categoryField={categoryField} seriesItem={balanceSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Balance Sheet Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && balanceTableInfo.length ? graphTableInfoContent(balanceTableHeader, balanceTableInfo) : null
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Cashflow'}</h6>
                                            {companyInfo.isDocumentsAdded && cashPeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financeCashCard" cardType={'CASHFLOW'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && cashPeriodData.length ?
                                                    <ColumnChart id="cashflowFinance" data={cashPeriodData} categoryField={categoryField} seriesItem={cashSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Cash Flow Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && cashTableInfo.length ? graphTableInfoContent(cashTableHeader, cashTableInfo) : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {
                    financialDetailsInfoVisible && companyInfo.isDocumentsAdded ? getDetailedFinanceInfoContent() : null
                }
            </div>
        </div>
    )
}