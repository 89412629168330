import React from "react";
import { Outlet } from "react-router-dom";

export const PortcoAnalyzeCanvasComponent = () => {

    const uploadDocumentModalContent = () => {
        return (
            <div className="modal fade" id="documentUploadModal" tabIndex="-1" role="dialog" aria-labelledby="documentUploadModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="documentUploadModalLabel">Upload Documents</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="card text-center bl-db-fileupload-border">
                                                <div className="card-body">
                                                    <img src="https://icons.veryicon.com/png/o/business/cloud-computing-background-management-icon/upload-to-folder.png" height="80px" />
                                                    <p className="card-text">Click below to select a file from this device</p>
                                                    <input type="file" id="dbFileSelection" accept=".xlsx, .xls" className="d-none"></input>
                                                    <button className="btn btn-outline-primary btn-sm text-nowrap"><i className="fa fa-upload mr-2" aria-hidden="true"></i> Upload Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <ul className="pl-3 font-small">
                                                <li className="text-muted font-small font-italic">File processing usually takes around 1-2 minutes, depending on the file size or number of pages</li>
                                                <li className="text-muted font-small font-italic">You can monitor the upload and processing progress</li>
                                                <li className="text-muted font-small font-italic">Once processing is complete and data is updated, you can view it in file repository</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm">Start Processing</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Outlet />
            {uploadDocumentModalContent()}
        </>
    )
}