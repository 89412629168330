import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService } from "../../../services/companyService";
import { convertToDate, generateStringFromSlug } from "../../../utils/validation";
import { SourceCommonCompanyTitle } from "../../Sourcing/Common/companyTitle";
import { EmptyCardsContent } from "../../Sourcing/Common/emptyCard";
import { PIEChartComponent } from "../../Graph/piechart";
import { ColumnChart } from "../../Graph/columnChart";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { TrendChartComponent } from "../../Graph/trendChart";
import moment from "moment";


export const PortcoInformationTransactionProfileComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Transaction Profile', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [isGraphLoaded, setIsGraphLoaded] = useState(false)
    const [portfolioAIInfo, setPortfolioAIInfo] = useState(null)
    const [sectorBreakdown, setSectorBreakdown] = useState([])
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [growthChartData, setGrowthChartData] = useState([]);
    const [customColors] = useState( [
        "#47abe1",
        "#9bbfd0",
        "#61aabb",
        "#73cfbf",
        "#070b3180",
        "#47abe180",
        "#9bbfd080",
        "#61aabb80",
        "#73cfbf80",
        "#47ab00",
        "#9bbf00",
        "#61aa00",
        "#73cf00"
    ])

    useEffect(() => {
        setIsLoaded(false)
        setIsGraphLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location.pathname])

    const getCompanyInformation = async (slug) => {
        try {
            const resp = await getCompanyInformationService(slug);
            setIsLoaded(true)
            if (resp && resp.success) {
                const companyInfoResp = resp.data[0];
                setCompanyInfo(companyInfoResp)
                setPortfolioAIInfo(companyInfoResp.portfolio_ai_info ? companyInfoResp.portfolio_ai_info : null)
            }
        }
        catch (e) { }
    }

    useEffect(() => {
        if (portfolioAIInfo) {
            if (portfolioAIInfo.charts && portfolioAIInfo.charts.sectorBreakdown) {
                const spendInfo = portfolioAIInfo.charts.sectorBreakdown.map(sector => {
                    return { sector: sector.sector, size: sector.proportion }
                })
                setSectorBreakdown(spendInfo)
            }
            if (portfolioAIInfo.charts && portfolioAIInfo.charts.purchaseType) {
                getPurchaseTypeDataInfo(portfolioAIInfo.charts.purchaseType)
            }
            if (portfolioAIInfo.charts && portfolioAIInfo.charts.ebitdaGrowth) {
                const valuationInfo = portfolioAIInfo.charts.ebitdaGrowth.map(growth => {                    
                    return { date: convertToDate(growth.year), value: growth.value }
                })
                setGrowthChartData(valuationInfo)
            }
            setIsGraphLoaded(true)
        }
    }, [portfolioAIInfo])

    const getPurchaseTypeDataInfo = (data) => {
        setIncomePeriodData(data);
        setCategoryField('type');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'type');
        setIncomeSeries(items)
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-4">
                    <div className="card-deck">
                        <div className="card col-3 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-building mr-1" aria-hidden="true"></i> Deal Target IRR</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.KPIs?.dealTargetIRR || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col-3 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-users mr-1" aria-hidden="true"></i> Employee at Acquisition</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.KPIs?.employeesAtAcquisition || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col-3 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-money mr-1" aria-hidden="true"></i> EBITDA at Acquisition</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.KPIs?.ebitdaAtAcquisition || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col-3 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-usd mr-1" aria-hidden="true"></i> OpCo at Acquisition</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.KPIs?.opCoNetDebtAtAcquisition || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Sector Breakdown</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                sectorBreakdown.length ?
                                                    <PIEChartComponent data={sectorBreakdown} customColors={customColors} hideCenterText={true} height="15rem" /> :
                                                    <EmptyCardsContent title="No Sector Breakdown data found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Purchase Type</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                incomePeriodData.length ?
                                                    <ColumnChart id="transactionProfilePurchaseType" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="15rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Purchase Type found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">EBITDA Growth</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                growthChartData.length ?
                                                    <TrendChartComponent id="transactionProfileGrowth" height="15rem" chartInfo={growthChartData}/> :
                                                    <EmptyCardsContent title="No EBITDA Growth found" buttonView={false} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Transaction Summary</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-calendar-o mr-3" aria-hidden="true"></i> Date of Signing​</div>
                                                <span className="">{portfolioAIInfo?.transactionSummary?.dateOfSigning ? moment(portfolioAIInfo?.transactionSummary?.dateOfSigning).format('MMM DD, YYYY') : 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-product-hunt mr-3" aria-hidden="true"></i> Project Name</div>
                                                <span className="">{portfolioAIInfo?.transactionSummary?.projectName || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-cubes text-gray mr-3" aria-hidden="true"></i> Purchase Type​​</div>
                                                <span className=""> {portfolioAIInfo?.transactionSummary?.purchaseType || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-random text-gray mr-3" aria-hidden="true"></i> Purchase Process</div>
                                                <span className="">{portfolioAIInfo?.transactionSummary?.purchaseProcess || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-pie-chart text-gray mr-3" aria-hidden="true"></i> Sector</div>
                                                <span className="">{portfolioAIInfo?.transactionSummary?.sector || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-bullseye text-gray mr-3" aria-hidden="true"></i> Position Type​</div>
                                                <span className=""> {portfolioAIInfo?.transactionSummary?.positionType || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-usd text-gray mr-3" aria-hidden="true"></i> Cross Fund Investment​</div>
                                                <span className=""> {portfolioAIInfo?.transactionSummary?.crossFundInvestment || 'NA'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Deal Source Details</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-binoculars mr-3" aria-hidden="true"></i> Seller at Entry​</div>
                                                <span className="">{portfolioAIInfo?.dealSource?.sellerAtEntry || 'NA'}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="text-muted"><i className="fa fa-university mr-3" aria-hidden="true"></i> Deal Source</div>
                                                <span className="">{portfolioAIInfo?.dealSource?.dealSource || 'NA'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}