import React, { useEffect, useState } from "react";
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const TrendChartComponent = ({ id = 'trendChart', height = '100px', chartInfo }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }

    var data = chartInfo ? chartInfo : [
      {
        date: "2012-01-01",
        value: 8
      },
      {
        date: "2012-01-02",
        value: 10
      },
      {
        date: "2012-01-03",
        value: 12
      },
      {
        date: "2012-01-04",
        value: 14
      },
      {
        date: "2012-01-05",
        value: 11
      },
      {
        date: "2012-01-06",
        value: 6
      },
      {
        date: "2012-01-07",
        value: 7
      },
      {
        date: "2012-01-08",
        value: 9
      },
      {
        date: "2012-01-09",
        value: 13
      },
      {
        date: "2012-01-10",
        value: 15
      },
      {
        date: "2012-01-11",
        value: 19
      },
      {
        date: "2012-01-12",
        value: 21
      },
      {
        date: "2012-01-13",
        value: 22
      },
      {
        date: "2012-01-14",
        value: 20
      },
      {
        date: "2012-01-15",
        value: 18
      },
      {
        date: "2012-01-16",
        value: 14
      },
      {
        date: "2012-01-17",
        value: 16
      },
      {
        date: "2012-01-18",
        value: 18
      },
      {
        date: "2012-01-19",
        value: 17
      },
      {
        date: "2012-01-20",
        value: 15
      },
      {
        date: "2012-01-21",
        value: 12
      },
      {
        date: "2012-01-22",
        value: 10
      },
      {
        date: "2012-01-23",
        value: 8
      }
    ];


    let root = am5.Root.new(id);

    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"]
    });

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 0
      })
    );

    var easing = am5.ease.linear;

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: "zoom",
          minGridDistance: 70,
          minorGridEnabled: true
        }),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" })
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{valueY}"
        })
      })
    );

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });

    series.data.setAll(data);

    series.bullets.push(function () {
      var circle = am5.Circle.new(root, {
        radius: 4,
        fill: series.get("fill"),
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2
      });

      return am5.Bullet.new(root, {
        sprite: circle
      });
    });

    createTrendLine(
      chartInfo ? chartInfo : [
        { date: "2012-01-02", value: 10 },
        { date: "2012-01-11", value: 19 }
      ],
      chartInfo ? '' : root.interfaceColors.get("positive")
    );

    createTrendLine(
      chartInfo ? chartInfo : [
        { date: "2012-01-17", value: 16 },
        { date: "2012-01-22", value: 10 }
      ],
      chartInfo ? '' : root.interfaceColors.get("negative")
    );

    function createTrendLine(data, color) {
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "date",
          stroke: color,
          valueYField: "value"
        })
      );

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"]
      });

      series.data.setAll(data);
      series.appear(1000, 100);
    }

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis
    }));
    cursor.lineY.set("visible", false);

    chart.appear(1000, 100);

    setChartRoot(root);

    return () => {
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, []);

  return <div id={id} style={{ width: '100%', height: height }}></div>;
}