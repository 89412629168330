import React, { useContext, useEffect, useState } from "react";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService, getCompanyNewsByCategoryService } from "../../../services/companyService";
import { EmptyCardsContent } from "../Common/emptyCard";
import moment from "moment";
import missingImg from "./../../../assets/img/imagemissing.png";

export const SourceCompanyAllNews = ({callsByOtherPlace=false}) => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        if(!callsByOtherPlace){
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                    { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: '', menuName: 'News & Events', isActive: false, hasSubMenu: true }
                ]
            )
        }
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', blScore: 85
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [newsCategory] = useState([
        {
            "eventCategory": "Crime and Justice",
            list: []
        }, {
            "eventCategory": "Science and Technology",
            list: []
        }, {
            "eventCategory": "Business and Entrepreneurship",
            list: []
        }, {
            "eventCategory": "Social Issues and Civil Rights",
            list: []
        }
    ])
    const [newsSubCategory] = useState([
        {
            "eventSubCategory": "Social inequality and poverty"
        }, {
            "eventSubCategory": "Gender equality and LGBTQ+ rights"
        }, {
            "eventSubCategory": "Corporate leadership and executive changes"
        }, {
            "eventSubCategory": "Startups and entrepreneurship news"
        }, {
            "eventSubCategory": "New product launches and innovations"
        }, {
            "eventSubCategory": "Tech company acquisitions and partnerships"
        }, {
            "eventSubCategory": "Cybersecurity and data privacy"
        }
    ])
    const [isNewsLoaded, setIsNewsLoaded] = useState(false)
    const [newsArticles, setNewsArticles] = useState([])

    useEffect(() => {
        setIsLoaded(false)
        let pathArray = location.pathname.split('/');
        let companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location])

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        setIsLoaded(true)
        if (resp && resp.success) {
            getCompanyNews(resp.data[0].companyId)
            setCompanyInfo(resp.data[0])
        }
    }

    const getCompanyNews = async (companyId) => {
        setIsNewsLoaded(false)
        const resp = await getCompanyNewsByCategoryService(companyId);
        if (resp && resp.success) {
            const articles = resp.data;
            let categoryNews = [];
            for (let key in articles) {
                categoryNews.push({
                    eventCategory: key,
                    list: articles[key]
                })
            }
            setNewsArticles(categoryNews)
        }
        setIsNewsLoaded(true)
    }

    function isImageBroken(url, callback) {
        const img = new Image();

        img.onload = function () {
            // Image loaded successfully
            callback(false);
        };

        img.onerror = function () {
            // Image could not be loaded
            callback(true);
        };

        img.src = url;
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
        e.target.alt = 'Alternative Image';
    }

    const eventListContent = () => {
        return (
            <section className="pb-3">
                <div className="row">
                    <div className="col-md-12 col-lg-12 mb-4">
                        <div className={`card-deck ${newsArticles.length === 1 ? 'bl-w-50' : newsArticles.length > 4 ? 'bl-articles-card' : ''} `} >
                            {
                                newsArticles.map((articles, i) => {
                                    return (
                                        <div key={`risk-event-exp-list-${i}`} className="card">
                                            <div className="d-flex card-header">
                                                <h6 className="mb-0">{articles.eventCategory}</h6>
                                                <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Google News, Reddit, Business Wire'}</span>
                                            </div>
                                            <div className="card-body p-3">
                                                {
                                                    articles.list.map((item, k) => {
                                                        const newsImage = item && item.urlToImage ? item.urlToImage : missingImg;
                                                        return (
                                                            <React.Fragment key={`risk-event-exp-items-${i}${k}`}>
                                                                <div className="media mb-3">
                                                                    <div className="media-body mr-2">
                                                                        {/* <p className="mb-0"><small className="text-muted">{item.eventSubCategory}</small></p> */}
                                                                        <a className="bl-truncate-line-2" href={item.url} target="_blank" rel="noopener">
                                                                            {item.title}
                                                                        </a>
                                                                        <p className="mb-0 mt-1 bl-truncate-line-4 font-smaller text-muted">{item.description}</p>
                                                                        <p className="mb-0"><small className="text-muted">{moment(new Date(item.publishedAt)).format('MMM DD, YYYY')} | {item.source}</small></p>
                                                                    </div>
                                                                    <img className="rounded" src={newsImage} onError={handleImageError} alt="Generic placeholder image" height="80px" width="80px" />
                                                                </div>
                                                                {
                                                                    articles.list.length !== k + 1 ? (<hr />) : null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* <div className="card-footer bg-transparent"><p className="card-text"><small className="text-muted">View All</small></p></div> */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* {
                    eventExplorerJson.map((period, i) => {
                        return (
                            <div key={`risk-event-exp-period-${i}`} className="row">
                                <div className="col-xl-12 col-md-12 col-12 mb-3">
                                    <h6>{period.eventFor}</h6>
                                </div>
                                <div className="col-md-12 col-lg-12 mb-4">
                                    <div className="card-deck">
                                        {
                                            period.categories.map((list, j) => {
                                                return (
                                                    <div key={`risk-event-exp-list-${i}${j}`} className="card">
                                                        <div className="card-header"><h6 className="mb-0">{list.eventCategory}</h6></div>
                                                        <div className="card-body p-3">
                                                            {
                                                                list.list.map((item, k) => {
                                                                    return (
                                                                        <React.Fragment key={`risk-event-exp-items-${i}${j}${k}`}>
                                                                            <div className="media mb-3">
                                                                                <div className="media-body mr-2">
                                                                                    <p className="mb-0"><small className="text-muted">{item.eventSubCategory}</small></p>
                                                                                    <a href="#" target="_blank" rel="noopener">
                                                                                        {item.newsTitle}
                                                                                    </a>
                                                                                </div>
                                                                                <img className="rounded" src={item.imgUrl} alt="Generic placeholder image" height="80px" width="80px" />
                                                                            </div>
                                                                            {
                                                                                list.list.length !== k + 1 ? (<hr />) : null
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="card-footer bg-transparent"><p className="card-text"><small className="text-muted">View All</small></p></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {
                                    eventExplorerJson.length !== i + 1 ? (<div className="col-12 mb-2"><hr /></div>) : null
                                }
                            </div>
                        )
                    })
                } */}
            </section>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid mt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                {
                    isNewsLoaded && newsArticles.length === 0 ?
                        <div className="mt-5">
                            <EmptyCardsContent title="No News Found" desc="Unable to build page. Additional data sources required" buttonView={false} />
                        </div> :
                        isNewsLoaded ? eventListContent() :
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <span className="loader"></span>
                                </div>
                            </div>
                }


            </div>
        </div>
    )
}