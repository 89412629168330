import React, { useContext, useEffect } from "react";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";


export const PortcoAnalyzeHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()
    const nav = useNavigate()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
                { slug: '/portco/analyze', menuName: 'Analyze', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 bl-min-vh-94 align-content-center">

                        {/* For new analyze page */}
                        <section className="text-center mb-5">
                            <div className="container">
                                <h1 className="jumbotron-heading font-weight-normal">Kairos Canvas</h1>
                                <p className="lead">Introducing a groundbreaking approach to securely analyze data with our PE AI Agents, pushing the limits of innovation.</p>
                                <p>
                                    <button onClick={()=>nav('/portco/analyze/canvas/create')} className="btn btn-primary my-2 rounded">Create New Canvas</button>
                                </p>
                                <br/>
                                <div className="card-deck mx-5">
                                    <div className="card">  
                                        <div className="card-body pt-4">
                                        <img className="mb-4" alt="100%x200" src="https://www.svgrepo.com/show/501124/canvas-logo.svg" data-holder-rendered="true" height={'40px'}/>
                                            <h5 className="card-title">Start with a Blank Canvas</h5>
                                            <p className="card-text text-muted">An AI-driven blank canvas to explore your data, generate insights, and adapt to your evolving needs</p>
                                        </div>
                                    </div>
                                    <div className="card">                                        
                                        <div className="card-body pt-4">
                                            <img className="mb-4" alt="100%x200" src="https://img.freepik.com/premium-vector/cloud-dataset-icon-outline_969863-53709.jpg" data-holder-rendered="true" height={'40px'}/>
                                            <h5 className="card-title">Configure Datasets on the Fly</h5>
                                            <p className="card-text text-muted">Effortlessly adjust your data inputs on the fly to adapt to changing analytical needs</p>
                                        </div>
                                    </div>
                                    <div className="card"> 
                                        <div className="card-body pt-4">
                                        <img className="mb-4" alt="100%x200" src="https://t4.ftcdn.net/jpg/09/76/65/11/360_F_976651125_doXlJm3tWPVYuqQL0eq3m8x0kYb1uoM9.jpg" data-holder-rendered="true" height={'40px'}/>
                                            <h5 className="card-title">Tune AI Agents as Needed</h5>
                                            <p className="card-text text-muted">Customize AI Agents with tailored instructions to align perfectly with your specific objectives</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row mx-5 px-2">
                                <div class="col-xl-8 col-md-8 card p-0 mb-4 offset-2 border-0">
                                    <div class="card-body d-flex justify-content-between align-items-end ">
                                        <div className="mb-0 pb-0">
                                            <h5 class="card-title mb-0 pb-0">My Canvases (3)</h5>
                                        </div>
                                    </div>
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="pl-2 text-muted">Name</div>
                                                </th>
                                                <th className=" text-muted">Category</th>
                                                <th className=" text-muted">Data Sources</th>
                                                <th className=" text-muted">Created on</th>
                                                <th className=" text-muted">Modified on</th>
                                                <th className=" text-muted">Status</th>
                                               <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="pl-2">Portfolio Company Monitor - V1</div>
                                                </td>
                                                <td>PortCo</td>
                                                <td>3 Sources</td>
                                                <td>Jan 14</td>
                                                <td>2 days ago</td>
                                                <td>Published</td>
                                                <td><i class="fa fa-angle-right" aria-hidden="true"></i></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pl-2">Untitled Canvas 1</div>
                                                </td>
                                                <td>PortCo</td>
                                                <td>3 Sources</td>
                                                <td>Jan 2</td>
                                                <td>5 days ago</td>
                                                <td>Published</td>
                                                <td><i class="fa fa-angle-right" aria-hidden="true"></i></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pl-2">Untitled Canvas 2</div>
                                                </td>
                                                <td>PortCo</td>
                                                <td>3 Sources</td>
                                                <td>Jan 1</td>
                                                <td>8 days ago</td>
                                                <td>[Draft]</td>
                                                <td><i class="fa fa-angle-right" aria-hidden="true"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}