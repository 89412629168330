import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TrendChartComponent } from "../../Graph/trendChart";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import { PeopleListComponent } from "../Common/peopleListView";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import keyPeopleJson from "./../../../data/peopleData.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService, getCompanyWidgetInformationService } from "../../../services/companyService";
import { EmptyCardsContent } from "../Common/emptyCard";
import moment from "moment";

export const SourceCompanyPeopleComponent = ({ callsByOtherPlace = false }) => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        if (!callsByOtherPlace) {
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                    { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: '', menuName: 'People', isActive: false, hasSubMenu: true }
                ]
            )
        }
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [peopleFeatures, setPeopleFeaures] = useState([
        {
            feature: 'Founders & Key People',
            description: "Individuals who played pivotal roles in establishing and leading the company. They are the driving forces behind the company's inception, growth, and strategic direction",
            peopleList: keyPeopleJson.slice(0, 4),
            peopleCount: 4
        },
        {
            feature: "Board Members",
            description: "Individuals who provide strategic guidance, make critical decisions, and oversee the overall direction of a company.",
            peopleList: keyPeopleJson.slice(5, 7),
            peopleCount: 2
        },
        {
            feature: "Investors",
            description: "Individuals, organizations, or entities that provide capital, usually in exchange for ownership shares or equity, with the expectation of gaining financial returns from the company's growth and profitability",
            peopleList: keyPeopleJson.slice(8, 10),
            peopleCount: 2
        },
        {
            feature: "Key People",
            description: "Individuals within a company who hold significant roles and responsibilities that directly impact its strategic direction, operations, and overall success.",
            peopleList: keyPeopleJson.slice(10, 12),
            peopleCount: 2
        }
    ]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [keyPeople, setKeyPeople] = useState([])
    const [employeeChartData, setEmployeeChartData] = useState([])
    const [isGraphDataLoaded, setIsGraphDataLoaded] = useState(false)
    const [isPitchbookEnabled, setIsPitchbookEnabled] = useState(false)

    useEffect(() => {
        setIsLoaded(false)
        setIsGraphDataLoaded(false)
        setIsPitchbookEnabled(true)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        // let company = companyRiskProfile.find(comp=> comp.slug === companySlug);
        getCompanyInformation(companySlug)
        setIsLoaded(true)
    }, [location, companyRiskProfile])

    const getCompanyWidgetInfo = async (slug) => {
        try {
            const resp = await getCompanyWidgetInformationService(slug);
            getCompanyResponse(resp)
            setIsGraphDataLoaded(true)
        }
        catch(e){

        }
    }

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        getCompanyResponse(resp)
        if (resp && resp.success) {
            if (resp.data.length && resp.data[0].keyexecutives) {
                let peoples = resp.data[0].keyexecutives.map(p => {
                    return {
                        name: p.Name,
                        about: p.Title,
                        country: resp.data[0].country ? (resp.data[0].country).toLowerCase() : 'us',
                        phone: 'NA',
                        email: 'NA',
                        url: p.employee_link
                    }
                })
                setKeyPeople(peoples)
            }
            setCompanyInfo(resp.data[0])
        }
    }

    const getCompanyResponse = (resp) => {
        if (resp && resp.success && resp.data) {
            if (!resp.data[0].employee_count_over_time) {
                getCompanyWidgetInfo(resp.data[0].slug)
            }
            if (resp.data.length && resp.data[0].employee_count_over_time && resp.data[0].employee_count_over_time.length) {
                setEmployeeChartData(resp.data[0].employee_count_over_time)
                setIsGraphDataLoaded(true)
            }
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3">
                            <h6>Founders & Key People <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn'}</span></h6>
                            <p className="text-muted font-small">Individuals who played pivotal roles in establishing and leading the company. They are the driving forces behind the company's inception, growth, and strategic direction</p>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9">
                            <div className="card">
                                <div className="card-body px-1 py-0">
                                    <PeopleListComponent list={keyPeople} boardMemberColumn={true}></PeopleListComponent>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <PeopleListComponent list={keyPeople} boardMemberColumn={true}></PeopleListComponent>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* {
                        peopleFeatures.map((people, i) => {
                            return (
                                <div key={`key-people-feature-list-${i}`} className="row mb-3">
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <h6>{people.feature} ({people.peopleCount})</h6>
                                        <p className="text-gray font-small">{people.description}</p>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-9">
                                        <div className="card">
                                            <div className="card-body p-0">
                                                <PeopleListComponent list={people.peopleList} boardMemberColumn={true}></PeopleListComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    } */}

                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3">
                            <h6>Employee Count over time {isPitchbookEnabled && employeeChartData.length && <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn, Crunchbase, Glassdoor'}</span>}</h6>
                            <p className="text-muted font-small">A dynamic visualization showcasing the fluctuation in the number of employees within a specified timeframe. This graph provides insights into the growth or contraction of the workforce, aiding in workforce management and strategic decision-making for optimized organizational efficiency</p>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        !isGraphDataLoaded ?
                                            (<div className="row align-items-center h-100">
                                                <div className="col"> <span className="loader"></span></div>
                                            </div>) :
                                            employeeChartData.length ? <TrendChartComponent id="sourcepeopleemployee" height="25rem" chartInfo={employeeChartData}></TrendChartComponent> :
                                                <EmptyCardsContent title="No Employee data found" />
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">Employee Count Over Time</h6>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    {
                                        !isGraphDataLoaded ?
                                            (<div className="row align-items-center h-100">
                                                <div className="col"> <span className="loader"></span></div>
                                            </div>) :
                                            employeeChartData.length ? <TrendChartComponent id="sourcepeopleemployee" height="25rem" chartInfo={employeeChartData}></TrendChartComponent> :
                                                <EmptyCardsContent title="No Employee data found" />
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>
        </div>
    )

}