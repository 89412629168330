import React, { useContext, useEffect, useRef, useState } from "react";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import dataDictionaryJson from "./../../../../data/Portco/analyzeDataDictionary.json";
import moment from "moment";
import { ColumnChart } from "../../../Graph/columnChart";
import { AnalyzeCompanyContext } from "../../../../services/context/analyzeCompanyContext";

export const PortcoAnalyzeCanvasCreateComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { analyzeCanvasList, setAnalyzeCanvasList } = useContext(AnalyzeCompanyContext)
    const location = useLocation()
    const nav = useNavigate()

    const [dataDictionary, setDataDictionary] = useState(null)
    const [graphData, setGraphData] = useState([])
    const [seriesItem] = useState([{
        name: 'Revenue',
        val: 'revenue'
    }])
    const [viewType, setViewType] = useState('TABLE')
    const [tableColumn, setTableColumn] = useState([])
    const [isGraphEnabled, setIsGraphEnabled] = useState(false)
    const [isRevenueCellClicked, setIsRevenueCellClicked] = useState(false)
    const [availableColumns, setAvailableColumns] = useState([])
    const [cellInfo, setCellInfo] = useState({
        headerName: "", value: "", underlyingQuery: "", underlyingLogic: "", underlyingFile: ""
    })
    const [tableDataInfo, setTableDataInfo] = useState(null)
    const [canvasTitle, setCanvasTitle] = useState(null)
    const [aiSearch, setAISearch] = useState("")

    useEffect(() => {
        setAISearch("")
        setIsRevenueCellClicked(false)
        setCellInfo({ headerName: "", value: "", underlyingQuery: "", underlyingLogic: "", underlyingFile: "" })
        const previouslyHighlighted = document.querySelector("#canvasConfigTable .highlight");
        if (previouslyHighlighted) {
            previouslyHighlighted.classList.remove("highlight");
        }
        setViewType('TABLE')
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
                { slug: '/portco/analyze', menuName: 'Analyze', isActive: false, hasSubMenu: true },
                { slug: '/portco/analyze/create', menuName: 'Untitled Canvas', isActive: false, hasSubMenu: true }
            ]
        )
        removeHighlightedCell()
    }, [location.pathname])

    useEffect(() => {
        if (analyzeCanvasList && analyzeCanvasList.length) {
            const pathArray = location.pathname.split('/');
            const activeCanvas = pathArray[4];
            const canvasInfo = analyzeCanvasList.find(canvas => canvas.canvasId === parseInt(activeCanvas));
            if (canvasInfo && canvasInfo.tableInfo) {
                setTableDataInfo(canvasInfo.tableInfo)
                setCanvasTitle(canvasInfo.canvasName)
            }
            else {
                setTableDataInfo(null)
                setCanvasTitle(null)
            }
        }
        else {
            nav(`/portco/analyze/canvas/create`)
        }
    }, [analyzeCanvasList, location.pathname])

    useEffect(() => {
        if (dataDictionary && tableDataInfo) {
            const table = document.getElementById('canvasConfigTable');
            const firstRow = table.rows[0];
            const columnLength = firstRow ? firstRow.cells.length : 0;
            const headers = [];
            for (let key in tableDataInfo) {
                headers.push(key)
            }
            for (let i = 0; i < firstRow.cells.length; i++) {
                firstRow.cells[i].textContent = headers[i];
            }
            if (columnLength < headers.length) {
                const newColumnCount = headers.length - columnLength;
                for (let i = 0; i < newColumnCount; i++) {
                    addColumn(headers[columnLength + i])
                }
            }
            setTimeout(() => {
                const keys = Object.keys(tableDataInfo);
                keys.forEach((key, index) => {
                    scheduleUpdateColumnData(key, index)
                });
            }, 50)
        }
        else {
            keepTwoColumns('canvasConfigTable')
        }
    }, [dataDictionary, tableDataInfo])

    function keepTwoColumns(tableId) {
        const newHeaders = ['Enter Column A here', 'Enter Column B here']
        const table = document.getElementById(tableId);
        if (table) {
            for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
                const row = table.rows[rowIndex];
                for (let i = 0; i < 2; i++) {
                    if (i < row.cells.length) {
                        row.cells[i].classList.remove("bl-canvas-cell-highlight-bg");
                        row.cells[i].textContent = '';
                        if (rowIndex === 0 && newHeaders[i]) {
                            row.cells[i].textContent = newHeaders[i];
                        }
                    }
                }
                while (row.cells.length > 2) {
                    row.deleteCell(2);
                }
            }
        }
    }

    useEffect(() => {
        const allAvailableColumns = [];
        if (dataDictionaryJson) {
            setDataDictionary(dataDictionaryJson)
            for (let key in dataDictionaryJson) {
                allAvailableColumns.push(key)
            }
            setAvailableColumns(allAvailableColumns)
        }
    }, [dataDictionaryJson])

    useEffect(() => {
        if (dataDictionary) {
            getCanvasGraphItems()
        }
    }, [dataDictionary])

    useEffect(() => {
        if (tableColumn && tableColumn.length) {
            const keywords = ["Company Name", "Revenue 2024"];
            const allKeywordsMatch = keywords.every(keyword =>
                tableColumn.some(name => name.trim().toLowerCase().includes(keyword.toLowerCase()))
            );
            setIsGraphEnabled(allKeywordsMatch)
        }
    }, [tableColumn])

    useEffect(() => {
        setIsRevenueCellClicked(false)
        if (dataDictionary) {
            document.getElementById("canvasConfigTable").addEventListener("click", function (event) {
                const cell = event.target;
                if (cell.tagName === "TD") {
                    const colIndex = cell.cellIndex;
                    const headerCell = document.querySelector(`#canvasConfigTable thead th:nth-child(${colIndex + 1})`);
                    const headerName = headerCell ? headerCell.innerText.trim() : "Unknown Header";
                    const bodyName = cell.innerText.trim();
                    setIsRevenueCellClicked(true);
                    let info = {
                        underlyingQuery: "", underlyingLogic: "", underlyingFile: ""
                    }
                    if (headerName) {
                        const _info = dataDictionary[headerName];
                        if (_info) {
                            info = _info;
                        }
                    }
                    setCellInfo({
                        headerName: headerName, value: bodyName,
                        underlyingQuery: info?.underlyingQuery || "", underlyingLogic: info?.underlyingLogic || "", underlyingFile: info?.underlyingFile || ""
                    })
                    const previouslyHighlighted = document.querySelector("#canvasConfigTable .highlight");
                    if (previouslyHighlighted) {
                        previouslyHighlighted.classList.remove("highlight");
                    }
                    cell.classList.add("highlight");
                }
            });
        }

    }, [dataDictionary])

    const addColumn = (columnName = "New Column") => {
        const table = document.getElementById("canvasConfigTable");
        const headerRow = table.querySelector("thead tr");
        const bodyRows = table.querySelectorAll("tbody tr");

        const newColIndex = headerRow.children.length;
        const newHeader = document.createElement("th");
        newHeader.contentEditable = "true";
        newHeader.innerText = columnName;
        newHeader.oninput = function () {
            scheduleUpdateColumnData(this, newColIndex);
        };
        headerRow.appendChild(newHeader);

        bodyRows.forEach((row) => {
            const newCell = document.createElement("td");
            newCell.contentEditable = "true";
            row.appendChild(newCell);
        });
    }

    const debounceTimers = {};

    const scheduleUpdateColumnData = (headerCell, colIndex) => {

        if (debounceTimers[colIndex]) {
            clearTimeout(debounceTimers[colIndex]);
        }
        debounceTimers[colIndex] = setTimeout(() => {
            setTimeout(() => {
                updateColumnData(headerCell, colIndex);
            }, 50);
        }, 0);
    }

    const updateColumnData = (headerCell, colIndex, columnData) => {
        const table = document.getElementById("canvasConfigTable");
        const headerRow = table.querySelector("thead tr");
        const columnNames = Array.from(headerRow.querySelectorAll("th")).map(th => th.innerText.trim());
        setTableColumn(columnNames)
        const columnName = headerCell.innerText ? headerCell.innerText.trim() : headerCell.trim();
        const rows = document.querySelectorAll("#canvasConfigTable tbody tr");
        if (columnName && dataDictionary[columnName]) {
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            setTimeout(() => {
                for (let i = 0; i < rows.length; i++) {
                    const item = columnData && columnData.length ? columnData[i] : dataDictionary[columnName].data[i];
                    rows[i].children[colIndex].innerText = item || "";
                }
                if (pageLoader) {
                    pageLoader.style.display = 'none';
                }
            }, 3000)
        }
    }

    const getCanvasGraphItems = () => {
        let graphElement = dataDictionary["Company Name"].data.map((companyName, index) => {
            return {
                label: companyName,
                revenue: parseFloat(dataDictionary["Revenue 2024"].data[index].replace(/,/g, ""))
            };
        });
        setGraphData(graphElement.slice(0, 13));
    }

    const downloadData = () => {
        const rows = document.querySelectorAll('#canvasConfigTable tr');
        let csvContent = "";

        rows.forEach((row) => {
            const cols = row.querySelectorAll('th, td');
            const rowData = [];
            cols.forEach((col) => {
                const cellText = col.innerText.replace(/"/g, '""');
                rowData.push(`"${cellText}"`);
            });
            csvContent += rowData.join(",") + "\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "portfolio_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const saveCanvas = () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        setTimeout(() => {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }, 2000)
        const tableInfo = getTableData("canvasConfigTable");
        if (!tableInfo) {
            console.warn("Table data not found or is empty.");
            return;
        }

        const addedCanvas = Array.isArray(analyzeCanvasList) ? analyzeCanvasList.length : 0;
        const canvasInfo = {
            canvasId: addedCanvas + 1,
            tableInfo: tableInfo,
            canvasName: canvasTitle
        };

        // Parse the active canvas ID from the URL
        const pathArray = location.pathname.split('/');
        const activeCanvas = parseInt(pathArray[4], 10);

        if (!isNaN(activeCanvas)) {
            // Update existing canvas if matched
            const matchedItemIndex = analyzeCanvasList.findIndex(canvas => canvas.canvasId === activeCanvas);
            if (matchedItemIndex !== -1) {
                const updatedList = [...analyzeCanvasList];
                updatedList[matchedItemIndex] = {
                    ...updatedList[matchedItemIndex],
                    tableInfo: tableInfo,
                    canvasName: canvasTitle
                };
                setAnalyzeCanvasList(updatedList);
                return;
            }
        }

        setAnalyzeCanvasList(prev => [...prev, canvasInfo]);
        nav(`/portco/analyze/canvas/${addedCanvas + 1}`);
    };

    function getTableData(tableId) {
        const table = document.getElementById(tableId);
        if (!table) {
            console.error("Table not found!");
            return null;
        }

        const headers = [];
        const data = {};
        const headerElements = table.querySelectorAll("thead th");
        headerElements.forEach((header, index) => {
            const headerText = header.textContent.trim();
            headers.push(headerText);
            data[headerText] = [];
        });

        const rows = table.querySelectorAll("tbody tr");
        rows.forEach(row => {
            const cells = row.querySelectorAll("td");
            cells.forEach((cell, index) => {
                const header = headers[index];
                if (header) {
                    data[header].push(cell.textContent.trim());
                }
            });
        });

        return data;
    }

    const inputRef = useRef(null);

    const adjustWidth = () => {
        if (inputRef.current) {
            const tempSpan = document.createElement('span');
            tempSpan.style.visibility = 'hidden';
            tempSpan.style.whiteSpace = 'pre';
            tempSpan.style.font = getComputedStyle(inputRef.current).font;
            tempSpan.textContent = inputRef.current.value || "Untitled Canvas";
            document.body.appendChild(tempSpan);
            const newWidth = tempSpan.offsetWidth > 800 ? 800 : tempSpan.offsetWidth < 150 ? 150 : tempSpan.offsetWidth + 10;
            document.body.removeChild(tempSpan);
            inputRef.current.style.width = `${newWidth}px`;
        }
    };

    useEffect(() => {
        adjustWidth();
    }, [canvasTitle])

    const getDataUsingAISearch = () => {
        removeHighlightedCell()
        if (aiSearch) {
            const tableData = getTableData("canvasConfigTable")
            if (aiSearch.toUpperCase().includes("EBITDA")) {
                const filteredItem = filterDataByEBITDA(tableData, aiSearch);
                let i = 0;
                for (let key in filteredItem) {
                    updateColumnData(key, i, filteredItem[key])
                    i++;
                }
            }
            else if (aiSearch.toUpperCase().includes("NET IRR")) {
                const numberMatch = aiSearch.match(/[\d,]+/);
                const threshold = parseFloat(numberMatch[0].replace(',', ''));
                highlightCells((value) => {
                    const numericValue = isNaN(value) ? parseFloat(value.replace(/,/g, '')) : value;
                    if(aiSearch.toUpperCase().includes("ABOVE")){
                        return !isNaN(numericValue) && numericValue > threshold;
                    }
                    else {
                        return !isNaN(numericValue) && numericValue < threshold;
                    }
                });
            }
        }
    }

    function filterDataByEBITDA(data, conditionStr) {
        const conditionMatch = conditionStr.match(/[<>]/);
        const numberMatch = conditionStr.match(/[\d,]+/);

        if (!conditionMatch || !numberMatch) {
            console.error("Invalid condition string.");
            return null;
        }

        const condition = conditionMatch[0];
        const threshold = parseFloat(numberMatch[0].replace(',', ''));
        const matchingIndices = data && data.EBITDA && data.EBITDA.map((value, index) => {
            const numericValue = parseFloat(value.replace(',', ''));
            if (condition === '>') {
                return numericValue > threshold ? index : null;
            } else if (condition === '<') {
                return numericValue < threshold ? index : null;
            }
            return null;
        }).filter(index => index !== null);

        const result = {};
        for (const key in data) {
            result[key] = matchingIndices && matchingIndices.length && matchingIndices.map(index => dataDictionary[key].data[index]);
        }

        return result;
    }

    function highlightCells(conditionFn) {
        const table = document.getElementById("canvasConfigTable");
        const rows = table.rows;
        const firstRow = table.rows[0];
        let cellIndex = null;
        for (let i = 0; i < firstRow.cells.length; i++) {
            if (firstRow.cells[i].textContent === "Net IRR") {
                cellIndex = i;
            }
        }
        for (let i = 1; i < rows.length; i++) {
            const cells = rows[i].cells;
            const cell = cells[cellIndex];
            const cellValue = cell ? cell.textContent || cell.innerText : "";
            const numberMatch = cellValue.match(/[\d.]+/);
            const threshold = numberMatch ? parseFloat(numberMatch[0]) : "";
            
            if (conditionFn(threshold, i, cellIndex)) {                
                cell.classList.add("bl-canvas-cell-highlight-bg");
            } else {
                cell && cell.classList.remove("bl-canvas-cell-highlight-bg");
            }
        }
    }

    const removeHighlightedCell = () => {
        const table = document.getElementById("canvasConfigTable");
        const rows = table.rows;
        for (let i = 1; i < rows.length; i++) {
            const cells = rows[i].cells;
            for(let j=0; j<cells.length; j++){
                const cell = cells[j];
                cell && cell.classList.remove("bl-canvas-cell-highlight-bg");
            }
        }
    }


    return (
        <div id="content">
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                        <div className="d-sm-flex align-items-center justify-content-between mb-3">
                            <div className="">
                                <div className="d-flex"><input ref={inputRef} type="text" className="form-control border-0 p-0 bl-analyze-editableTitle" value={canvasTitle ? canvasTitle : ''} onChange={(e) => setCanvasTitle(e.target.value)} id="canvasTitle" aria-describedby="" placeholder="Untitled Canvas" /><i className="fa fa-pencil ml-3 pt-2" aria-hidden="true"></i></div>
                                <p className="text-muted font-small mb-0"><i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i> <i>Unsaved changes as of {moment().format("hh:mm A")} today</i></p>
                            </div>
                            <div className="">
                                <div className="d-flex">
                                    <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={() => addColumn()}><i className="fa fa-fw fa-plus" aria-hidden="true" title="Filter"></i> Add New Column</button>
                                    <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={saveCanvas}><i className="fa fa-fw fa-save" aria-hidden="true" title="Filter"></i> Save Now</button>
                                    <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={downloadData}><i className="fa fa-download" aria-hidden="true"></i></button>
                                    <button className="btn btn-primary btn-sm mr-2 mb-0"><i className="fa fa-share" aria-hidden="true"></i></button>
                                    <button className="btn btn-primary btn-sm mr-0 mb-0"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="alert alert-warning alert-dismissible fade show font-small align-items-center py-2" role="alert">
                            <i className="fa fa-info-circle mr-2" aria-hidden="true"></i>Start by adding column headers like <b>{availableColumns.join(', ')}</b> etc to auto-populate rows.<br />
                            <button type="button" className="close pt-1" data-dismiss="alert" aria-label="Close">
                                <i className="fa fa-times font-smaller" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="ssd">
                            <ul className="nav justify-content-center">
                                <li className="nav-item">
                                    <a className={`nav-link ${viewType === 'TABLE' ? 'active bl-active-tabs-style text-primary' : ''}`} onClick={() => setViewType('TABLE')}><i className="fa fa-table mr-2" aria-hidden="true"></i> Table</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${!isGraphEnabled && 'disabled'} ${viewType === 'GRAPH' ? 'active bl-active-tabs-style text-primary' : ''}`} onClick={() => setViewType('GRAPH')}><i className="fa fa-bar-chart mr-2" aria-hidden="true"></i> Graph</a>
                                </li>
                            </ul>
                            <div className="pt-1 d-flex bl-ai-filter-div">
                                <button className="btn btn-outline-warning btn-sm bl-db-aiInsight-btn text-dark mr-2"><i className="fa fa-fw fa-lightbulb-o" aria-hidden="true" title="Filter"></i></button>
                                <div className="input-group w-auto">
                                    <input type="text" value={aiSearch} onChange={(e) => setAISearch(e.target.value)} className="form-control form-control-sm font-smaller bl-db-aifilter-textbox2" placeholder="Ask AI to filter... (e.g., 'Highlight Net IRR above 5%, EBITDA > n etc')" />
                                    <div className="input-group-append bl-h-fit-content cp" onClick={() => getDataUsingAISearch()}><span className="input-group-text"><i className="fa fa-search font-smaller"></i></span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card pb-0">
                            <div className="card-body p-0 table-responsive">
                                <table id="canvasConfigTable" className={`table table-bordered table-sm mb-0 rounded ${viewType === "TABLE" ? '' : 'd-none'}`}>
                                    <thead>
                                        <tr>
                                            <th contentEditable="true" onInput={(e) => scheduleUpdateColumnData(e.target.innerText, 0)} suppressContentEditableWarning={true}>Enter Column A here</th>
                                            <th contentEditable="true" onInput={(e) => scheduleUpdateColumnData(e.target.innerText, 1)} suppressContentEditableWarning={true}>Enter Column B here</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                        <tr><td contentEditable="true"></td><td contentEditable="true"></td></tr>
                                    </tbody>
                                </table>
                                {
                                    viewType !== "TABLE" ?
                                        <ColumnChart id="analyzeCanvasChart" data={graphData} categoryField={'label'} seriesItem={seriesItem} height="38rem" /> : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-2 col-12 mt-n3 bl-heatmap-right-panel-bg pt-4">
                        <div id="accordion1">
                            <div className="card border-0 bg-transparent mb-2">
                                <div className="card-header border-bottom-0 px-0" id="headingOne">
                                    <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                        <button className="btn btn-link btn-sm bl-analyze-rightpanel-colLink" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <i className="fa fa-info-circle mr-2 text-warning" aria-hidden="true"></i> Selected Cell Info
                                        </button>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </h5>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                    <div className="card-body p-3 font-small bl-analyze-rightpanel-bg rounded">
                                        {
                                            isRevenueCellClicked ? (
                                                <>
                                                    <div className="mb-3">
                                                        <b>Column Name:</b><br />
                                                        {cellInfo?.headerName || null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <b>Value:</b><br />
                                                        {cellInfo?.value || null}
                                                    </div>

                                                    {
                                                        cellInfo.underlyingFile ?
                                                            <div className="">
                                                                <div className="mb-2"><b> Linked File Preview:</b> (1/1)</div>
                                                                <img src={cellInfo.underlyingFile} height={'60px'} className="img-fluid p-1 mb-2 shadow-sm border border-secondary open-reference-modal cp" alt="reference preview" />
                                                                <div className="text-center"><i><u>(ESG-slide-deck-2025.ppt) p8</u></i></div>
                                                            </div> : (
                                                                <>
                                                                    <div className="mb-3">
                                                                        <b>Underlying Logic/Calculation:</b><br />
                                                                        {cellInfo?.underlyingLogic || null}
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <b>Executed Query:</b><br />
                                                                        <i>{cellInfo?.underlyingQuery || null}</i>
                                                                    </div>
                                                                </>
                                                            )
                                                    }

                                                </>
                                            ) : <div>
                                                Please select a cell or header to view explanation
                                            </div>
                                        }


                                    </div>
                                </div>
                            </div>

                            <div className="card border-0 bg-transparent mb-2">
                                <div className="card-header border-bottom-0 px-0" id="headingTwo">
                                    <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                        <button className="btn btn-link btn-sm bl-analyze-rightpanel-colLink" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <i className="fa fa-database mr-2" aria-hidden="true"></i> Enabled Data Sources
                                        </button>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                    <div className="card-body p-0 font-small bl-analyze-rightpanel-bg rounded">
                                        <ul className="list-group list-group-flush bg-transparent">
                                            <li className="list-group-item bg-transparent">
                                                <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                Chronograph
                                            </li>
                                            <li className="list-group-item bg-transparent">
                                                <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                Financials
                                            </li>
                                            <li className="list-group-item bg-transparent">
                                                <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                Board Decks
                                            </li>
                                            <li className="list-group-item bg-transparent">
                                                <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                Vendor Spends
                                            </li>
                                        </ul>
                                        <div className="d-flex p-3 justify-content-between align-items-center">
                                            <span className="border border-dark pl-1 rounded"><i className="fa fa-cog mr-1" aria-hidden="true"></i></span>
                                            <div className="text-muted cp" data-toggle="modal" data-target="#documentUploadModal"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload</div>
                                            {/* <i>View All</i> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card border-0 bg-transparent mb-2">
                                <div className="card-header border-bottom-0 px-0" id="headingThree">
                                    <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                        <button className="btn btn-link btn-sm collapsed bl-analyze-rightpanel-colLink" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <i className="fa fa-microchip mr-2" aria-hidden="true"></i> AI Agent Settings
                                        </button>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                    <div className="card-body pt-0 pl-2 pr-0 rounded pb-0">
                                        <div className="form-group font-small">
                                            <label htmlFor="exampleFormControlTextarea">PortCo Monitor V2.1</label>
                                            <textarea className="form-control form-control-sm font-small" id="exampleFormControlTextarea1" rows="3" placeholder="Please enter optional instructions to tune"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card border-0 bg-transparent mb-2">
                                <div className="card-header border-bottom-0 px-0" id="headingFour">
                                    <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                        <button className="btn btn-link btn-sm collapsed bl-analyze-rightpanel-colLink" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <i className="fa fa-list mr-2" aria-hidden="true"></i> Activity Log
                                        </button>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                    <div className="card-body pt-0 pl-2 pr-0 rounded pb-0 text-muted font-small">
                                        <p>
                                            Created by<br />
                                            <b>Apurva Pandey</b>
                                        </p>
                                        <p>
                                            Created on<br />
                                            <b>{moment().format("MMM DD, YYYY")}</b>
                                        </p>
                                        <p>
                                            Modified on<br />
                                            <b>{moment().format("MMM DD, YYYY")}</b>
                                        </p>
                                        <p>
                                            Shared with<br />
                                            <b>Sarathi Dutta, Diksha C</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}