import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import exceptionWorkbenchJson from "./../../data/Portco/exceptionWorkbench.json";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import flowDiagramIcon from "./../../assets/img/dataFlow.png";
import { convertCamelCaseToSeparateWords } from "../../services/commonService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import companyPlaceholder from "./../../assets/img/companyPlaceholder.svg";


export const PortcoExceptionWorkbenchComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
                { slug: '/portco/exception-workbench', menuName: 'Exception Workbench', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: ""
    });
    const [workbenchHeader, setWorkbenchHeader] = useState([])
    const [workbenchData, setWorkbenchData] = useState([])

    useEffect(() => {
        const items = homeLabel.find(l => l.FEATURE === "EXCEPTION_WORKBENCH").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        if (exceptionWorkbenchJson) {
            const tableData = exceptionWorkbenchJson;
            let tableHeader = [];
            if (tableData.length) {
                for (let key in tableData[0]) {
                    const headerText = key !== 'LogoURL' ? convertCamelCaseToSeparateWords(key) : null;
                    if(headerText){
                        tableHeader.push({
                            header: headerText,
                            field: key
                        })
                    }
                }
                setWorkbenchHeader(tableHeader)
                setWorkbenchData(tableData)
            }
        }
    }, [exceptionWorkbenchJson])

    const missingImg = (e) => {
        e.target.src = companyPlaceholder;
    }

    const arrangeColumnValue = (item, column) => {        
        if (column === 'Name') {
            return (
                <div className="d-flex align-items-center">
                    <div className="bl-listLogo"><img src={item.LogoURL} alt="logo" onError={missingImg} className="img-fluid bl-report-thumbnail mr-2" /></div>
                    <b>{item.Name}</b>
                </div>
            )
        }
        else return item[column];
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <section className="mb-3">
                            <div className="media p-2">
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src="https://img.freepik.com/free-vector/manager-prioritizing-tasks-list_74855-5272.jpg" alt="overview-Img" />
                            </div>
                        </section>

                        <section className="mb-4">
                            <div className="card-deck">
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-building mr-1" aria-hidden="true"></i> Total Vendors Managed</h6>
                                            <h5 className={`mb-0`}>20</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-usd mr-1" aria-hidden="true"></i> Vendors With Exceptions</h6>
                                            <h5 className={`mb-0`}>15</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-diamond mr-1" aria-hidden="true"></i> Resolved Exceptions %</h6>
                                            <h5 className={`mb-0`}>53%</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-3 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="text-muted mb-0"><i className="fa fa-money mr-1" aria-hidden="true"></i> Top Exception Type</h6>
                                            <h5 className={`mb-0`}>Overbilling</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="mb-3 text-center d-none">
                            <img src={flowDiagramIcon} alt="Flow Diagram" className="img-fluid bl-h-20rem" />
                        </section>

                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body p-0">
                                    <DataTable value={workbenchData}>
                                        {
                                            workbenchHeader.map((col, i) => {
                                                const uniqueVal = Date.now() + Math.random();
                                                return (
                                                    <Column key={`exception-workbench-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} body={(e) => arrangeColumnValue(e, col.header)} sortable />
                                                )
                                            })
                                        }
                                    </DataTable>
                                </div>
                            </div>             
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}