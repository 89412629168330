import React, { useEffect, useState } from "react";
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const WaterFallChartComponent = ({ id = 'waterFallChart', height = '100px', data }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }

    let root = am5.Root.new(id);

    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"]
    });

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0
      }));

      let xRenderer = am5xy.AxisRendererX.new(root, { 
        minGridDistance: 30,
        minorGridEnabled: true
       });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
      
      xRenderer.grid.template.setAll({
        location: 1
      })

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 }),
        tooltip: am5.Tooltip.new(root, {})
      }));

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11
    });

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis
      }));

      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        openValueYField: "open",
        categoryXField: "category"
      }));
      
      series.columns.template.setAll({
        templateField: "columnConfig",
        strokeOpacity: 0
      })

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "${displayValue} K",
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      let stepSeries = chart.series.push(am5xy.StepLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "stepValue",
        categoryXField: "category",
        noRisers: true,
        locationX: 0.65,
        stroke: root.interfaceColors.get("alternativeBackground")
      }));
      
      stepSeries.strokes.template.setAll({
        strokeDasharray: [3, 3]
      })

      let colorSet = am5.ColorSet.new(root, {});

      xAxis.data.setAll(data);
      series.data.setAll(data);
      stepSeries.data.setAll(data);

      series.appear(1000);

    chart.appear(1000, 100);

    setChartRoot(root);

    return () => {
      // Cleanup when unmounting the component
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, []);

  return <div id={id} style={{ width: '100%', height: height }}></div>;
}