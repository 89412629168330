import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService } from "../../../services/companyService";
import { convertToDate, generateStringFromSlug } from "../../../utils/validation";
import { SourceCommonCompanyTitle } from "../../Sourcing/Common/companyTitle";
import { EmptyCardsContent } from "../../Sourcing/Common/emptyCard";
import { TrendChartComponent } from "../../Graph/trendChart";
import moment from "moment";
import { HorizontalBarChart } from "../../Graph/horizontalBarChart";
import { SimpleTreeMapChart } from "../../Graph/simpleTreeMap";
import { CardDropdownContent } from "../../Common/cardDropdown";
import AIContext from "../../../services/context/AIAssistance/aiContext";


export const PortcoInformationVendorSpendsComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType, setCompanyProfileWidget, setAIModalTitle } = useContext(AIContext);
    const location = useLocation()

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Vendor Spends', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [isGraphLoaded, setIsGraphLoaded] = useState(false)
    const [portfolioAIInfo, setPortfolioAIInfo] = useState(null)
    const [spendByCategory, setSpendByCategory] = useState([])
    const [spendByRegion, setSpendByRegion] = useState([])
    const [spendOverTimeChartData, setSpendOverTimeChartData] = useState([]);

    useEffect(() => {
        setIsLoaded(false)
        setIsGraphLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location.pathname])

    const getCompanyInformation = async (slug) => {
        try {
            const resp = await getCompanyInformationService(slug);
            setIsLoaded(true)
            if (resp && resp.success) {
                const companyInfoResp = resp.data[0];
                setCompanyInfo(companyInfoResp)
                setPortfolioAIInfo(companyInfoResp.portfolio_ai_info ? companyInfoResp.portfolio_ai_info : null)
            }
        }
        catch (e) { }
    }

    useEffect(() => {
        if (portfolioAIInfo) {
            if (portfolioAIInfo.widgets && portfolioAIInfo.widgets.spendByCategory) {
                const spendInfo = portfolioAIInfo.widgets.spendByCategory.map(spend => {
                    return { country: spend.category, value: spend.spend }
                })
                setSpendByCategory(spendInfo)
            }
            if (portfolioAIInfo.widgets && portfolioAIInfo.widgets.spendByRegion) {
                const spendInfo = generateHierarchicalData(portfolioAIInfo.widgets.spendByRegion)
                setSpendByRegion(spendInfo)
            }
            if (portfolioAIInfo.widgets && portfolioAIInfo.widgets.totalSpendOverTime) {
                const spendTimeInfo = portfolioAIInfo.widgets.totalSpendOverTime.map(growth => {
                    return { date: convertToDate(growth.year), value: growth.spend }
                })
                setSpendOverTimeChartData(spendTimeInfo)
            }
            setIsGraphLoaded(true)
        }
    }, [portfolioAIInfo])

    const generateHierarchicalData = (regions) => {
        const groupNames = ["First", "Second", "Third", "Fourth", "Fifth"];
        let index = 0;

        const data = {
            name: "Root",
            children: groupNames.map((group, groupIndex) => {
                const children = [];
                while (index < regions.length) {
                    const region = regions[index];
                    children.push({
                        name: region.region,
                        value: region.spend
                    });
                    index++;
                    if (children.length >= groupIndex + 1) break;
                }
                return {
                    name: group,
                    children
                };
            })
        };
        return data;
    }

    const onChildCardEvent = (item) => {
        if (item) {
            onModalLaunch(item)
        }
    }

    const onModalLaunch = (modalMode) => {
        let companyProfileInfoObj = {
            company: companyInfo.company, website: companyInfo.website, description: companyInfo.description, address: companyInfo.address,
            employeeRange: companyInfo.employeeRange, fundingRound: companyInfo.fundingRound, valuation: companyInfo.valuation,
            fundingAmount: companyInfo.fundingAmount, totalEmployee: companyInfo.totalEmployee
        }
        companyProfileInfoObj.spendByCategory = spendByCategory;
        companyProfileInfoObj.spendByRegion = spendByRegion;
        companyProfileInfoObj.spendOverTime = spendOverTimeChartData;
        companyProfileInfoObj.vendorSpendDetails = portfolioAIInfo.vendorSpendDetails;
        companyProfileInfoObj.vendorSpendLogs = portfolioAIInfo.vendorDetailsLog;
        if (modalMode === 'SPEND_BY_CATEGORY') {
            setAIModalTitle('Spend by Category')
            setAIModalType('SPEND_BY_CATEGORY')
        }
        else if (modalMode === 'SPEND_BY_REGION') {
            setAIModalTitle('Spend by Region')
            setAIModalType('SPEND_BY_REGION')
        }
        else if (modalMode === 'SPEND_OVER_TIME') {
            setAIModalTitle('Total Spend Over Time')
            setAIModalType('SPEND_OVER_TIME')
        }
        else if (modalMode === 'VENDOR_SPEND_DETAILS') {
            setAIModalTitle('Vendor Spend Details')
            setAIModalType('VENDOR_SPEND_DETAILS')
        }
        else if (modalMode === 'VENDOR_SPEND_LOG') {
            setAIModalTitle('Vendor Spend Logs')
            setAIModalType('VENDOR_SPEND_LOG')
        }
        setCompanyProfileWidget(companyProfileInfoObj)
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-4">
                    <div className="card-deck">
                        <div className="card col shadow-sm p-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-money mr-1" aria-hidden="true"></i> Total Spends</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.vendorKPIs?.totalSpend || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm p-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-users mr-1" aria-hidden="true"></i> Active Contracts</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.vendorKPIs?.activeContracts || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm p-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-renren mr-1" aria-hidden="true"></i> Early Contract Renewal Opp</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.vendorKPIs?.earlyContractRenewalOpportunities || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm p-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-bug mr-1" aria-hidden="true"></i> Exceptions Identified</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.vendorKPIs?.exceptionsIdentified || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card col shadow-sm p-0">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted mb-0"><i className="fa fa-diamond mr-1" aria-hidden="true"></i> Resolved Exceptions</h6>
                                    <h5 className="mb-0">{portfolioAIInfo?.vendorKPIs?.resolvedExceptions || 'NA'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Spend by Category</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="spendCategoryCard" cardType={'SPEND_BY_CATEGORY'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                spendByCategory.length ?
                                                    <HorizontalBarChart data={spendByCategory} hideCenterText={true} height="15rem" /> :
                                                    <EmptyCardsContent title="No Sector Breakdown data found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Spend by Region</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="spendRegionCard" cardType={'SPEND_BY_REGION'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                spendByRegion ?
                                                    <SimpleTreeMapChart id="spendByRegionChart" data={spendByRegion} height="15rem" /> :
                                                    <EmptyCardsContent title="No Spend by Region found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Total Spend Over Time</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="spendOverTimeCard" cardType={'SPEND_OVER_TIME'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        {
                                            !isGraphLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                spendOverTimeChartData.length ?
                                                    <TrendChartComponent id="vendorSpendTime" height="15rem" chartInfo={spendOverTimeChartData} /> :
                                                    <EmptyCardsContent title="No Spend Over Time found" buttonView={false} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Vendor Spend Details</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="vendorSpendCard" cardType={'VENDOR_SPEND_DETAILS'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-muted" scope="col">Vendor Name</th>
                                                    <th className="text-muted" scope="col">Total Spend</th>
                                                    <th className="text-muted" scope="col">Category</th>
                                                    <th className="text-muted" scope="col">Spend %</th>
                                                    <th className="text-muted" scope="col">Exceptions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    portfolioAIInfo && portfolioAIInfo.vendorSpendDetails && portfolioAIInfo.vendorSpendDetails.length ? portfolioAIInfo.vendorSpendDetails.map((vendor, i) => {
                                                        return (
                                                            <tr key={`vendor-spends-details-${i}`}>
                                                                <td scope="row">{vendor.vendorName}</td>
                                                                <td>{vendor.totalSpend}</td>
                                                                <td>{vendor.category}</td>
                                                                <td>{vendor.spendPercent}</td>
                                                                <td>{vendor.exceptions}</td>
                                                            </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Vendor Details Log</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="vendorSpendLogCard" cardType={'VENDOR_SPEND_LOG'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-muted" scope="col">Vendor Name</th>
                                                    <th className="text-muted" scope="col">Total Spend</th>
                                                    <th className="text-muted" scope="col">Category</th>
                                                    <th className="text-muted" scope="col">Avg Monthly Spend</th>
                                                    <th className="text-muted" scope="col">Last Payment Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    portfolioAIInfo && portfolioAIInfo.vendorDetailsLog && portfolioAIInfo.vendorDetailsLog.length ? portfolioAIInfo.vendorDetailsLog.map((vendor, i) => {
                                                        return (
                                                            <tr key={`vendor-spends-details-${i}`}>
                                                                <td scope="row">{vendor.vendorName}</td>
                                                                <td>{vendor.totalSpend}</td>
                                                                <td>{vendor.category}</td>
                                                                <td>{vendor.avgMonthlySpend}</td>
                                                                <td>{moment(vendor.lastPaymentDate).format("MMM DD, YYYY")}</td>
                                                            </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}