import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ComparableChartComponent } from "../../Graph/comparableLineChart";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import esglabelIcon from "./../../../assets/img/esglabel.png";
import { ESGScoreCardComponent } from "../../Common/esgScoreCard";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService } from "../../../services/companyService";
import { CardDropdownContent } from "../../Common/cardDropdown";


export const SourceCompanyESGProfileComponent = ({callsByOtherPlace=false}) => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        if(!callsByOtherPlace){
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                    { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: '', menuName: 'ESG', isActive: false, hasSubMenu: true }
                ]
            )
        }
    }, [location.pathname])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [ESGScores, setESGScores] = useState({
        "esg_score": 77,
        "community": 87,
        "environment": 83,
        "employees": 65,
        "governance": 81
    })
    const [isESGDataFetched, setIsESGDataFetched] = useState(false)

    useEffect(() => {
        setIsLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        // let company = companyRiskProfile.find(comp => comp.slug === companySlug);
        getCompanyInformation(companySlug)
        setIsLoaded(true)
    }, [location])

    const getCompanyInformation = async (slug) => {
        setIsESGDataFetched(false)
        const resp = await getCompanyInformationService(slug);
        if (resp && resp.success) {
            setCompanyInfo(resp.data[0])
            if (resp.data.length && resp.data[0].ESGScore) {
                setESGScores(resp.data[0].ESGScore)
                setIsESGDataFetched(true)
            }
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between align-self-center w-100">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">ESG Ratings compared with all peer companies</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Bloomberg ESG Data'}</span>
                                        </div>
                                        <CardDropdownContent cardId="esgratingcard" />
                                    </div>
                                    <div className="card-body d-grid">

                                        <div className="container align-self-center">
                                            {
                                                !isESGDataFetched ?
                                                    (<div className="row align-items-center h-100">
                                                        <div className="col"> <span className="loader"></span></div>
                                                    </div>) :
                                                    <div>
                                                        <ESGScoreCardComponent id="companyesgpageinfo" scores={ESGScores}></ESGScoreCardComponent>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <img className="img-fluid" src={esglabelIcon} />
                                                                {/* <CarbonZeroChart id="esgcarbonchart" height='15px' marker={false}></CarbonZeroChart> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between align-self-center w-100">
                                    <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Historical ESG Performance</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Bloomberg ESG Data'}</span>
                                        </div>
                                        <CardDropdownContent cardId="esgperofrmancecard" />
                                    </div>
                                    <div className="card-body">
                                        <ComparableChartComponent height="15rem"></ComparableChartComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}