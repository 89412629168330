import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { PageLayout } from './Components/pageLayout';
import { DashboardComponent } from './Components/dashboard';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { TreeTableComponent } from './Components/treeTable';
import { MDMCompanyComponent } from './Components/master-data/companies';
import { MDMCompanyProfileComponent } from './Components/master-data/companyProfile';
import { MDMFundComponent } from './Components/master-data/funds';
import { MDMDealComponent } from './Components/master-data/deals';
import { AnalyticsOverviewComponent } from './Components/analytics/overview';
import LoginComponent from './Components/login';
import { AnalyticsReportListComponent } from './Components/analytics/reportList';
import { AnalyticsSelfServiceComponent } from './Components/analytics/selfService';
import { AnalyticsCatalogComponent } from './Components/analytics/catalog';
import { AnalyticsManageReportComponent } from './Components/analytics/configure/manageReport';
import { AnalyticsManageReportCategoryComponent } from './Components/analytics/configure/manageReportCategory';
import { AnalyticsViewReportComponent } from './Components/analytics/reportView';
import { RiskOverviewComponent } from './Components/Risk/risk-overview';
import { RiskCompanyProfileComponent } from './Components/Risk/riskCompanyProfile';
import { RiskEventExplorer } from './Components/Risk/riskEventExplorer';
import { RiskAIModelsComponent } from './Components/Risk/Configuration/riskAIModels';
import { RiskCategoryComponent } from './Components/Risk/Configuration/riskCategories';
import { RiskEventCategoryComponent } from './Components/Risk/Configuration/eventCategory';
import { RiskDataSourceComponent } from './Components/Risk/Configuration/dataSources';
import { RiskPortfolioCompanyComponent } from './Components/Risk/Configuration/riskPortfolioCompanies';
import { RiskCompanyEventsComponent } from './Components/Risk/riskCompanyEvents';
import { SourcingOverviewComponent } from './Components/Sourcing/sourceOverview';
import { SourceCompanyProfileComponent } from './Components/Sourcing/company/profile';
import { PageNotFoundComponent } from './Components/pageNotFound';
import { SourceCompanyRiskComponent } from './Components/Sourcing/company/risk';
import { SourcePinnedCompanyComponent } from './Components/Sourcing/pinnedCompany';
import { SourceCompanyPeopleComponent } from './Components/Sourcing/company/people';
import { SourceDealStagesComponent } from './Components/Sourcing/dealStage';
import { SourceCompanyCompareComponent } from './Components/Sourcing/company/compareableCompany';
import { SourceCompanyFinancialComponent } from './Components/Sourcing/company/financial';
import { SourceCompanyESGProfileComponent } from './Components/Sourcing/company/esgProfile';
import { SourceCompanyAllNews } from './Components/Sourcing/company/allNews';
import { AppsOverviewComponent } from './Components/Apps/overview';
import { AppsCategoriesComponent } from './Components/Apps/categories';
// import { AIModelOverviewComponent } from './Components/AIModels/AIModelOverview';
// import { AIModelCategoriesComponent } from './Components/AIModels/modelCategory';
import { DataSphereCategoryComponent } from './Components/DataSphere/dataCategory';
import { WorkflowOverviewComponent } from './Components/Workflow/overview';
import { WorkflowCategoryComponent } from './Components/Workflow/workflowCategories';
import { RulesOverviewComponent } from './Components/Rules/overview';
import { RulesCategoryComponent } from './Components/Rules/rulesCategory';
import { TasksOverviewComponent } from './Components/Tasks/overview';
import { TaskCategoryComponent } from './Components/Tasks/taskCategory';
import AIAssistanceContextProvider from './services/context/AIAssistance/aiContextProvider';
import BreadcrumbContextProvider from './services/context/Breadcrumb/breadcrumbContextProvider';
import SearchContextProvider from './services/context/Search/searchContextProvider';
import SystemDownCompoent from './Components/Errors/systemdown';
import { SourceCompanyDataSourceComponent } from './Components/Sourcing/company/companyDataSource';
import { AuthContextProvider } from './services/context/Auth/authContext';
import { AnalyzeCompanyContextProvider } from './services/context/analyzeCompanyContext';
import { ICMemoAnalysisComponent } from './Components/Sourcing/company/analysis/ICMemo';
import { ToolkitOverviewComponent } from './Components/AnalyticsToolkit/toolkitOverview';
import { ToolkitCategoriesComponent } from './Components/AnalyticsToolkit/toolkitCategory';
import { DealProfileComponent } from './Components/Sourcing/company/dealProfile';
import { KairosModalComponent } from './Components/Kairos';
import { SourcingContextProvider } from './services/context/sourcingContext';
import { SourcingComponent } from './Components/Sourcing';
import { PortcoMonitoringComponent } from './Components/Portco';
import { PortcoMonitoringDashboardComponent } from './Components/Portco/dashboard';
import { PortcoMonitoringDataCollectionComponent } from './Components/Portco/dataCollection';
import { PortcoInformationComponent } from './Components/Portco/Information';
import { PortcoInformationProfileComponent } from './Components/Portco/Information/profile';
import { PortcoInformationFinancialComponent } from './Components/Portco/Information/finance';
import { AIModelOverviewComponent } from './Components/AIAgent/AIAgentOverview';
import { AIModelCategoriesComponent } from './Components/AIAgent/agentCategory';
import { PortcoInformationESGComponent } from './Components/Portco/Information/esgInfo';
import { PortcoInformationPeopleComponent } from './Components/Portco/Information/peopleInfo';
import { PortcoInformationNewsComponent } from './Components/Portco/Information/newsInfo';
import { PortcoInformationTransactionProfileComponent } from './Components/Portco/Information/transactionProfile';
import { PortcoInformationVendorSpendsComponent } from './Components/Portco/Information/vendorSpends';
import { PortcoInformationValuationsComponent } from './Components/Portco/Information/valuation';
import { PortcoInformationComparableCompaniesComponent } from './Components/Portco/Information/comparableCompaniesInfo';
import { PortcoExceptionWorkbenchComponent } from './Components/Portco/exceptionWorkbench';
import { PortcoAnalyzeComponent } from './Components/Portco/Analyze';
import { PortcoAnalyzeHomeComponent } from './Components/Portco/Analyze/home';
import { PortcoAnalyzeCanvasComponent } from './Components/Portco/Analyze/Canvas';
import { PortcoAnalyzeCanvasCreateComponent } from './Components/Portco/Analyze/Canvas/createCanvas';


function App() {

  return (
    <HashRouter>
      <AuthContextProvider>
        <BreadcrumbContextProvider>
          <SearchContextProvider>
            <AIAssistanceContextProvider>
              <AnalyzeCompanyContextProvider>
                <SourcingContextProvider>
                  <Routes>
                    <Route element={<PageLayout />}>
                      <Route path='/dashboard' element={<DashboardComponent />} />
                      <Route path='/task' element={<DashboardComponent />} />
                      <Route path='/feed' element={<TreeTableComponent />} />
                      <Route path='/master-data/companies' element={<MDMCompanyComponent />} />
                      <Route path='/master-data/companies/profile' element={<MDMCompanyProfileComponent />} />
                      <Route path='/master-data/fund' element={<MDMFundComponent />} />
                      <Route path='/master-data/deal' element={<MDMDealComponent />} />
                      <Route path='/analytics/overview' element={<AnalyticsOverviewComponent />} />
                      <Route path='/analytics/reports/:id' element={<AnalyticsReportListComponent />} />
                      <Route path='/analytics/reports/category/:categoryId/:reportId' element={<AnalyticsReportListComponent />} />
                      <Route path='/analytics/self-service' element={<AnalyticsSelfServiceComponent />} />
                      <Route path='/analytics/catalog' element={<AnalyticsCatalogComponent />} />
                      <Route path='/analytics/manage-report' element={<AnalyticsManageReportComponent />} />
                      <Route path='/analytics/manage-report-category' element={<AnalyticsManageReportCategoryComponent />} />
                      <Route path='/analytics/reports/:catId/:reportId' element={<AnalyticsViewReportComponent />} />
                      <Route path='/risk/overview' element={<RiskOverviewComponent />} />
                      <Route path='/risk/company/:slug' element={<RiskCompanyProfileComponent />} />
                      <Route path='/risk/event-explorer' element={<RiskEventExplorer />} />
                      <Route path='/risk/company/:slug/:slug' element={<RiskCompanyEventsComponent />} />
                      <Route path='/risk/ai-models' element={<RiskAIModelsComponent />} />
                      <Route path='/risk/risk-categories' element={<RiskCategoryComponent />} />
                      <Route path='/risk/event-categories' element={<RiskEventCategoryComponent />} />
                      <Route path='/risk/data-sources' element={<RiskDataSourceComponent />} />
                      <Route path='/risk/portfolio' element={<RiskPortfolioCompanyComponent />} />
                      <Route element={<SourcingComponent />}>
                        <Route path='/sourcing/overview' element={<SourcingOverviewComponent />} />
                        <Route path='/sourcing/company/:slug/profile' element={<SourceCompanyProfileComponent />} />
                        <Route path='/sourcing/company/:slug/summary' element={<DealProfileComponent />} />
                        <Route path='/sourcing/company/:slug/risk' element={<SourceCompanyRiskComponent />} />
                        <Route path='/sourcing/company/:slug/people' element={<SourceCompanyPeopleComponent />} />
                        <Route path='/sourcing/company/:slug/compare' element={<SourceCompanyCompareComponent />} />
                        <Route path='/sourcing/company/:slug/financial' element={<SourceCompanyFinancialComponent />} />
                        <Route path='/sourcing/company/:slug/esg' element={<SourceCompanyESGProfileComponent />} />
                        <Route path='/sourcing/company/:slug/all-news' element={<SourceCompanyAllNews />} />
                        <Route path='/sourcing/company/:slug/company-data-source' element={<SourceCompanyDataSourceComponent />} />
                        <Route path='/sourcing/company/:slug/ic-memo-analysis' element={<ICMemoAnalysisComponent />} />
                        <Route path='/sourcing/all-company' element={<SourcePinnedCompanyComponent />} />
                        <Route path='/sourcing/deal-stage/:name' element={<SourceDealStagesComponent />} />
                      </Route>
                      <Route path='/kairos' element={<KairosModalComponent />} />
                      {/* <Route path='/sourcing/diligence' element={<SourceDealStagesComponent />} />
          <Route path='/sourcing/negotiations' element={<SourceDealStagesComponent />} />
          <Route path='/sourcing/closed' element={<SourceDealStagesComponent />} /> */}
                      <Route path='/apps/overview' element={<AppsOverviewComponent />} />
                      <Route path='/apps/category/:slug' element={<AppsCategoriesComponent />} />
                      <Route path='/ai-agent/overview' element={<AIModelOverviewComponent />} />
                      <Route path='/ai-agent/category/:slug' element={<AIModelCategoriesComponent />} />
                      <Route path='/data-sphere/:slug' element={<DataSphereCategoryComponent />} />
                      <Route path='/workflow/overview' element={<WorkflowOverviewComponent />} />
                      <Route path='/workflow/category/:slug' element={<WorkflowCategoryComponent />} />
                      <Route path='/rules/overview' element={<RulesOverviewComponent />} />
                      <Route path='/rules/category/:slug' element={<RulesCategoryComponent />} />
                      <Route path='/tasks/overview' element={<TasksOverviewComponent />} />
                      <Route path='/tasks/category/:slug' element={<TaskCategoryComponent />} />
                      <Route path='/toolkit/overview' element={<ToolkitOverviewComponent />} />
                      <Route path='/toolkit/category/:slug' element={<ToolkitCategoriesComponent />} />
                      <Route element={<PortcoMonitoringComponent />}>
                        <Route path='/portco/dashboard' element={<PortcoMonitoringDashboardComponent />} />
                        <Route path='/portco/data-collection' element={<PortcoMonitoringDataCollectionComponent />} />
                        <Route path='/portco/exception-workbench' element={<PortcoExceptionWorkbenchComponent />} />
                        <Route element={<PortcoAnalyzeComponent />} >
                          <Route path='/portco/analyze' element={<PortcoAnalyzeHomeComponent />} />
                          <Route element={<PortcoAnalyzeCanvasComponent />} >
                            <Route path='/portco/analyze/canvas/:id' element={<PortcoAnalyzeCanvasCreateComponent />} />
                          </Route>
                        </Route>
                        <Route element={<PortcoInformationComponent />}>
                          <Route path='/portco/info/:id/profile' element={<PortcoInformationProfileComponent />} />
                          <Route path='/portco/info/:id/financial' element={<PortcoInformationFinancialComponent />} />
                          <Route path='/portco/info/:id/esg' element={<PortcoInformationESGComponent />} />
                          <Route path='/portco/info/:id/people' element={<PortcoInformationPeopleComponent />} />
                          <Route path='/portco/info/:id/news' element={<PortcoInformationNewsComponent />} />
                          <Route path='/portco/info/:id/transaction-profile' element={<PortcoInformationTransactionProfileComponent />} />
                          <Route path='/portco/info/:id/vendor-spends' element={<PortcoInformationVendorSpendsComponent />} />
                          <Route path='/portco/info/:id/valuation' element={<PortcoInformationValuationsComponent />} />
                          <Route path='/portco/info/:id/compare' element={<PortcoInformationComparableCompaniesComponent />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path='/' element={<LoginComponent />} />
                    <Route path='/system-down' element={<SystemDownCompoent />} />
                    <Route path='/*' element={<PageNotFoundComponent />} />
                  </Routes>
                </SourcingContextProvider>
              </AnalyzeCompanyContextProvider>
            </AIAssistanceContextProvider>
          </SearchContextProvider>
        </BreadcrumbContextProvider>
      </AuthContextProvider>
    </HashRouter>
  );
}

export default App;
