import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import appListJson from "../../data/AIModel/modelList.json";
import appCategoryJson from "../../data/AIModel/subMenu.json";
// import { AIModelCardComponent } from "./AIModelCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { AIModelCardComponent } from "./AIAgentCard";


export const AIModelCategoriesComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        const category = appCategoryJson[0].list.find(c => c.slug === slug);
        if (slug === 'all') {
            setAppCategory('All AI Models')
            setAppList(appListJson)
        }
        else {
            if (category) {
                setAppCategory(category.name)
                const appList = appListJson.filter(app => app.category === category.name);
                setAppList(appList)
            }
        }
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'AI Agents', isActive: true, hasSubMenu: true },
                { slug: `/ai-agent/category/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [appList, setAppList] = useState([])
    const [appCategory, setAppCategory] = useState('')
    const [appInfo, setAppInfo] = useState({
        title: "",
        description: "",
        category: "",
        icon: "",
        apps: [],
        isEnabled: true,
        isPinned: true,
        features: [],
        specifications: []
    })

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 3) {
            return pathArray[3];
        }
    }

    const appInfoForModal = (app) => {        
        setAppInfo(app)
    }

    const agentAppInformationModal = () => {
        return (
            <div className="modal fade" id="agentAppInfoModal" tabIndex="-1" role="dialog" aria-labelledby="agentAppInfoModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="deleteReportModalLabel">{reportForm.DELETE.TITLE}</h5> */}
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="pricing-header pb-md-4 mx-auto text-center">
                                    <h4>{appInfo.title}</h4>
                                    <p className="lead pb-0 mb-0">{appInfo.description}</p>
                                    <img src="https://cdn.prod.website-files.com/65b8cd72835ceeacd4449a53/667b080e4b3ca12dc5d5d439_Langgraph%20UI-2.webp" height="530rem" />
                                </div>

                                <div className="container">
                                    <div className="card-deck text-center">
                                        <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <b>Overview</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    <li>Name : {appInfo.title}</li>
                                                    <li>Category: {appInfo.category}</li>
                                                    <li>Used By: {appInfo.apps.join(', ')}</li>
                                                    {/* <li>Used By: {
                                                        appInfo.apps.map((f, i) => {
                                                            return (
                                                                <span key={`ai-agent-apps-${i + 1}`} className={`badge bl-filter-chip d-inline-block mb-2`}>{f}</span>
                                                            )
                                                        })
                                                    }</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <b>Key Features</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    {
                                                        appInfo.features.map((feat, i) => {
                                                          return <li key={`key-feat-app-${i}`}>{feat}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <b>Specifications</b>
                                            </div>
                                            <div className="card-body text-left">
                                                <ul className="pl-3">
                                                    {
                                                        appInfo.specifications.map((spec, i) => {
                                                           return <li key={`key-spec-app-${i}`}>{spec}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col">
                        <section className="mb-3">
                            <h6>{appCategory}</h6>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    appList.map((app, i) => {
                                        return (
                                            <AIModelCardComponent key={`category-app-${i}`} app={app} appInfo={appInfoForModal}></AIModelCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {agentAppInformationModal()}
        </div>
    )
}