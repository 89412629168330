import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getAllDatasourceService, getCompanyInformationService, getCompanyNewsByCategoryService, getCompanyWidgetInformationService, getFinanceInfoService } from "../../../services/companyService";
import emptyIcon from "./../../../assets/img/empty-widget.png";
import missingImg from "./../../../assets/img/imagemissing.png";
import { SourceCommonCompanyTitle } from "../../Sourcing/Common/companyTitle";
import { convertToDate, extractNumberFromString, generateStringFromSlug } from "../../../utils/validation";
import { convertCamelCaseToSeparateWords, truncateFileName } from "../../../services/commonService";
import { PeopleListComponent } from "../../Sourcing/Common/peopleListView";
import { CardDropdownContent } from "../../Common/cardDropdown";
import { EmptyCardsContent } from "../../Sourcing/Common/emptyCard";
import { TrendChartComponent } from "../../Graph/trendChart";
import { ESGScoreCardComponent } from "../../Common/esgScoreCard";
import { ColumnChart } from "../../Graph/columnChart";
import { WorldMapChartComponent } from "../../Graph/worldMapChart";
import { PIEChartComponent } from "../../Graph/piechart";
import AIContext from "../../../services/context/AIAssistance/aiContext";


export const PortcoInformationProfileComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType, setCompanyProfileWidget } = useContext(AIContext);
    const nav = useNavigate()
    const location = useLocation()

    const [companyInfo, setCompanyInfo] = useState({
        companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false,
        socialNetworkUrl: [], isDataSourceAdded: false, isDocumentsAdded: false
    });
    const [keyPeople, setKeyPeople] = useState([])
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [companyNews, setCompanyNews] = useState([])
    const [socialMediaFollowers, setSocialMediaFollowers] = useState([])
    const [valuationChartData, setValuationChartData] = useState([])
    const [employeeChartData, setEmployeeChartData] = useState([])
    const [isGraphDataLoaded, setIsGraphDataLoaded] = useState(false)
    const [isFinanceGraphDataLoaded, setIsFinanceGraphDataLoaded] = useState(false)
    const [ESGScores, setESGScores] = useState({
        "esg_score": 77,
        "community": 87,
        "environment": 83,
        "employees": 65,
        "governance": 81
    })
    const [isESGDataFetched, setIsESGDataFetched] = useState(false)
    const [isNewsFetched, setIsNewsFetched] = useState(false)
    const [isPitchBookEnabled, setIsPitchBookEnabled] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [companyLocations, setCompanyLocations] = useState([])
    const [vendorSpends, setVendorSpends] = useState([])
    const [portfolioAIInfo, setPortfolioAIInfo] = useState(null)

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        setBreadcrumbMenu(
            [
                { slug: '/portco/dashboard', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
                { slug: '', menuName: generateStringFromSlug(companySlug), isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location.pathname])

    useEffect(() => {
        setIsLoaded(false)
        setIsGraphDataLoaded(false)
        setIsFinanceGraphDataLoaded(false)
        setIsPitchBookEnabled(false)
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug, 1)
    }, [location.pathname])

    useEffect(() => {
        if (portfolioAIInfo && portfolioAIInfo.vendorSpendDetails) {
            const spendInfo = portfolioAIInfo.vendorSpendDetails.map(vendor => {
                return { sector: vendor.category, size: extractNumberFromString(vendor.totalSpend) }
            })
            setVendorSpends(spendInfo)
        }
        if (portfolioAIInfo && portfolioAIInfo.valuationOverTime) {
            const valuationInfo = portfolioAIInfo.valuationOverTime.map(valuation => {
                return { date: convertToDate(valuation.date), value: valuation.key }
            })
            setValuationChartData(valuationInfo)
        }
    }, [portfolioAIInfo])

    const getCompanyInformation = async (slug, retry) => {
        try {
            setIsESGDataFetched(false)
            const resp = await getCompanyInformationService(slug);
            if (retry === 1) {
                getCompanyResponse(resp)
            }
            if (resp && resp.data && resp.data.length) {
                const companyInfoResp = resp.data[0];
                if (resp.data.length && resp.data[0].keyexecutives) {
                    let peoples = resp.data[0].keyexecutives.map(p => {
                        return {
                            name: p.Name,
                            about: p.Title,
                            country: resp.data[0].country ? (resp.data[0].country).toLowerCase() : 'us',
                            phone: 'NA',
                            email: 'NA',
                            url: p.employee_link
                        }
                    })
                    setKeyPeople(peoples)
                }
                setCompanyInfo(companyInfoResp)
                setPortfolioAIInfo(companyInfoResp.portfolio_ai_info ? companyInfoResp.portfolio_ai_info : null)
                if (companyInfoResp.external_data && companyInfoResp.external_data.location) {
                    const compLocations = companyInfoResp.external_data.location.map(loc => {
                        return {
                            title: loc.city,
                            latitude: loc.latitude, longitude: loc.longitude
                        }
                    })
                    setCompanyLocations(compLocations)
                }
                if (retry === 1) {
                    getAllDatasource(resp.data[0].companyId)
                    getFinanceInfo(resp.data[0].companyId)
                    getCompanyNews(resp.data[0].companyId)
                }
                const compDate = companyInfoResp.updated_at ? companyInfoResp.updated_at : moment();
                const diffInHours = moment().diff(moment(compDate), 'hours', true)
                if (Math.abs(diffInHours) < 1 && companyInfoResp.source === 'company' && !companyInfoResp.external_data && retry <= 5) {
                    setTimeout(() => {
                        getCompanyInformation(slug, retry + 1)
                    }, 7000)
                }
            }
        }
        catch (e) { }
    }

    const getCompanyResponse = (resp) => {
        setIsLoaded(true)
        if (resp && resp.success && resp.data) {
            if (!resp.data[0].social_media_followers_over_time || !resp.data[0].valuation_over_time || !resp.data[0].employee_count_over_time) {
                getCompanyWidgetInfo(resp.data[0].slug)
            }

            if (resp.data.length && resp.data[0].ESGScore) {
                setESGScores(resp.data[0].ESGScore)
                setIsESGDataFetched(true)
            }
            if (resp.data.length && resp.data[0].social_media_followers_over_time && resp.data[0].social_media_followers_over_time.length) {
                setSocialMediaFollowers(resp.data[0].social_media_followers_over_time)
                setIsGraphDataLoaded(true)
            }
            if (resp.data.length && resp.data[0].valuation_over_time && resp.data[0].valuation_over_time.length) {
                // setValuationChartData(resp.data[0].valuation_over_time)
                setIsGraphDataLoaded(true)
            }
            if (resp.data.length && resp.data[0].employee_count_over_time && resp.data[0].employee_count_over_time.length) {
                setEmployeeChartData(resp.data[0].employee_count_over_time)
                setIsGraphDataLoaded(true)
            }
        }
    }

    const getFinanceInfo = async (companyId) => {
        setIsFinanceGraphDataLoaded(false)
        const resp = await getFinanceInfoService(companyId);
        setIsFinanceGraphDataLoaded(true)
        if (resp && resp.success && resp.data && resp.data.length && resp.data[0].income_statement) {
            getIncomeStatementData(resp.data[0].income_statement)
            setUploadedFileName(resp.fileName ? resp.fileName : '')
        }
    }

    const getIncomeStatementData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setIncomeSeries(items)
    }

    const getCompanyWidgetInfo = async (slug) => {
        try {
            setIsESGDataFetched(false)
            const resp = await getCompanyWidgetInformationService(slug);
            getCompanyResponse(resp)
            setIsGraphDataLoaded(true)
        }
        catch (e) {

        }

    }

    const getAllDatasource = async (companyId) => {
        setIsPitchBookEnabled(false)
        const resp = await getAllDatasourceService(companyId);
        if (resp && resp.success) {
            resp.data && resp.data.length && resp.data.forEach(s => {
                if (s.name === 'Pitchbook' && s.mappingId) {
                    setIsPitchBookEnabled(true);
                }
                if (s.name === 'X' && s.mappingId) {
                }
            })
        }
    }

    const getCompanyNews = async (companyId) => {
        setIsNewsFetched(false)
        const resp = await getCompanyNewsByCategoryService(companyId);
        if (resp && resp.success) {
            const articles = resp.data;
            let categoryNews = [];
            for (let key in articles) {
                categoryNews.push({
                    eventCategory: key,
                    list: articles[key]
                })
            }
            const allObjects = categoryNews.flatMap(category => category.list);
            const sortedObjects = allObjects.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
            const list = sortedObjects.slice(0, 3)
            setCompanyNews(list)
        }
        setIsNewsFetched(true)
    }

    useEffect(() => {
        if (isNewsFetched && companyInfo.external_data && companyNews.length === 0) {
            const articles = companyInfo.external_data.companyNews;
            let categoryNews = [];
            articles && articles.length && articles.forEach(n => {
                categoryNews.push({
                    eventCategory: 'Others',
                    source: 'Company Website',
                    title: n.headline,
                    url: n.url,
                    publishedAt: new Date(n.date)
                })
            })
            const sortedObjects = categoryNews.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
            const list = sortedObjects.slice(0, 3)
            setCompanyNews(list)
        }
    }, [isNewsFetched, companyInfo.external_data])

    const getCompanySectors = () => {
        if (companyInfo.specialty && companyInfo.specialty.length) {
            let specialty = [];
            const specialtyList = companyInfo.specialty.slice(0, 3);
            for (let i = 0; i < specialtyList.length; i++) {
                specialty.push(companyInfo.specialty[i].specialty)
            }
            return specialty.join(', ');
        }
        else return 'NA'
    }

    const updateCompanyInfo = () => {
        // getCompanyInformation(companyInfo.slug, 1)
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
        e.target.alt = 'Alternative Image';
    }

    const profileSection = () => {
        return (
            <section className="mb-3">
                <div className="row bl-profile-highlights">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i> About <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn, Crunchbase'}</span></h6>
                        <p className="bl-truncate-line-5">{companyInfo.description ? companyInfo.description : companyInfo.external_data && companyInfo.external_data.aboutUs ? companyInfo.external_data.aboutUs : ''}</p>
                        <p><i className="fa fa-fw fa-location-arrow" aria-hidden="true"></i> {companyInfo.address ? companyInfo.address : companyInfo.external_data && companyInfo.external_data.headquarters ? companyInfo.external_data.headquarters : 'NA'}</p>
                        <p><i className="fa fa-fw fa-users" aria-hidden="true"></i> {companyInfo.employeeRange ? companyInfo.employeeRange : 'NA'}</p>
                        <p><i className="fa fa-fw fa-key" aria-hidden="true"></i> {portfolioAIInfo?.investmentAndFinancialDetails?.fund || 'NA'}</p>
                        <p><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i> {portfolioAIInfo?.investmentAndFinancialDetails?.status || 'NA'}</p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6><i className="fa fa-fw fa-bolt" aria-hidden="true"></i> Highlights</h6>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-6">
                                <p>Industry <br /><b>{companyInfo.industry ? companyInfo.industry : 'NA'}</b></p>
                                <p>Geographic Reach <br /><b>{companyInfo.external_data && companyInfo.external_data.listOfContinents && companyInfo.external_data.listOfContinents.length ? companyInfo.external_data.listOfContinents.join(', ') : 'NA'}</b></p>
                                <p>Reporting Currency <br /><b>{portfolioAIInfo?.investmentAndFinancialDetails?.reportingCurrency || 'NA'}</b></p>
                                <p>Investment Amount <br /><b>{portfolioAIInfo?.investmentAndFinancialDetails?.investmentAmount || 'NA'}</b></p>
                            </div>
                            <div className="col-xl-6 col-md-6 col-6">
                                <p className="bl-truncate-line-4">Sector <br /><b>{companyInfo.specialty && companyInfo.specialty.length ? getCompanySectors() : 'NA'}</b></p>
                                <p>Legal Entity Type <br /><b>{portfolioAIInfo?.investmentAndFinancialDetails?.legalEntityType || 'NA'}</b></p>
                                <p>Stake % <br /><b>{portfolioAIInfo?.investmentAndFinancialDetails?.stakePercent || 'NA'}</b></p>
                                <p>Latest Investment On <br /><b>{portfolioAIInfo?.investmentAndFinancialDetails?.latestInvestmentOn ? moment(portfolioAIInfo?.investmentAndFinancialDetails?.latestInvestmentOn).format('MMM DD, YYYY') : 'NA'}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6><i className="fa fa-fw fa-rss-square" aria-hidden="true"></i> Recent News <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'Google News, Reddit, Business Wire'}</span></h6>
                        {
                            companyNews.map((activity, i) => {
                                const newsImage = activity && activity.urlToImage ? activity.urlToImage : missingImg;
                                return (
                                    <div key={`risk-activity-k-${i + 1}`} >
                                        <div className={`bl-activity py-2`}>
                                            <img className="img-fluid align-self-center bl-w-4rem" src={newsImage} onError={handleImageError} alt="news logo" />
                                            <div>
                                                <p className="mb-0"><small className="text-muted">{activity.eventCategory}</small></p>
                                                <a className="bl-desc-truncate-line-2 p-0 text-left" href={activity.url} target="_blank">{activity.title}</a>
                                                <p className="text-muted font-smaller bl-desc-truncate-line-1 mb-0">{moment(new Date(activity.publishedAt)).format('MMM DD, YYYY')} {activity.source ? `| ${activity.source}` : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            isNewsFetched && companyNews.length === 0 ?
                                <div className="d-flex align-self-center mt-3">
                                    <img className="mr-3 rounded align-self-center" src={emptyIcon} height="60px" alt="Generic placeholder image" />
                                    <div>
                                        <h6 className="mb-1 align-self-center">{'No News Found'}</h6>
                                        <p className="mb-2 align-self-center font-small text-muted bl-truncate-line-2">{'Unable to build widget. Additional data sources required.'}</p>
                                        <button className="btn btn-sm secondary-link btn-outline-primary" data-toggle="modal" data-target="#datasourceModal">View Data Sources</button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </section>
        )
    }

    const onChildCardEvent = (item) => {
        if (item) {
            onModalLaunch(item)
        }
    }

    const onModalLaunch = (modalMode) => {
        let companyProfileInfoObj = {
            company: companyInfo.company, website: companyInfo.website, description: companyInfo.description, address: companyInfo.address,
            employeeRange: companyInfo.employeeRange, fundingRound: companyInfo.fundingRound, valuation: companyInfo.valuation,
            fundingAmount: companyInfo.fundingAmount, totalEmployee: companyInfo.totalEmployee
        }
        if (modalMode === 'RISK') {
            companyProfileInfoObj.riskScores = companyInfo.riskScores
        }
        else if (modalMode === 'ESG') {
            companyProfileInfoObj.ESGScore = companyInfo.ESGScore
        }
        else if (modalMode === 'FINANCIAL') {
            companyProfileInfoObj.incomeStatement = companyInfo.incomeStatement
            companyProfileInfoObj.incomePeriodData = incomePeriodData;
            companyProfileInfoObj.categoryField = categoryField;
            companyProfileInfoObj.incomeSeries = incomeSeries;
        }
        else if (modalMode === 'VALUATION') {
            companyProfileInfoObj.valuationChartData = valuationChartData;
        }
        else if (modalMode === 'EMPLOYEE_COUNT') {
            companyProfileInfoObj.employeeChartData = employeeChartData;
        }
        else if (modalMode === 'SOCIAL_MEDIA') {
            companyProfileInfoObj.socialMediaFollowers = socialMediaFollowers;
        }
        else if (modalMode === 'KEY_PEOPLE') {
            companyProfileInfoObj.keyPeople = keyPeople;
        }
        setCompanyProfileWidget(companyProfileInfoObj)
        setAIModalType(modalMode)
    }

    const switchModule = (moduleName) => {
        let path = location.pathname.replace('profile', moduleName);
        nav(path)
    }

    const getTransactionProfileInfo = (type) => {
        const transactionProfileInfo = portfolioAIInfo && portfolioAIInfo.metrics && portfolioAIInfo.metrics.length ? portfolioAIInfo.metrics : [];
        if (transactionProfileInfo) {
            const transValue = transactionProfileInfo.find(trans => trans.metric === type);
            return transValue && transValue.value ? transValue.value : '';
        }
    }

    const widgetSectionsContent = () => {
        return (
            <>
                <section className="mb-2">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Locations</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-globe" aria-hidden="true"></i>{'Website'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileLocationCard" />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyLocations && companyLocations.length ? <WorldMapChartComponent height="12rem" data={companyLocations} /> : <EmptyCardsContent title="Location not found" buttonView={false} />
                                        }

                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Transaction Profile</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Pitchbook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileTransactionCard"/>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <div><i className="fa fa-users text-gray mr-3" aria-hidden="true"></i> Employees at Acquisition​</div>
                                                        <span className="badge badge-primary badge-pill">{getTransactionProfileInfo("Employees at Acquisition")}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <div><i className="fa fa-dollar text-gray mr-3" aria-hidden="true"></i> EBITDA at Acquisition​</div>
                                                        <span className="badge badge-primary badge-pill">{getTransactionProfileInfo("EBITDA at Acquisition")}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <div><i className="fa fa-money text-gray mr-2" aria-hidden="true"></i> OpCo Net Debt at Acquisition​​</div>
                                                        <span className="badge badge-primary badge-pill">{getTransactionProfileInfo("OpCo Net Debt at Acquisition")}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('esg')}>
                                            <h6 className="mb-0">ESG Scorecard</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Bloomberg ESG Data'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileESGCard" cardType={'ESG'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isESGDataFetched ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) : <ESGScoreCardComponent id="companyProfileInfo" scores={ESGScores}></ESGScoreCardComponent>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card-deck">

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('financial')}>
                                            <h6 className="mb-0">Financial Summary Over Time</h6>
                                            {companyInfo.isDocumentsAdded && incomePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        {companyInfo.isDocumentsAdded && incomePeriodData.length && <CardDropdownContent cardId="profileFinancialCard" cardType={'FINANCIAL'} onChildCardEvent={(item) => onChildCardEvent(item)} />}
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                                    <ColumnChart id="profileIncomeStatementFinance" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="15rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Financial Summary data found" />
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center" onClick={() => switchModule('financial')}>
                                            <h6 className="mb-0">Valuation Over Time</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileValuationCard" cardType={'VALUATION'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                valuationChartData.length ?
                                                    <TrendChartComponent id="sourceProfileValuation" height="15rem" chartInfo={valuationChartData}></TrendChartComponent> :
                                                    <EmptyCardsContent title="No Valuation data found" />
                                        }

                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center" onClick={() => switchModule('financial')}>
                                            <h6 className="mb-0">Vendor Spends</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileVendorCard" />
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            vendorSpends.length === 0 ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                vendorSpends.length ?
                                                    <PIEChartComponent data={vendorSpends} hideCenterText={true} height="15rem" /> :
                                                    <EmptyCardsContent title="No Valuation data found" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('people')}>
                                            <h6 className="mb-0">Employee Count Over Time</h6>
                                            {isPitchBookEnabled && employeeChartData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn, Crunchbase, Glassdoor'}</span>}
                                        </div>
                                        {isPitchBookEnabled && employeeChartData.length && <CardDropdownContent cardId="profileEmployeeCard" cardType={'EMPLOYEE_COUNT'} onChildCardEvent={(item) => onChildCardEvent(item)} />}
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                isPitchBookEnabled && employeeChartData.length ? <TrendChartComponent id="sourceProfileEmployee" height="15rem" chartInfo={employeeChartData}></TrendChartComponent> :
                                                    <EmptyCardsContent title="No Employee data found" />
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('people')}>
                                            <h6 className="mb-0 align-self-center">Key People</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileKeyPeopleCard" cardType={'KEY_PEOPLE'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-0">
                                        <PeopleListComponent list={keyPeople}></PeopleListComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                {
                    profileSection()
                }
                {widgetSectionsContent()}
            </div>
        </div>
    )
}